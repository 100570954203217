/* ScrollingText */
.events-consolidated-wrapper{
    background: url('../../Pages/About/bg.png');
    cursor: url(https://static.codepen.io/assets/favicon/favicon-8ea04875e70c4b0bb41da869e81236e54394d63638a1ef12fa558a4a835f1164.ico), auto;
    z-index: 50;
    background-size: auto;
    background-position: center;
}
.scrolling-text{
  font-family: 'That_New_Pixel';
  font-style: italic;
  color: #2D48D5;
  font-size: 5rem;
  padding-top: 15vh;
  padding-bottom: 2vh;
  padding-left: 5vw;
  z-index: 98;
  position: relative;
}
  .filled-image{
    z-index: 98;
    margin-top: 30px;
    margin-left: 100px;
  }
  
  .filled-text:hover{
    font-size: 100px;
    transform: scale(1.1); 
    cursor: pointer; 
    transition: font-size 0.3s ease, transform 0.3s ease; 
  }

  @media (max-width: 768px){
    .filled-image{
      height: 50px;
      margin-left: 20px;
    }
    .scrolling-text{
      font-size: 3rem !important;
      margin-top: 50px;
      margin-left: 10px;
      padding-top: 2vh;
      padding-left: 10vw!important;
    }
  } 
  

  /* EventList.css */
.event-list {
    position: relative;

    display: flex;
    flex-direction: column;
  }
  
  .event-item {
    height: 168px;
    display: flex;
    justify-content: space-between;
    padding-left: 100px;
    padding-right: 100px;
    align-items: center;
    background-color: #2D48D5; 
    transition: background-color 0.3s ease;
    position: relative;
    border-bottom: 4px solid #C1C3C6;
  }
  
  .event-item.hovered {
    display: flex;
    background-color: #F3FF63;
  }

  .event-item:hover .event-main-title ,.event-item:hover .event-number {
    color : #2D48D5;
  }

  .event-item:hover .event-subheadings .sub-heading{
    color: black;
  }
  
  .event-title-container {
    display: flex;
    justify-content: space-between;
    width: max-content;
  }
  
  .event-title {
    display: flex;
    align-items: center;
    padding: 0 20px;
    font-weight: 200;
  }
  
  .event-number {
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
  }
  
  .event-main-title {
    font-family: 'FK_Grotesk';
font-size: 64px;
font-weight: 100;
line-height: 96px;
letter-spacing: -0.03em;
text-align: left;
  }
  
  .event-subheadings {
    font-family: 'FK_Grotesk';
    justify-content: flex-start;
font-size: 16px;
font-style: italic;
font-weight: 100;
line-height: 19.2px;
letter-spacing: -0.011em;
text-align: left;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    width: 300px;
    height: 16vh
  }
  
  .event-image-container {
    margin-bottom: 10vh;
    z-index: 99;
    width: 400px;
    height: 280px;
  }
  
  .event-image {
    /* width: 75%;
    height: 75%; */
    object-fit: cover;
  }
  @media (max-width: 768px) {
    .event-item {
      align-items: flex-start;
      height: 160px;
      padding: 2vw 0vw 0vw 0vw;
    }

    

    .event-image-container{
      width: 120px;
      height: 85px;
      margin-top: 10px;
    }

    .event-main-title{
      font-family: 'FK_Grotesk';
font-size: 24px;
font-weight: 100;
line-height: 36px;
letter-spacing: -0.03em;
text-align: left;
color:#F3FF63;
    }

    .event-number{
      align-self: flex-start;
      margin-top: 10px;
      height: 15px;
      width: 15px;
    }

    .event-subheadings{
      display: flex;
      justify-content: flex-start;
      height: 80%;
      width: 100px;
      align-self: center;
      margin-right: 10px;
      width: 130px;
      padding-left: 0vw;
      padding-right: 5px;
    }

    .sub-heading{
      font-family: 'FK_Grotesk';
      margin-bottom: 0.6vh;
font-size: 10px;
font-style: italic;
font-weight: 400;
line-height: 12px;
letter-spacing: -0.011em;
text-align: left;
      
    }

    .event-main-title{
      width: 50vw;
    }

  }
  .last{
    padding-top: 20%;
    background: url('../../Pages/About/bg.png');
    z-index: 50;
  }




  
  
  