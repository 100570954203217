@font-face {
    font-family: 'PP_Mori';
    src: url('../../../fonts/PPMori-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Zaio';
    src: url('../../../fonts/Zaio.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
.papa{
    display: flex;
    flex-direction: column;
   /* height: 58vh;*/
    /*width: 40vw;*/
    margin-top: 15vh;
    margin-left: 5vh;
    /* border: 1px solid black; */
    
    
}

.card1{
    display: flex;
    flex-direction: row;
    /* margin-top: 10vh;
    margin-left: 10vh; */
    background-color: white;
    border: white;
    border: 1px solid black;
    height: 45vh;
    /* /width: 94vh;/ */
}



.points-div{
    margin-top: -13vh;
   
   display: flex;
   flex-direction: row;
   justify-content: flex-end;
   padding-right: 5vh;
   margin-right: 2vh;
   height: fit-content;
   
   

}

.points{
    width: 10vw;
    height: 8vh;
    display: flex;
    background-color:#F76300;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-family: 'Zaio';
    font-size: 5vh;
    text-shadow: 1px 3px 0px #000000;
    border: 1px solid black;
}

.star-img{
    width: 10vh;
    height: 10vh;
    margin-bottom: -8vh ;
    margin-right: -20vh;
    z-index: 3000;
    /* margin-left: 10vh; */
}

.stardiv{
    display: flex;
    flex-direction: column;
    
    height: fit-content;
    /* margin-left: 10vh; */
}

.face-img{
    width: 25vh;
    height: 25vh;
    margin-left: 6vh;
}

.usersname{
    font-family: 'PP_Mori';
    font-size: 4vh;
    color: black;
    text-align: center;
}

.profile_img{
    /* width: 50%; */
    margin-top: 2vh;
    /* margin-left: 3vh; */
    
}

.nameandreferral{
    width: 40vh;
    margin-top:3vh ;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    color: black;
    font-family: 'PP_Mori';
    font-weight: 400;
}

.referral{
    color: orange;
    font-family: 'PP_Mori';
    size: 6vh;
}

.details{

    /* color: black; */
    display: flex;
    flex-direction: column;
    
    height: 45vh;
    justify-content: center;
    /* align-items: center; */
    row-gap: 2vh;
    color: grey;
    

}

.info{
    display: flex;
    flex-direction: row;
    column-gap: 3vh;
    justify-content: flex-start;
    color: black;

}

.smallimg{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 2vh;
    justify-content: center;
}

.right-side{
    font-family: 'PP_Mori';
    display: flex;
    flex-direction: column;
    height: 80%;
    padding: 2vw;

}

.info-para{
    font-family: 'PP_Mori';
    color: black;
    font-size: 18px;
}

.profile{
    font-family: 'PP_Mori';
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
}
    /* For hamburger menu that shows profile details of user */
 /* @media only screen and (max-width: 768px){
    .papa{
        display: flex;
        position:relative;
        flex-direction: column;
        align-items: flex-start;
        width: fit-content;
    }
    .card1{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
        border:none;
    }
    .right-side{
        display: flex;
        padding: 0.125rem 0rem;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
    }
    .profile{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
    }
    .points-div, .details{
        display: none;
    }
    .nameandreferral{
        border-bottom: 1px #C2C2C2 solid;
    }

 } */







@media only screen and (max-width: 768px) {
    .papa{
       width: 100vw;
        background-color: white !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left:0;
        height: 100%;
    }
    .card1{
        display: flex;
        flex-direction: column;
        height: auto;
        width: 100vw; 
        justify-content: center;
        align-items: center;
        border: none;
    }
    .referral{
        color: orange;
    /* margin-top: -10vh;  */
       
    }
    .details{
        margin-top: 2vh;
    color: black; 
        display: flex;
        flex-direction: column;
        width: 100vw;
        height: 38vh;
        justify-content: center;
    align-items: center; 
        row-gap: 2vh;
        color: grey;
        
    
    }
    .right-side{
        width: 100vw;
        margin-top: 2vh;
        height: auto;
    }
    .profile_img{
        transform: translateX(-5vw);
        width:100vw;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .points-div{
     margin-top: 0.001vh;
       display: flex;
       flex-direction: row;
       justify-content: center;
       width: 100vw;
       height: fit-content;
       margin-right: 0;
       padding-right: 0;
    }
    .points{
        width: 50vw;
        height: 10vh;
        display: flex;
        background-color: #F76300;
        justify-content: center;
        text-align: center;
        align-items: center;
        font-family: 'Zaio';
        font-size: 6vh;
    }
    .star-img{
        margin-bottom: -10vh ;
        margin-right: -20vh;
        z-index: 100;
    }
    .stardiv{
        display: flex;
        flex-direction: column;
        height: fit-content; 
    }
    .face-img{
        width: auto;
        height: 25vh;
        margin-left: 4vh;
    }
    
    .usersname{
        color: black;
        text-align: center;
    }
    
    /* .profile_img{
    /* width: 50%;  */
        /* margin-top: 2vh; */
    /* margin-left: 3vh;  */
        
    
    
.nameandreferral{
        width: 100%; 
    height: 0; 
        margin-top:3vh ;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        font-size: 3vh !important;
        color: black;
    }
     .profile{
        width: 100vw;
     }
    .info{
        width: 90%;
        display: flex;
        flex-direction: row;
        column-gap: 3vh;
        justify-content: flex-start;
        color: black;
        font-size: 0.01vh !important;
        align-content: center;
        align-items: center;
    }
    
    .smallimg{
        height: 5vh;
        width: 7vh;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 2vh;
        justify-content: center;
    }
    
    .right-side{
        display: flex;
        flex-direction: column;
        height: 80%;
        width: 100vw;
    }
    .info-para{
        font-size: 20px;
        color: black;
    }     
    .profile{
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
    align-items: center; 
    } 
}