* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: Arial, sans-serif;
  }
  
  .loginbackground {
    width: 100vw;
    background-image: url('./bg.png');
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-repeat:repeat-y;
  }
  
  .bluebox {
    margin-top: 20vh;
    margin-bottom: 40vh;
    clip-path: polygon(
      30px 0px,
      30px 10px,
      20px 10px,
      20px 20px,
      10px 20px,
      10px 30px,
      0px 30px,
      0px calc(100% - 30px),
      10px calc(100% - 30px),
      10px calc(100% - 20px),
      20px calc(100% - 20px),
      20px calc(100% - 10px),
      30px calc(100% - 10px),
      30px 100%,
      calc(100% - 30px) 100%,
      calc(100% - 30px) calc(100% - 10px),
      calc(100% - 20px) calc(100% - 10px),
      calc(100% - 20px) calc(100% - 20px),
      calc(100% - 10px) calc(100% - 20px),
      calc(100% - 10px) calc(100% - 30px),
      100% calc(100% - 30px),
      100% 30px,
      calc(100% - 10px) 30px,
      calc(100% - 10px) 20px,
      calc(100% - 20px) 20px,
      calc(100% - 20px) 10px,
      calc(100% - 30px) 10px,
      calc(100% - 30px) 0px,
      30px 0px
    );
    /* height: 500px; */
    width: 950px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2D48D5;
    color: white;
    padding: 20px;
    flex-direction: column;
    outline: 2px solid black; 
    transition: opacity 1s ease-in-out;
    /* margin-top: 10vh; */
  }
  
  
  .bluebox p {
    font-family: 'That_New_Pixel';
    font-style: italic;
    font-size: 3rem;
    margin-bottom: 20px;
    color: #F3FF63;
  }
  
  .bluebox input {
    color: black;
    font-family: 'FK_Grotesk';
    width: 605px;
    height: 35px;
    padding-left: 10px;
    padding-right: 10px;
    border: none;
    border-radius: 0px;
    font-size: 1rem;
    background-color: #F7F8FF;
  }

  .bluebox input::placeholder{
    color: #C1C3C6;
  }
  
  .button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .bluebox button {
    height: 52px;
    clip-path: polygon(
      15px 0px,
      15px 5px,
      10px 5px,
      10px 10px,
      5px 10px,
      5px 15px,
      0px 15px,
      0px calc(100% - 15px),
      5px calc(100% - 15px),
      5px calc(100% - 10px),
      10px calc(100% - 10px),
      10px calc(100% - 5px),
      15px calc(100% - 5px),
      15px 100%,
      calc(100% - 15px) 100%,
      calc(100% - 15px) calc(100% - 5px),
      calc(100% - 10px) calc(100% - 5px),
      calc(100% - 10px) calc(100% - 10px),
      calc(100% - 5px) calc(100% - 10px),
      calc(100% - 5px) calc(100% - 15px),
      100% calc(100% - 15px),
      100% 15px,
      calc(100% - 5px) 15px,
      calc(100% - 5px) 10px,
      calc(100% - 10px) 10px,
      calc(100% - 10px) 5px,
      calc(100% - 15px) 5px,
      calc(100% - 15px) 0px,
      15px 0px
    );
    padding: 10px;
    border: none;
    border-radius: 0px;
    font-size: 1.2rem;
    cursor: pointer;
    background-color: #F3FF63;
    color: #2D48D5;
    font-family: 'FK_Grotesk';
    transition: background-color 0.3s ease-in-out;
    /* box-shadow: 10px 10px 5px black; */
  }
  
  /* .bluebox button:hover {
      margin: 2px 4px 4px 1px;
  }
   */
  .bluebox a {
    color: white;
    text-decoration: none;
  }
  
  .bluebox a button {
    height: 52px;
    clip-path: polygon(
      15px 0px,
      15px 5px,
      10px 5px,
      10px 10px,
      5px 10px,
      5px 15px,
      0px 15px,
      0px calc(100% - 15px),
      5px calc(100% - 15px),
      5px calc(100% - 10px),
      10px calc(100% - 10px),
      10px calc(100% - 5px),
      15px calc(100% - 5px),
      15px 100%,
      calc(100% - 15px) 100%,
      calc(100% - 15px) calc(100% - 5px),
      calc(100% - 10px) calc(100% - 5px),
      calc(100% - 10px) calc(100% - 10px),
      calc(100% - 5px) calc(100% - 10px),
      calc(100% - 5px) calc(100% - 15px),
      100% calc(100% - 15px),
      100% 15px,
      calc(100% - 5px) 15px,
      calc(100% - 5px) 10px,
      calc(100% - 10px) 10px,
      calc(100% - 10px) 5px,
      calc(100% - 15px) 5px,
      calc(100% - 15px) 0px,
      15px 0px
    );
    padding: 10px;
    border: none;
    border-radius: 0px;
    font-size: 1.2rem;
    cursor: pointer;
    background-color: #C1C3C6;
    color: #2D48D5;
    transition: background-color 0.3s ease-in-out;
    box-shadow: 10px 5px 5px rgba(0, 0, 0, 0.2);
    padding-left: 5vw;
    padding-right: 5vw;
  }
  .button-container-wrapper-outter{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .button-container-wrapper {
    width: 30vw;
    clip-path: polygon(
      15px 0px,
      15px 5px,
      10px 5px,
      10px 10px,
      5px 10px,
      5px 15px,
      0px 15px,
      0px calc(100% - 15px),
      5px calc(100% - 15px),
      5px calc(100% - 10px),
      10px calc(100% - 10px),
      10px calc(100% - 5px),
      15px calc(100% - 5px),
      15px 100%,
      calc(100% - 15px) 100%,
      calc(100% - 15px) calc(100% - 5px),
      calc(100% - 10px) calc(100% - 5px),
      calc(100% - 10px) calc(100% - 10px),
      calc(100% - 5px) calc(100% - 10px),
      calc(100% - 5px) calc(100% - 15px),
      100% calc(100% - 15px),
      100% 15px,
      calc(100% - 5px) 15px,
      calc(100% - 5px) 10px,
      calc(100% - 10px) 10px,
      calc(100% - 10px) 5px,
      calc(100% - 15px) 5px,
      calc(100% - 15px) 0px,
      15px 0px
    );
    padding-bottom: 1vh;
    padding-right: 1vh;
    border: none;
    border-radius: 0px;
    font-size: 1.2rem;
    cursor: pointer;
    background-color: #000000;
    color: #2D48D5;
    /* display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row; */
  }
  .please-login{
    text-align: center;
    font-size: 2.5rem;
  }
  
  
  .error{
    margin-top: 2vh;
    color: #F3FF63;
    font-family: 'FK_Grotesk';
    font-size: 1.2rem;
  }
  
  @media (max-width: 768px) {
    .bluebox {
      margin-top: 15vh;
      padding-left: 5vw;
      width: 85vw;
      margin-bottom: 15vh;
    }
  
    .bluebox input {
      width: 50vw;
    }
  
    .bluebox a button {
      width: 50vw;
      height: 50px;
    }
    .input-feild-wrapper{
      width: 75vw!important;
    }
    .bluebox-container-wrapper{
      padding-bottom: 1vh;
      padding-right: 1vh;
    }
    .bluebox p{
      margin-left: 2vw;
      margin-right: 2vw;
      text-align: center;
      font-size: 2rem;
    }
    .form-detailss p{
      font-size: 1.5rem!important;
    }
    .button-container-wrapper-outter{
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .button-container button{
       padding-left: 10vw;
       padding-right: 10vw;
    }
    .button-container-wrapper{
      width: 70vw;
    }
  }