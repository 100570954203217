@font-face {
    font-family: 'PP_Mori';
    src: url("../../../fonts/PPMori-SemiBold.otf") format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gridular';
    src: url('../../../fonts/Gridular-Regular.ttf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Typewriter';
    src: url('../../../fonts/McGareyRegular.ttf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roslindale';
    src: url('../../../fonts/Roslindale-DeckNarrowRegular-Testing.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

* {
    font-family: 'PP_Mori';
}

.calb_container {
    background-image:url('./LB-bg.png');
    /* padding: 5%; */
    background-color: white;
    min-height: 100vh;
}


.leader-points{
    margin-left: 57vw;
}


.highlighted {
    background-color: #ff0000;
    color: #ffffff;
}

.podium-container{
    margin-top: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* align-items: center; */
    gap: 6vw;
    height: auto;

}
.calb_table {
    
    margin-top: 10vh;
    width: 100%;
    /* border-collapse: collapse;   */
}
.podium2{
    margin-top: 15vh;
}
.podium3{
    position: relative;
    margin-top: 20vh;
}

.entry-points{
    font-size: 3vh;
    position: absolute;
    margin-top: -8vh;
    z-index: 500;
    margin-left: 10vh;
}

.entry-name{
    position: absolute;
    color: black;
    z-index: 800;
    margin-top: -11vh;
    padding-bottom: -20vh;
    margin-left: 8vh;
    /* margin-bottom: -5vh; */

}

.position-star,.position-star-1,.position-star-2,.position-star-3{
    position: absolute;
    margin-left: -13vh;
    margin-top: -5vh;
}

.points-div-leader{
    margin-left: 50vw;
    display: flex;
    justify-content: center;
    font-size: 4vh;
    width: 8vw;
    height: 6vh;
    background-color:#F7B602;
}
.leader-position{
    color: black;
    font-family: 'PP_Mori';
}
.table-container{
    padding: 1.5vh;
    height: auto;
    margin-top: 5vh;
  margin-left: 10vw;
  margin-right: 10vw;
}
.stats-row{
    /* justify-content: center; */
    align-items: center;
    background-color: white;
    border: 1px solid black;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 10vh;
    color: black;
    padding: 2vh;
}
.leader-name{

    width: 10vw;
    font-family: "PP_Mori";
    color: black;
    margin-left: 10vw;
}
.heading-row{
    display: flex;
    flex-direction: row;
    padding: 1vh;
    font-size: medium;
   
}
.name-heading{
    margin-left: 9vw;
    color: black !important;
}
.position-heading{
    color: black !important;
}

.leader-position{
    margin-left: 1vw;
    display: flex;
    font-family: 'PP_Mori';
}
.leader-points{
    color: black !important;
}


@media only screen and (max-width: 768px) {
   


.podium-container{
    padding: 1.5vh;
    margin-top: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* align-items: center; */
    gap: 6vw;
    height: auto;

}
.calb_table {

    margin-top: 10vh;
    width: 100%;
    /* border-collapse: collapse;   */
}
.podium2{
    margin-top: 15vh;
}
.podium3{
    position: relative;
    margin-top: 20vh;
}
.leader-points{
    margin-left: 38vw;
}

.entry-points{
    font-size: 2vh;
    position: absolute;
    margin-top: -3.4vh;
    z-index: 500;
    margin-left: 2.1vh;
}
.position-star-2{
    height: 6vh;
    width: 10vw;
    position: absolute;
    margin-left: -2vh;
    margin-top: -0vh;
}
.position-star-1{
    height: 6vh;
    width: 10vw;
    position: absolute;
    margin-left: -2vh;
    margin-top: -0vh;
}
.position-star-3{
    height: 6vh;
    width: 10vw;
    position: absolute;
    margin-left: -2vh;
    margin-top: -0vh;
}
.entry-name{
    font-size: 1.2vh;
    position: absolute;
    color: black;
    z-index: 800;
    margin-top: -4.8vh;
    /* padding-bottom: -20vh; */
    margin-left: 1.35vh;
    /* margin-bottom: -5vh; */

}

.position-star{
    
    display: block;
    position: absolute;
    margin-left: -13vh;
    margin-top: -5vh;
}
.points-div-leader{
    margin-left: 42vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2vh;
    width: 15vw;
    height: 4vh;
    background-color:#F7B602;
}
.leader-position{
    /* margin-left: 1vh; */
    color: black;
    font-family: 'PP_Mori';
}
.table-container{
    padding: 1.5vh;
    margin-left: 0vh;
    margin-right: 0vh;
    height: auto;
    margin-top: 5vh;
}
.stats-row{
    /* justify-content: center; */
    align-items: center;
    background-color: white;
    border: 1px solid black;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 10vh;
    color: black;
}
.leader-name{

    width: 10vw;
    font-family: "PP_Mori";
    color: black;
    margin-left: 15vw;
}
.heading-row{
    display: flex;
    flex-direction: row;
    color: white;
}
.name-heading{
    margin-left: 8vw;
}



}

@media only screen and (max-width: 1024px) and (min-width:768px) {
 
.table-container{
    padding: 1.5vh;
    margin-top: 5vh;
}

.leader-points{
    margin-left: 50vw;
}
.podium-container{
    padding: 2vh;
    margin-top: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* align-items: center; */
    gap: 6vw;
    height: auto;

}
.calb_table {
    /* padding: 2vh; */
    margin-top: 10vh;
    width: 100%;
    /* border-collapse: collapse;   */
}
.podium2{
    margin-top: 15vh;

}
.podium3{
    position: relative;
    margin-top: 20vh;
}

.entry-points{
    font-size: 2vh;
    position: absolute;
    margin-top: -4.5vh;
    z-index: 500;
    margin-left: 4vh;
}
.position-star-2{
    height: 8vh;
    width: 12vw;
    position: absolute;
    margin-left: -3vh;
    margin-top: -0vh;
}
.position-star-1{
    height: 8vh;
    width: 12vw;
    position: absolute;
    margin-left: -3vh;
    margin-top: -0vh;
}
.position-star-3{
    height: 8vh;
    width: 12vw;
    position: absolute;
    margin-left: -3vh;
    margin-top: -0vh;
}
.entry-name{
    font-size: 1.4vh;
    position: absolute;
    color: black;
    z-index: 800;
    margin-top: -7vh;
    /* padding-bottom: -20vh; */
    margin-left: 1.5vh;
    margin-bottom: 0vh;

}

.position-star{
    
    display: block;
    position: absolute;
    margin-left: -13vh;
    margin-top: -5vh;
}
.points-div-leader{
    margin-left: 38vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3vh;
    width: 15vw;
    height: 4vh;
    background-color:#F7B602;
}
.leader-position{
    color: black;
    font-family: 'PP_Mori';
}
.table-container{
   
    height: auto;
}
.stats-row{
    /* justify-content: center; */
    align-items: center;
    background-color: white;
    border: 1px solid black;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 10vh;
    color: black;
}
.leader-name{

    width: 10vw;
    font-size: 3vh;
    font-family: "PP_Mori";
    color: black;
    margin-left: 15vw;
}
.heading-row{
    display: flex;
    flex-direction: row;
    color: white;
}
.name-heading{
    margin-left: 8vw;
}

.leader-position{
    font-size: 3vh;
    margin-left: 1vw;
    display: flex;
    font-family: 'PP_Mori';
}

}