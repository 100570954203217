.frames-outer-wrapper{
    background: url('../../../assets/framesBg.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 130vh;
    padding-top: 50px ;
    position: relative;
    cursor: url('../../../assets/click.png'), auto;
}
.frames-photo-card{
    position: absolute;
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 10;
}
.frames-img{
    width: auto;
    height: auto;
}
.frames-techniche-heading{
    font-size: 4rem;
    font-family: 'That_New_Pixel';
}
.frames-techniche-subheading{
    font-family: 'FK_Grotesk';
    font-weight: 400;
}
.frames-techniche-heading span{
    font-family: 'That_New_Pixel';
    font-style: italic;
}
@media screen and (max-width:450px) {
   .frames-outer-wrapper{
    height: 100vh;
   }
   .frames-techniche-heading span{
    font-size: 2.5rem;
   }
   .frames-techniche-heading{
    font-size: 2.5rem;
   }
   .frames-techniche-subheading {
    font-size: 0.8rem;
   }
}