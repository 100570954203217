*{
    margin: 0;
    padding: 0;
}
.LongTileImage{
    display: block;
    clip-path: polygon(0% 4.5%, 0.5% 4.5%, 0.5% 3%, 1% 3%, 1% 1.5%, 1.5% 1.5%, 1.5% 0%,98.5% 0%, 98.5% 1.5%, 99% 1.5%, 99% 3%, 99.5% 3%, 99.5% 4.5%, 100% 4.5%,100% 95.5%, 99.5% 95.5%, 99.5% 97%, 99% 97%, 99% 98.5%, 98.5% 98.5%, 98.5% 100%,1.5% 100%, 1.5% 98.5%, 1% 98.5%, 1% 97%, 0.5% 97%, 0.5% 95.5%, 0% 95.5%
     );

}

.LongTileWrapper{
    z-index: 98;
    display: flex;
    margin-top: 10vh;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 62.8vw;
    padding: 0.2vh;
    background-color: black;
    align-items: center;
    clip-path: polygon(0% 4.5%, 0.5% 4.5%, 0.5% 3%, 1% 3%, 1% 1.5%, 1.5% 1.5%, 1.5% 0%,98.5% 0%, 98.5% 1.5%, 99% 1.5%, 99% 3%, 99.5% 3%, 99.5% 4.5%, 100% 4.5%,100% 95.5%, 99.5% 95.5%, 99.5% 97%, 99% 97%, 99% 98.5%, 98.5% 98.5%, 98.5% 100%,1.5% 100%, 1.5% 98.5%, 1% 98.5%, 1% 97%, 0.5% 97%, 0.5% 95.5%, 0% 95.5%
    );

}

.LongTileContainer{
    align-content: center;
    align-items: center;
    overflow: hidden;
    width: 65vw;
    position: relative;
    margin: 0.2vw;
    box-shadow: 2rem 2rem 1rem black;
}

.LongTileContainer p{
    font-family: 'FK_Grotesk_SemiMono';
    position: absolute;
    top: 80%;
    left: 2%;
    display: inline-flex;
    color: #2D48D5;
    font-weight: 400;
    font-size: 1.2rem;
    text-align: center;
}

.LongTileImage:hover{
    background: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    transition: height 0.3s ease-in-out;
    background-size: cover;
    background-attachment: fixed;
}


.RegWrapper{
    display: flex;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    top: 78%;
    right: 4%;
    justify-content: center;
    width: 11vw;
    padding: 0.05vh;
    background-color: black;
    align-items: center;
    clip-path: polygon(
        0% 12% ,3% 12%, 3% 8% , 6% 8%, 6% 4%, 9% 4%, 9% 0%, 91% 0% ,91% 4%, 94% 4%, 94% 8% , 97% 8%, 97% 12%, 100% 12%, 100% 88% ,97% 88%, 97% 92% , 94% 92%, 94% 96%, 91% 96%, 91% 100% , 9% 100% , 9% 96%, 6% 96% , 6% 92% , 3% 92% , 3% 88%, 0% 88%  
    );

}

.RegContainer{
    font-family: 'FK_Grotesk_SemiMono';
    align-content: center;
    align-items: center;
    overflow: hidden;
    width: 11vw;
    padding-top: 5%;
    padding-bottom: 5%;
    position: relative;
    margin: 0.3vh 0.8vh 0.8vh 0.3vh;
    text-align: center;
    font-weight: 100;
    font-size: 1rem;
    background-color: white;
    color: #2D48D5;
    clip-path: polygon(
        0% 12% ,3% 12%, 3% 8% , 6% 8%, 6% 4%, 9% 4%, 9% 0%, 91% 0% ,91% 4%, 94% 4%, 94% 8% , 97% 8%, 97% 12%, 100% 12%, 100% 88% ,97% 88%, 97% 92% , 94% 92%, 94% 96%, 91% 96%, 91% 100% , 9% 100% , 9% 96%, 6% 96% , 6% 92% , 3% 92% , 3% 88%, 0% 88%  
    );
}

.RegContainer:hover{
    cursor: pointer;
    background-color: #F3FF63;
    font-style: italic;
    margin: 0.0vh 0.4vh 0.4vh 0.0vh;
    transform: translate(0.2vh, 0.2vh); /* Moves the button diagonally */
  transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
}


@media (max-width: 1050px){
    
    .LongTileWrapper{
        width: 85vw;
    }

    .LongTileContainer{
        width: 90vw;
    }

    .RegWrapper{
        width: 15vw;
    }

    .RegContainer{
        width: 15vw;
    }
}

@media (max-width : 768px){

    .LongTileWrapper{
        margin-top: 5vh;
    }

    .LongTileContainer p{
        top: 60%;
    }

    .RegWrapper{
        top: 70%;
        width: 20vw;
    }

    .RegContainer{
        width: 20vw;
    }
    .LongTileContainer p{
        font-size: 0.8rem!important;
    }
}

@media (max-width : 600px){

    .LongTileContainer{
        height: 30vw;
    }

    .LongTileWrapper{
        height: 30vw;
    }

    .LongTileImage{
        height: 28.8vw;
    }

    .LongTileContainer p{
        /* top: 9%; */
    }

    .RegWrapper{
        display: none;
        top: 58%;
        right: 35%;
    }

    .RegContainer{
        display: none;
       font-size: 75%;
    }

    .RegWrapper{
        width: 40vw;
    }

    .RegContainer{
        width: 40vw;
    }

}

@media (max-width : 400px){

    .LongTileContainer{
        height: 35vw;
    }

    .LongTileWrapper{
        height: 29vw;
    }

    .LongTileImage{
        height: 33.8vw;
    }

    .LongTileContainer p{
        font-size: 120%;
    }

}