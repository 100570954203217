.LongTileWrapper {
    position: relative;
    overflow: hidden;
    z-index: 98;
}

.LongTileContainer {
    position: relative;
    background: linear-gradient(to top, rgba(0,0,0,0.7), rgba(0,0,0,0) 70%);
    border-radius: 8px; 
    overflow: hidden; 
    background-color: black;
    margin: 0.2vh 0.3vh 0.3vh 0.2vh;;
}

.LongTileImage {
    width: 100%;
    height: auto;
    display: block;
}

.LongTileContainer p {
    font-size: 1.2rem;
    font-family: 'FK_Grotesk';
    color: white;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 16px;
}
.LearnMoreWrapper:hover .LearnMoreContainer {
    color: #F3FF63 /* Changes the text color to yellow on hover */
}

.LearnMoreWrapper:hover .LearnMoreicon {
    --ci-primary-color:#F3FF63;
}
.LearnMoreicon{
    padding-left: 1vh;
    height: 3vh;
}
.LearnMoreWrapper {
    display: flex;
    align-items: baseline;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    top: 78%;
    right: 4%;
    justify-content: center;
    padding: 0.05vh;
    align-items: center;
    opacity: 0; 
    transition: opacity 0.3s ease;
}

.LongTileWrapper:hover .LearnMoreWrapper {
    opacity: 1; 
}

.LearnMoreContainer {
    font-family: 'FK_Grotesk';
    align-content: center;
    align-items: center;
    overflow: hidden;
    padding-top: 10%;
    position: relative;
    margin: 0.3vh 0.8vh 0.8vh 0.3vh;
    text-align: center;
    font-weight: 500;
    font-size: 1rem;
    color: white;
}

.LearnMoreContainer:hover {
    cursor: pointer;
}
