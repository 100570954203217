@font-face {
    font-family: 'PP_Mori';
    src: url('../../../fonts/PPMori-SemiBold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: 'PP_Mori';
    src: url('../../../fonts/PPMori-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Zaio';
    src: url('../../../fonts/Zaio.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
.CApage{
    height: 100vh;
    background-color: white;
}
.taskpage{
    background-color: rgb(255, 255, 255) !important;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap:5vh;
    margin-right: 10vw;
    /* /left: 45vw;/ */
}
.taskdivhead{
    top: 12vh;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 21.5vw;
    margin-bottom: 2vh;
    
}
.dashtask{
    display: flex;
    flex-direction: column;
    gap:4vh;
}
.taskheading{
font-size: 8vh;
height: 12vh;
font-family:'PP_Mori';
color: #000000;
font-weight: 400;

}
.filtertaskbtn{
    position: relative;
    color: #000000;
    font-size: 3vh;
    margin-top: 4vh;
    font-family: 'Zaio';
    
}
.filteroption{
    text-align: center;
    font-family: 'Zaio';
    font-size: 24px;
    padding: 1vh 1vh 1vh 1vh;
    border-radius: 40px;
    border: 2px solid black;
    transform-style: preserve-3d;
    width: 10vw;
    color: white;
    background-color: black;
}
.options{
    font-family: 'PP_Mori';
    text-shadow:1vw ;
}
.taskdisplay{
    color: #000000;
    
   /* margin-left:46vw;*/
    position: relative;
   margin-top: 10vh;
}
.text{
    color: #000;
}
.taskpoint{
    color: #000;
    border-radius: 1vw solid #000;
}
.eachtask{
    width:48vw;
    border: 0.1vw solid black;
    margin-bottom: 2vh;
   
}
.texttask{
    display: flex;
    color: #000000;
    font-style: 'PP_Mori';
    font-weight:40;
    font-size: 5vh;
    width:auto;
}
.taskd{
    display:flex;
    flex-direction:row;
    flex-wrap: wrap;
    gap: 2vw;
    margin: 1vw;
}
.taskpoint{
    -webkit-text-fill-color:white;
    text-align: center;
   margin-left: 3vw;
    border-style: solid;
    border-width: 0.1vw;
    border-color: #000000;
    background-color: rgba(247, 182, 2, 1) !important;
    padding-left: 1vw;
    padding-right: 1vw;
    font-size: 5vh;
    font-family: 'Zaio';
   font-style: normal;
   font-weight: 400;

   text-shadow: 1.35947px 1.90325px 0px #000000;
}
.cawork{
    color: #000000;
    margin: 1vw;
    font-family: 'PP Mori';
   font-style: normal;
font-size: 2.5vh;

z-index: 1;

}
.linkforsubmit{
    display: flex;
   justify-content: center;
    align-items: center;
    margin:1vw;
}
.linktask{
    background-color:rgba(217, 217, 217, 1);
    width: 35vw;
    padding: 0.4vw;
    border: #000000 solid 0.1vh;
}
.submission{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap:2vw;
    margin-bottom: 2vw;
    margin-left: 1vw;
    
}
.btnforsubmit{
    color: #000000;
}
.btnsubmit{
    margin-top: 1vh;
    width: 6vw;
    height: 6vh;
    color: #000000;
    image-rendering:auto;
   

}

.modal, .overlay{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    
}

.overlay{
    background: rgba(154, 152, 152, 0.6);
}

.modal-content{
    display: inline-flex;
    /* height:  */
    padding: 2.7rem;
    flex-direction: column;
    align-items:center;
    gap: 24px;
    flex-shrink: 0;
    border-radius: 12px;
    border: 1px solid #000;
    background: #FFF;
    color: #000;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.modal-heading{
    flex: 1 0 0;
    align-self: stretch;
    color: #000;
    font-size: 8vh;
    font-weight: 2000;
    line-height: normal;
}

.modal-desc{
    flex: 1 0 0;
    align-self: stretch;
    color: #000;
    /* font-size: 3vh; */
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

@media only screen and (max-width: 768px) {
    .cadashboard-container{
        width:100vw;
    }
    .taskpage{
        background-color: rgb(255, 255, 255) !important;
      
        /* height: 400vh; */
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap:5vh;
        margin-right: 10vw;
        /* /left: 45vw;/ */
    }
    
    .taskdivhead{
        top:0vh;
       
        justify-content: center;
        width: 100vw;
        display: flex;
      align-items: center;
       
        
    }
    .filtertaskbtn{
        margin-top: 0;
       
    }
    .taskheading{
       
       display: flex;
       align-items: center;
        flex-shrink: 0;
        color: #000;
        font-family: 'PP_Mori';
        font-size: 6vh;
        font-style: normal;
        font-weight: 300;
       
    }
    .taskdisplay{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 0vh;
    }
    .dashtask{
        background-color: white;
        width: 100vw; 
        align-items: normal !important;
    }
    
    .options{
        border-radius: 0.49138rem;
        border: 0.655px solid #000;
        background: #000;
        width: 3rem;
        height: 1.35919rem;
        flex-shrink: 0;
        color: #FFF;
        text-align: center;
        text-shadow: 0.603px 0.845px 0px #000;
        /* -webkit-text-stroke-width: 0.2945559620857239;
        -webkit-text-stroke-color: #000; */
        font-family: 'PP_Mori';
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.02rem;
    }
    .filteroption{
        width: 35vw;
        border-radius: 2vh;
    }
    .eachtask{
        display: flex;
        width: 80%;
        height: auto;
        
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
        flex-shrink: 0;
        border: 0.5px solid #000;
        background: #FFF;
        padding-top: 5vh;
        padding-bottom: 5vh;
        padding-left: 1vh;
        padding-right: 1vh;
    }
    .taskpoint {
        font-size: 4vh;
        padding-left: 5vw;
        padding-right: 5vw;
    }
    .taskd{
        display: flex;
        flex-wrap: wrap;
        gap: 1vw;
        margin: 0vw;
        gap:1vw;
    }
    .catags img{
        width: 30vw;
    }
    .btnforsubmit img{
        width: 40vw;
    }
    .texttask{
        justify-content: center;
        width:auto;
       /* display: flex;*/
        align-items: center;
        align-self: stretch;
        white-space: normal;
        flex-wrap: wrap;
        color: #000;
        font-family: "PP_Mori";
        margin-top: -4vh; 
       
    }
    .status-icon1{
        transform: translateX(5vw);
        width: 60vw !important;
    }
    .btnforsubmit input {
        width: 40vw;
    }
    .cawork{
        display: flex;
        flex-wrap: wrap;
       padding: 1vw;
        white-space: normal;
        color: #000;
        font-family: "PP_Mori";
        font-size: 3vh;
        font-style: normal;
        font-weight: 400;
        margin: 0;
      
    }
    .submission{
        display: flex;
        flex-direction: column;
        align-self: stretch;
        gap: 1vh;
    }
    .linktask{
        flex: 1;
        height: 3.5rem;
    }
    .btnsubmit{
        width: 13rem;
        height: 8rem;
        margin-top:0.001vh;
    }
    .dashtask{
        /* width: 100vw !important; */
        align-items: center;
        justify-content: center;
    }
    .modal{
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        
    }
    
    .overlay{
        width:100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        background: rgba(154, 152, 152, 0.6);
    }
    
    .modal-content{
        display: inline-flex;
        /* height:  */
        padding: 2.7rem;
        flex-direction: column;
        align-items:center;
        gap: 24px;
        flex-shrink: 0;
        border-radius: 12px;
        border: 1px solid #000;
        background: #FFF;
        color: #000;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }
    
    .modal-heading{
        flex: 1 0 0;
        align-self: stretch;
        color: #000;
        font-size: 8vh;
        font-weight: 2000;
        line-height: normal;
    }
    
    .modal-desc{
        flex: 1 0 0;
        align-self: stretch;
        color: #000;
        /* font-size: 3vh; */
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    
    
    .submission{
        gap:10vw;
        /*margin-left: 3vw;*/
    }
    .linktask{
        width: 40vw;
    } 
    .taskd{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;

    }
    .btnforsubmit{
        display: flex;
        justify-content: center;
        
    }
    .filteroption{
        height: 6vh;
        padding-left: 4vw;
        padding-right: 4vw;
    }
}