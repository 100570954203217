.footer {
  max-width: 100%;
  background-color: #0f1a34;
  color: white;
  position: relative;
  overflow: hidden;
  margin-top: 0vh;
}

.footer-container {
  margin-top: 6vh;
  width: 90%;
  gap: 17%;
  padding: 0 5%;
  display: flex;
  flex-wrap: wrap;
  z-index: 10;
}

.registerghm {
  font-size: 3vw;
}

.footer-section {
  flex: 1;
  min-width: 250px;
  margin-bottom: 32px;
}

/* Logo Section */

.marathon-logo {
  display: flex;
  align-self: center;
  text-align: center;
  justify-content: space-evenly;
  margin-bottom: 32px;
}

.footer-title {
  width: 40%;
}

.footer-title-img {
  width: 100%;
}


.social-icons {
  justify-content: space-evenly;
  display: flex;
  gap: 48px;
  margin-top: 4vw;
}

.social-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s;
}

.social-icon:hover {
  opacity: 0.8;
}

/* Leaf Logo Section */
.Leaf {
  margin-left: 15%;
  width: 20%;
}

.Leaf-img {
  width: 100%;
}

/* Links and Contact Section */
.links-contact-section {
  flex: 1;
  min-width: 250px;
  display: flex;
  flex-wrap: wrap;
}

.quick-links,
.contact-us {
  color: white;
  display: inline-block;
  justify-items: center;
  flex: 1;
  min-width: 150px;
  margin-left: 4vw;
}

.section-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 32px;
  margin-top: 0;
}

.links-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.links-list li {
  margin-bottom: 18px;
}

.links-list a,
.email-link,
.creator-link {
  color: white;
  text-decoration: none;
  transition: text-decoration 0.2s;
}

.links-list a:hover,
.email-link:hover,
.creator-link:hover {
  text-decoration: underline;
}

.email-link {
  display: inline-block;
  margin-bottom: 2px;
}

.created-by {
  margin-top: 28px;
}

.created-label {
  font-size: 12px;
  color: #a0a0a0;
  margin-bottom: 4px;
}

.creator-link {
  display: block;
  font-weight: 500;
  margin-bottom: 4px;
}

/* Decorative Elements */
.bottom-element {
  padding-top: 2px;
  display: flex;
  margin-top: 64px;
  background-color: #0A1633;
}

.bottom-element div {
  width: 25%;
}

.inks-contact-section {
  gap: 6vw;
}


/* Responsive adjustments */

@media (max-width: 1280px) {

  .footer-container {
    gap: 20%;
  }
}

@media (max-width: 1024px) {
  .footer-container {
    gap: 50%;
  }

  .Leaf {
    width: 20%;
  }

  .footer-title {
    width: 40%;
  }

  .marathon-logo {
    margin-bottom: 10%;
  }

  .links-contact-section {
    padding-top: 8%;
  }


}



@media (max-width: 768px) {
  .footer-container {
    gap: 50%;
  }

  .footer {
    padding-top: 15%;
  }

  .marathon-logo {
    margin-bottom: 12%;
  }


  .links-contact-section {
    gap: 20%;
  }

  .bottom4 {
    display: none;
  }

  .bottom-element div {
    width: 33.3333333333333%;
  }
}

@media (max-width: 500px) {
  .section-title {
    font-size: 16px;
  }

  .footer-container {
    padding: 0 0;
    margin: 0 5%;
  }

  .quick-links h2 {
    margin-bottom: 23px;
  }

  .contact-us {
    margin-left: 15%;
  }



  .contact-us a {
    font-size: 12px;
  }

  .creator-link {
    font-size: 12px;
  }

  .created-label {
    font-size: 10px;
  }

  .Leaf {
    margin: 0;
  }

  .links-contact-section {
    gap: 0;
  }

  .social-icons {
    gap: 0;
  }

  .bottom3 {
    display: none;
  }

  .links-contact-section {
    display: inline-block;
  }

  .bottom-element div {
    width: 50%;
  }

}

@media (max-width: 400px) {


  .contact-us {
    float: right;
    margin-left: 0;
  }

  .social-icon g {
    transform: scale(0.75);
    translate: 5px 5px;
  }

  .footer-container {
    width: 100%;
    margin: 0;
  }

}