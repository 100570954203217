.clip-class{
    height: 200px;
    clip-path: polygon(
      12px 0px,
      12px 4px,
      8px 4px,
      8px 8px,
      4px 8px,
      4px 12px,
      0px 12px,
      0px calc(100% - 12px),
      4px calc(100% - 12px),
      4px calc(100% - 8px),
      8px calc(100% - 8px),
      8px calc(100% - 4px),
      12px calc(100% - 4px),
      12px 100%,
      calc(100% - 12px) 100%,
      calc(100% - 12px) calc(100% - 4px),
      calc(100% - 8px) calc(100% - 4px),
      calc(100% - 8px) calc(100% - 8px),
      calc(100% - 4px) calc(100% - 8px),
      calc(100% - 4px) calc(100% - 12px),
      100% calc(100% - 12px),
      100% 12px,
      calc(100% - 4px) 12px,
      calc(100% - 4px) 8px,
      calc(100% - 8px) 8px,
      calc(100% - 8px) 4px,
      calc(100% - 12px) 4px,
      calc(100% - 12px) 0px,
      12px 0px
    );
  }
  .clip-class-prize{
    height: 10vh;
    clip-path: polygon(
      12px 0px,
      12px 4px,
      8px 4px,
      8px 8px,
      4px 8px,
      4px 12px,
      0px 12px,
      0px calc(100% - 12px),
      4px calc(100% - 12px),
      4px calc(100% - 8px),
      8px calc(100% - 8px),
      8px calc(100% - 4px),
      12px calc(100% - 4px),
      12px 100%,
      calc(100% - 12px) 100%,
      calc(100% - 12px) calc(100% - 4px),
      calc(100% - 8px) calc(100% - 4px),
      calc(100% - 8px) calc(100% - 8px),
      calc(100% - 4px) calc(100% - 8px),
      calc(100% - 4px) calc(100% - 12px),
      100% calc(100% - 12px),
      100% 12px,
      calc(100% - 4px) 12px,
      calc(100% - 4px) 8px,
      calc(100% - 8px) 8px,
      calc(100% - 8px) 4px,
      calc(100% - 12px) 4px,
      calc(100% - 12px) 0px,
      12px 0px
    );
  }
  .clip-bg{
    clip-path: polygon(
      0px 0px,
      0px calc(100% - 30px),
      10px calc(100% - 30px),
      10px calc(100% - 20px),
      20px calc(100% - 20px),
      20px calc(100% - 10px),
      30px calc(100% - 10px),
      30px 100%,
      calc(100% - 30px) 100%,
      calc(100% - 30px) calc(100% - 10px),
      calc(100% - 20px) calc(100% - 10px),
      calc(100% - 20px) calc(100% - 20px),
      calc(100% - 10px) calc(100% - 20px),
      calc(100% - 10px) calc(100% - 30px),
      100% calc(100% - 30px),
      100% 0px,
      0px 0px
    );
  }

  .clip-btn{
    clip-path: polygon(
      8px 0px,
      8px 4px,
      4px 4px,
      4px 8px,
      0px 8px,
      0px calc(100% - 8px),
      4px calc(100% - 8px),
      4px calc(100% - 4px),
      8px calc(100% - 4px),
      8px 100%,
      calc(100% - 8px) 100%,
      calc(100% - 8px) calc(100% - 4px),
      calc(100% - 4px) calc(100% - 4px),
      calc(100% - 4px) calc(100% - 8px),
      100% calc(100% - 8px),
      100% 8px,
      calc(100% - 4px) 8px,
      calc(100% - 4px) 4px,
      calc(100% - 8px) 4px,
      calc(100% - 8px) 0px,
      8px 0px
    );
  }
  @media screen and (max-width:700px) {
    .clip-class{
      width: 80vw;
      height: 20vh;
    }
    .clip-class img{
      height: 20vh;
    }
    .clip-class-prize{
      width: 50vw;
      height: 10vh;
    }
  }
