*{
    margin: 0;
    padding: 0;
}

.container-confirm{
  background: url("../GhmAssets/bg.png") no-repeat center center/cover;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 3vh;
    justify-content: center;
    align-items: center;
}
.container-confirm h1{
    color: #0C0E3D;
}