/*******************************************************
 * GENERAL STYLES
 *******************************************************/
 * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  a {
    text-decoration: none;
    cursor: pointer;
  }
  
  button {
    cursor: pointer;
    border: none;
    outline: none;
    background: none;
  }
  
  /*******************************************************
   * NAVBAR STYLES
   *******************************************************/
  .navbar-container {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 999;
    background: #0e1f48;
    border-bottom: 0.4px solid #fff6e4;
  }
  
  .navbar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 24px 120px;
  }
  
  /*******************************************************
   * BACK BUTTON
   *******************************************************/
  .nav-back-btn {
    font-family: "DM Sans", sans-serif;
    font-weight: 600;
    font-size: 1.3vw;
    color: #fffcf5;
    transition: opacity 0.2s ease-in-out;
  }
  
  .nav-back-btn:hover {
    opacity: 0.7;
  }
  
  /*******************************************************
   * LOGO IMAGE
   *******************************************************/
  .nav-logo-img {
    width: 180px;
    height: auto;
  }
  
  /*******************************************************
   * MOBILE VIEW
   *******************************************************/
  @media screen and (max-width: 768px) {
    .navbar-content {
      padding: 24px 16px;
      min-height: 71px;
    }
  
    .nav-back-btn {
      font-size: 16px;
    }
  
    .nav-logo-img {
      width: 106px;
    }
  }
  