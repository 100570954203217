/* Navbar.css */
@font-face {
  font-family: "Armageda";
  src: url("../../../fonts/Armageda Wide.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}
.carddd .entry {
  transition: transform 0.3s ease;
  cursor: pointer;
}

.carddd .entry:hover {
  transform: scale(1.05);
}
.CAPage {
  height: 100vh;
  background-color: white;
}

.canav {
  /* padding-bottom: 3vh; */
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  background-color: #ffe0ca !important;
  color: black;
  z-index: 1000;
  box-sizing: border-box;
  background: #ffffff;
}

nav ul {
  display: flex;
  list-style: none;
}

nav ul li {
  padding-left: 10vw;
  cursor: pointer;
  color: black;
}

nav ul li:hover {
  color: #f76300;
}

.navbutton {
  position: absolute;
  right: 5%;
  background-color: transparent !important;
  border: 1px solid black;
  padding: 0.5rem 1rem;
  border-radius: 12px;
  cursor: pointer;
}

.mobilenav {
  display: none;
}

@media (max-width: 500px) {
  .canav {
    padding-bottom: 0vh;
  }
  .mobilenav {
    display: block;
    position: absolute;
    left: 80%;
    cursor: pointer;
  }

  nav ul {
    display: none;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 10vh;
    left: 0;
    background-color: #ffe0ca;
    z-index: 999;
  }

  nav ul.show {
    display: flex;
  }

  nav ul li {
    font-size: 24px;
    padding: 1rem;
    text-align: center;
  }

  nav ul li:hover {
    background-color: #f76300;
    color: white;
  }

  .navbutton {
    position: relative;
    right: 0;
  }
}

/* hero section */
.cahero {
  padding-top: 10vh;
  display: flex;
  position: relative !important;
  padding-inline-start: 7%;
  align-items: center;
  height: 85vh !important;
  background-color: white !important;
}

.cahero h1 {
  letter-spacing: 0.001rem;
  font-size: 5.5vmax;
  width: 100%;
  color: black !important;
  font-family: "Armageda";
}

.cahero span {
  font-family: "Armageda";
  color: #f76300;
}

.cahero p {
  font-size: medium;
  width: 80%;
  color: black !important;
}

.hero-con {
  width: 60%;
  min-height: 70%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2vmax;
}

.hero-con button {
  width: fit-content;
  padding: 2vmin 3vmax;
  background-color: #9a81ff;
  color: white;
  font-size: 2vmax;
  font-weight: 800;
  border-radius: 62px;
  border: 2px solid black;
  transform-style: preserve-3d;
  cursor: pointer;
  font-family: "Armageda";
  letter-spacing: 0.1vw;
}
.hero-con button:hover {
  transform: scale(1.05);
}

.hero-con button:before {
  content: "REGISTER";
  position: absolute;
  bottom: -5px;
  left: -5px;
  width: 100%;
  height: 100%;
  background-color: black;
  padding: 2vmin 3vmax;
  color: black;
  font-size: 1.6vmax;
  font-weight: 800;
  margin: auto 0px;
  border-radius: 62px;
  border: 2px solid black;
  transform: translateZ(-1px);
}
.hero-con .head1 .letter {
  display: inline-block;
  transition: transform 0.3s ease;
  color: black; /* Default color for letters */
}

.hero-con .head1 .letter:hover {
  transform: scale(1.2);
}

.hero-con .head1 .orange {
  color: #f76300; /* Specific color for IIT GUWAHATI */
}

.cahero img {
  /* width: 40%; */
  height: 100%;
}
.cahero img:hover {
  transform: scale(1.05);
}
.testcon {
  height: 60vh;
}

@media (max-width: 650px) {
  .testcon {
    height: auto;
  }
  .cahero {
    height: fit-content !important;
    flex-direction: column;
    padding-inline-start: 7%;
    margin-top: 5vh !important;
    padding-top: 15vh;
    /* padding-block: 5vh !important;*/
  }

  .hero-con {
    width: 100%;
    min-height: 50%;
  }

  .cahero img {
    width: 100%;
  }

  .steps {
    height: fit-content !important;
  }

  .steps-li-con {
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  .steps-li-con h2 {
    font-size: 4vmax !important;
    width: 100% !important;
    text-align: center;
    justify-content: center !important;
  }

  .testcon h3 {
    font-size: 2.1vmax !important;
  }
  .testcon div {
    text-align: center;
    font-size: 1.8vmax !important;
  }

  .img-con {
    display: none !important;
  }
  .steps ul p {
    text-align: center;
    font-size: 2vmax !important;
  }

  .steps ul li {
    margin-left: 0 !important;
  }
}

/* section 2 */

.section2 {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.video-con {
  width: 100%;
  height: fit-content;
}

.video-con img {
  width: 100%;
}

.steps {
  background-color: white !important;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.steps h1 {
  width: 80%;
  color: black !important;
  font-size: 4.5vmax;
  text-align: center;
  padding-top: 5vh;
  margin-bottom: 5vh;
  font-family: "Armageda";
}

.steps span {
  font-family: "Armageda";
  color: #f76300;
}

.steps ul {
  width: 80%;
}

.steps ul li {
  padding: 5vmin 0;
  margin-left: 1.5rem;
  display: flex;
  align-items: center;
  gap: 2%;
  border-top: 2px solid black;
}

.steps ul li:last-child {
  border-bottom: 2px solid black;
}

.steps-li-con {
  display: flex;
  width: 100%;
}

.steps ul h2 {
  display: flex;
  justify-content: flex-start;
  width: 50%;
  font-size: 2.2vmax;
  color: black !important;
  font-family: "Armageda";
}

.steps ul h3 {
  font-size: 2vmax;
  width: 10%;
  color: black !important;
}

.steps ul p {
  text-align: right;
  font-size: 1vmax;
  color: black !important;
}

/* Carousel */
.carousel {
  background: url(../images/carousel_bg.png);
  background-repeat: no-repeat;
  /*background-size: contain;*/
  width: 100%;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.carousel h1 {
  width: 90%;
  color: black;
  font-size: 5vmax;
  text-align: center;
  margin-bottom: 5vh;
  margin-top: 5vh;
  font-family: "Armageda";
}

.carousel span {
  color: #f76300;
  font-family: "Armageda";
}

.splide {
  width: 91vw !important;
  overflow-x: hidden;
}

.splide__pagination {
  display: none !important;
}

.splide__arrow {
  transform: scale(1.8) !important;
  background-color: #f7b602 !important;
  opacity: 1 !important;
  color: black !important;
  transform-style: preserve-3d !important;
  border: 1px solid black !important;
}

.splide__arrow::before {
  content: "<";
  background-color: black;
  padding: 20% 35%;
  position: absolute;
  left: -2px;
  top: 1px;
  border-radius: 50%;
  transform: translateZ(-1px);
}

.testcon {
  display: flex;
  background-color: white;
  flex-direction: column;
  padding: 5%;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  border-radius: 20px;
}
.testcon h3 {
  text-align: center;
  font-size: 1.5vmax;
  color: white;
  text-shadow: 2px 3px 1px black;
  background-color: #f7b602;
  padding: 2px 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.testcon img {
  width: 94%;
  height: auto;
}

.testcon div {
  width: 94%;
  font-size: 1vmax;
  display: flex;
  justify-content: center;
  min-height: 15vmin;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid black;
  color: black;
}

/* accordion */
.carddd {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.entry {
  width: 75%;
  border: 2px solid black;
  transform-style: preserve-3d;
  background-color: white !important;
}

.entry:before {
  content: "";
  transform: translateZ(-10px);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 5px;
  left: -5px;
  background-color: black;
}

.show {
  max-width: 100%;
  min-height: 10vh;
  display: flex;
  align-items: center;
  padding: 0px 15px;
  justify-content: space-between;
}

.show p {
  text-align: center;
  width: 100%;
  font-size: 1.7vmax;
  font-weight: 500;
  color: black !important;
}

.show button {
  text-decoration: none;
  outline: 0px;
  border: 0px;
  background-color: #ffffff;
}

.show h4 {
  font-size: calc(0.7em + 1vw);
  font-weight: 600;
  color: black !important;
}

.show div {
  cursor: pointer;
  font-size: calc(0.7em + 1vw);
}

.toggle {
  padding: 0 2%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-size: 1.5vmax !important;
  color: black !important;
}

.space {
  height: 10px;
}

.faq {
  margin: 0;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2vh;
  background-color: white !important;
  color: black !important;
}

.left-faq {
  height: 10vh;
}

.left-faq-2 {
  height: 10vh;
}

.right-faq {
  height: 10vh;
}

.img-con {
  margin-top: 5vh;
  width: 100%;
  padding: 0 5%;
  margin-bottom: 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.img-con-2 {
  width: 100%;
  padding: 0 5%;
  margin-bottom: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}
