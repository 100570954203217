.input-wrapper {
    border-radius: 20px;
    margin-top: 10vh;
    background-color: white;
    width: 50%;
    height: 5vh;
    box-shadow: 0px 0px 8px #ddd;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.input-search {
    background-color: transparent;
    height: 100%;
    border: none;
    width: 100%;
    font-size: 3vh;
    color: black;
}

.message-from-wo {
    text-align: center;
    margin-top: 5vh;
}

.message-from-wo p {
    color: greenyellow;
}

.message-from-wo b {
    color: greenyellow;
}

.search-bar-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

#search-icon {
    color: royalblue;
}

.user-container {
    color: black;
    height: 100%;
}

.results-container {
    color: black;
    margin-top: 20px;
}

.result-heading {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 10px;
    font-weight: bold;
}

.result-item {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 10px;
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
}

.result-item p {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.error-message {
    color: red;
    text-align: center;
}

.results-container p {
    text-align: center;
}
