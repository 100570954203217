@font-face {
    font-family: 'PP_Mori';
    src: url("../../../fonts/PPMori-SemiBold.otf") format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Gridular';
    src: url('../../../fonts/Gridular-Regular.ttf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Typewriter';
    src: url('../../../fonts/McGareyRegular.ttf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Roslindale';
    src: url('../../../fonts/Roslindale-DeckNarrowRegular-Testing.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  body{
  margin: 0;
  color: white;
  }
  
  .pp_morix{
    font-family: 'PP_Mori';
  }
  
  .roslindale{
    font-family: 'Roslindale';
  }
  
  .canavbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 100px;
    background-color: rgba(104, 18, 18, 0.5); 
    backdrop-filter: blur(3px);
    padding-top: 10vh;
  }
  
  .canavbar-logo img {
    height: 40px;
  }
  
  .canavbar-links {
    list-style-type: none;
    margin: 0 0 0 150px;
    padding: 0;
    display: flex;
    justify-content: center;
  }
  
  .canavbar-links li {
    margin: 0 50px;
  }
  
  .canavbar-links a {
    text-decoration: none;
    color: white;
    margin-right: 40px;
  
  }
  .canavbar-links a :hover{
    text-decoration: none;
    color: purple;
    margin-right: 40px;
  
  }
  
  .canavbar-auth a {
    text-decoration: none;
    color: white;
    margin-left: 10px;
    font-family: 'PP_Mori';
  }
  
  
  .register-button{
    color:white;
    background-color: black;
    border: 1px solid;
    border-radius: 20px;
    padding: 10px 30px;
    margin-left: 10px;
   
   
    margin-right: 20px;
  }
  
  
  /* Your existing styles for the header */
  
  /* Media query for small screens to show hamburger menu */
  /* Your existing styles for the header */
  
  .canavbar {
  display: flex;
  align-items: center;
  justify-content:center;
  padding: 20px 20px;
  background-color: rgba(0, 0, 0, 0.5); 
  backdrop-filter: blur(3px);
  }
  
  .canavbar-logo img {
    padding-right: 20px;
    padding-left: 20px;
  width: 80px; /* Adjust the logo size as needed */
  }
  
  .canavbar-links {
  display: flex;
  align-items: center;
  }
  .canavbar-links :hover{
    color: purple;
  
  }
  
  .canavbar-links ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  }
  
  .canavbar-links ul li {
  margin-right: 20px;
  }
  
  .canavbar-auth {
  display: flex;
  align-items: center;
  }
  
  .canavbar-auth a {
  color: #fff;
  text-decoration: none;
  margin-right: 20px;
  }
  
  .caregister-button {
  background-color: #fff;
  color: #333;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  }
  
  /* Hamburger menu styles */
  .cahamburger-menu {
  display: none;
  cursor: pointer;
  }
  
  .cahamburger-menu .line {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 5px;
  }
  .logout{
    color: #fff;
  }
  
  /* Media query for small screens to show hamburger menu */
  @media (max-width: 768px) {
  
  .canavbar {
    padding-left: 40px;
    padding-right: 40px;
    background-color: rgba(0, 0, 0, 0.5); 
    backdrop-filter: blur(3px);
    display: flex;
    justify-content: space-between;
  }
  
  .canavbar-links {
    display: none;
    flex-direction: column;
    /* align-items: center; */
    position: absolute;
    top: 60px; /* Adjust the distance from the top as needed */
    left: 0;
    background-color: rgba(0, 0, 0, 0.92); 
    backdrop-filter: blur(3px);
    width: 100vw;
    height: 60vh;
    font-size: 22px;
    font-family: 'Gridular';
    font-family: 'Typewriter';
    
  }
  .canavbar-links li{
    margin-top: 30px;
  }
  .canavbar-links.show {
    display: flex;
  }
  
  .canavbar-auth {
    display: none;
  }
  
  .cahamburger-menu {
    display: block;
  }
  }
  
  
  
  
  