.addteam-rw-container{
    margin-top: 20vh;
}
.addteam-rw-instruction-container{
    font-size: 1.2em;
    display: grid;
    align-items: center;
    justify-content: space-around;
    align-content: space-evenly;
    justify-items: center;
}
.addteam-rw-instruction-container a{
    color: rgb(114, 177, 255);
}
.addteam-rw-add-container{
    font-size: 1.2em;
    display: grid;
    align-items: center;
    justify-content: space-around;
    align-content: space-evenly;
    justify-items: center;
    margin-top: 10vh;
}
.addteam-rw-instruction-contents p{
    text-align: center;
}
.addteam-rw-add{
    display: flex;
    align-items: flex-start;
    width: 70vw;
    justify-content:space-evenly;
    margin-bottom: 5vh;
    padding-top: 5vh;
    padding-bottom: 5vh;
    background-color: rgba(225,225,225,0.2);
    box-shadow: 0px 24px 24px rgba(0,0,0,0.4);
    border: 1px solid rgba(225,225,225,0.1);
    border-radius: 10px;
}
.addteam-rw-add label{
    font-weight: 200;
}
.addteam-submit-button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15vw;
    height: 7vh;
    border-radius: 50px;
    font-weight: 300;
}
.addteam-rw-add input{
    margin-left: 5vw;
    width: 30vw;
    height: 5vh;
    font-weight: 300;
}
.view-team-button{
    margin-top: 5vh;
    display: flex;
    align-items: center;
    justify-content: center;
}


@media (max-width: 768px) {
    .addteam-rw-add{
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        width: 80vw;
        justify-content:space-evenly;
        margin-bottom: 5vh;
        padding-top: 5vh;
        padding-bottom: 5vh;
        background-color: rgba(225,225,225,0.2);
        box-shadow: 0px 24px 24px rgba(0,0,0,0.4);
        border: 1px solid rgba(225,225,225,0.1);
        border-radius: 10px;
    }
    .addteam-submit-button{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50vw;
        height: 7vh;
        border-radius: 50px;
        font-weight: 300;
        margin-bottom: 5vh;
    }
    .addteam-rw-add input{
        width: 60vw;
        height: 7vh;
        font-weight: 300;
    }
}