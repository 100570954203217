/* frontend/src/components/Events/Robowars/ViewTeamRW.css */

.viewteam-rw-container {
    margin-top: 20vh;
    margin-bottom: 20vh;
    margin-left: 20vw;
    padding: 20px;
    background-color: rgba(225,225,225,0.2);
    box-shadow: 0px 24px 24px rgba(0,0,0,0.4);
    border: 1px solid rgba(225,225,225,0.1);
    border-radius: 5px;
    max-width: 60vw;

  }
  
  .team-member {
    border-radius: 10px;
    margin-bottom: 15px;
  }
  
  .team-member label {
    font-weight: bold;
    display: block;
  }
  
  .team-member p {
    color: black;
    margin: 5px 0 0;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 6px;
  }
  .go-back-button{
    justify-content: center;
    display: flex;
    align-items: center;
  }
  @media (max-width: 768px) {
    .viewteam-rw-container {
      margin-top: 20vh;
      margin-bottom: 20vh;
      margin-left: 10vw;
      padding: 20px;
      background-color: rgba(225,225,225,0.2);
      box-shadow: 0px 24px 24px rgba(0,0,0,0.4);
      border: 1px solid rgba(225,225,225,0.1);
      border-radius: 5px;
      max-width: 80vw;
  
    }
    .addteam-rw-add-container {
      font-size: 1.2em;
      display: grid;
      align-items: center;
      justify-content: space-around;
      align-content: space-evenly;
      justify-items: center;
      margin-top: 2vh;
    }
  }
  