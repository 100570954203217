*{
    padding: 0;
    margin: 0;
}

.sponsor-wrapper{
    padding-top: 8vw;
    background-image: url('./bg.png');
}

.sponsor-head{
    text-align: center;
    font-family: 'That_New_Pixel';
    font-size: 5rem;
    color: #2D48D5;
    font-weight: bold;
    margin-bottom: 4vw;
}

.sponsors-24{
    text-align: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100vw;
}
.title-sponsor-img{
    width: 50vw;
}
.learnhill-img{
    height: 20vw;
    width: 20vw;
}
.svjn-img{
    height: 25vw;
    width: 16vw;
}
.oil-img{
    height: 35vh;
    width: 15vw;
}
.fundaaz-img{
    height: 8vw;
    width: 15vw;
}
.ednight-img{
    height: 8vw;
    width: 15vw;
}
.autodesk-img{
    height: 8vw;
    width: 30vw;
}
.fetch-img{
    height: 5vw;
    width: 25vw;
}
.stp-img{
    height: 18vw;
    width: 18vw;
}
.nrl-img{
    height: 8vw;
    width: 18vw;
}
.krafton-img{
    height: 6vw;
    width: 40vw;
}
.ktm-img{
    height: 15vw;
    width: 25vw;
}
.welin-img{
    height: 12vw;
    width: 55vw;
}
.tooyum-img{
    height: 24vw;
    width: 28vw;
}
.krafton-img{
    width: 18vw;
}
.ktm-img{
    width: 18vw;
    height: 8vw;
}
.welin-img{
    height: 8vw;
    width: 18vw;
}
.tooyum-img{
    height: 10vw;
    width: 10vw;
}
.kbomb-img{
    height: 18vw;
    width: 18vw;
}
.coffeebite-img{
    height: 8vw;
    width: 18vw;
}
.lotte-img{
    height: 8vw;
    width: 18vw;
}
.classmate-img{
    height: 8vw;
    width: 18vw;
}
.max-img{
    height: 8vw;
    width: 18vw;
}
.lt-img{
    height: 18vw;
    width: 18vw;
}
.quil-img{
    height: 8vw;
    width: 18vw;
}
.yakult-img{
    height: 8vw;
    width: 18vw;
}
.unstop-img{
    height: 8vw;
    width: 18vw;
}
.product-img{
    height: 18vw;
    width: 18vw;
}
.beardo-img{
    height: 8vw;
    width: 18vw;
}
.fms-img{
    /* height: 8vw;
    width: 18vw; */
}
.dyet-img{
    height: 18vw;
    width: 18vw;
}
.revertech-img{
    height: 8vw;
    width: 18vw;
}
.dcx-img{
    height: 16vw;
    width: 18vw;
}
.lipy-img{
    height: 18vw;
    width: 18vw;
}
.codingnin-img{
    height: 18vw;
    width: 18vw;
}
.gentleDentel-img{
    height: 8vw;
    width: 18vw;
}
.Cube-img{
    height: 20vw;
    width: 18vw;
}
.witBlox-img{
    height: 18vw;
    width: 18vw;
}
.kohua-img{
    height: 8vw;
    width: 18vw;
}
.gail-img{
    height: 12vw;
    width: 18vw;
}
.dopamine-img{
    height: 18vw;
    width: 18vw;
}
.labdox-img{
    height: 8vw;
    width: 18vw;
}
.stock-img{
    height: 18vw;
    width: 18vw;
}
.easeMytrip-img{
    height: 8vw;
    width: 18vw;
}
.collab-img{
    height: 18vw;
    width: 18vw;
}
.companies-24{
    align-self: center;
    margin-bottom: 5vw;
    width: 100vw;
}

.companies-anc{
    align-items: center;
}

.othercompanies-24{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-bottom: 10vw;
}

.companiesGroup-24{
    display: flex;
    width: 30vw;
    margin-bottom: 8vw;
    flex-direction: column;
    justify-content: flex-end;
    
}


.sponsorname-24{
    font-family: 'FK_Grotesk_SemiMono';
    margin-top: 2vw;
    color: #2D48D5;
}

.sponsorImg{
    margin-left: auto;
    margin-right: auto;
    vertical-align: middle;
}

.sponsorImg-wrapper{
    text-align: center;
}

.sponsorGroup-24{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    vertical-align: middle;
}

@media (max-width: 1200px){

    .Sponsoralone{
        height: 18vw !important;
    }

}

@media (max-width: 800px){

    .sponsor-wrapper{
        padding-top: 18vw;
    }
}

@media (max-width: 450px){

    .sponsor-wrapper{
        padding-top: 24vw;
    }
}

@media (max-width: 700px){

    .sponsorname-24{
        font-size: 1rem !important;
    }
}

@media (max-width: 700px){
    .sponsor-head {
        font-size: 3rem;
    }
    .companiesGroup-24{
        width: 100vw;
        margin-bottom: 12vw;
        padding-left: 5vw;
        padding-right: 5vw;
    }
    .companies-24{
        margin-bottom: 10vw;
    }
    .title-sponsor-img{
        margin-left: 0vw;
        margin-right: 0vw;
        width: 100vw;
    }
    .learnhill-img{
        height: 40vw;
        width: 40vw;
    }
    .svjn-img{
        height: 60vw;
        width: 40vw;
    }
    .fundaaz-img{
        height: 40vw;
        width: 30vw;
    }
    .oil-img{
        height: 50vw;
        width: 40vw;
    }
    .ednight-img{
        height: 30vw;
        width: 30vw;
    }
    .autodesk-img{
        height: 15vw;
        width: 50vw;
    }
    .fetch-img{
        height: 10vw;
        width: 40vw;
    }
    .stp-img{
        height: 30vw;
        width: 30vw;
    }
    .nrl-img{
        height: 10vw;
        width: 30vw;
    }
    .krafton-img{
        height: 10vw;
        width: 30vw;
    }
    .ktm-img{
        height: 10vw;
        width: 30vw;
    }
    .welin-img{
        height: 10vw;
        width: 30vw;
    }
    .tooyum-img{
        height: 30vw;
        width: 30vw;
    }
    .kbomb-img{
        height: 10vw;
        width: 30vw;
    }
    .coffeebite-img{
        height: 10vw;
        width: 30vw;
    }
    .lotte-img{
        height: 10vw;
        width: 30vw;
    }
    .classmate-img{
        height: 10vw;
        width: 30vw;
    }
    .max-img{
        height: 10vw;
        width: 30vw;
    }
    .lt-img{
        height: 30vw;
        width: 30vw;
    }
    .quil-img{
        height: 10vw;
        width: 30vw;
    }
    .yakult-img{
        height: 10vw;
        width: 30vw;
    }
    .unstop-img{
        height: 10vw;
        width: 30vw;
    }
    .product-img{
        height: 30vw;
        width: 30vw;
    }
    .beardo-img{
        height: 10vw;
        width: 30vw;
    }
    .fms-img{
        height: 30vw;
        width: 30vw;
    }
    .dyet-img{
        height: 30vw;
        width: 30vw;
    }
    .revertech-img{
        height: 10vw;
        width: 30vw;
    }
    .dcx-img{
        height: 20vw;
        width: 30vw;
    }
    .lipy-img{
        height: 30vw;
        width: 30vw;
    }
    .codingnin-img{
        height: 30vw;
        width: 30vw;
    }
    .gentleDentel-img{
        height: 10vw;
        width: 30vw;
    }
    .Cube-img{
        height: 25vw;
        width: 30vw;
    }
    .witBlox-img{
        height: 30vw;
        width: 30vw;
    }
    .kohua-img{
        height: 10vw;
        width: 30vw;
    }
    .gail-img{
        height: 25vw;
        width: 30vw;
    }
    .dopamine-img{
        height: 30vw;
        width: 30vw;
    }
    .labdox-img{
        height: 10vw;
        width: 30vw;
    }
    .stock-img{
        height: 30vw;
        width: 30vw;
    }
    .easeMytrip-img{
        height: 10vw;
        width: 30vw;
    }
    .collab-img{
        height: 30vw;
        width: 30vw;
    }

}