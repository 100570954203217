@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
*{
    color:white;
    scroll-behavior: smooth;
}
.go-left-arrow{
    display: flex;
    padding-left: 4vw;
    /* width: 42vw; */
    align-items: center;
    position: relative;
    z-index: 98;
    cursor: pointer;
}
.go-left-arrow img{
    height: 8vh;
    cursor: pointer;
}
.singularevent-content-wrapper{
    width: 40vw;
}
.singularevent-title{
    width: 100vw;
    display: flex;
    font-size: 4rem;
    font-family: 'That_New_Pixel';
    margin-bottom: 5vh;
    color: #2D48D5;
    font-style: italic;
    justify-content: space-between;
    z-index: 98;
}
.singularevent-outer-wrapper{
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20vh;
    background: url('../../../../assets/bg.png');
    z-index: 50;
}
.singularevent-navbar ul li{
    margin: 20px;
    width: 10vw;
    display: flex;
    align-items: center;
    justify-content: center;
}
.singularevent-navbar ul li a{
    color: #C1C3C6;
    font-family: 'FK_Grotesk_SemiMono';
    text-decoration: none;
    font-size: 1rem;
    cursor: pointer;
    font-weight: 100;
}
.robowar-navbar-extra{
    color: #2D48D5;
    font-style: italic;
}
.singularevent-navbar ul{
    width:calc(70vw + 8px) ;
}
.singularevent-navbar{
    position: sticky;
    /* top: 8vh; */
    z-index: 100;
}
#singularevent-overview{
    display: flex;
    justify-content: space-between;
    margin-top: 0vh;
}
.singularevent-content-inner{
    width: 70vw;
    padding-bottom: 2vw;
    background: url('../../../../assets/EventsBg.png');
}
.singularevent-content-heading{
    font-family: 'FK_Grotesk_SemiMono';
    display: flex;
    align-items: center;
    font-size: 1.7rem;
    font-weight: 400;
}
.singularevent-vector{
    height: 3vh;
    margin-right: 10px;
}
.singularevent-content-details{
    font-family: 'FK_Grotesk';
    font-weight: 100;
    font-size: 1rem;
    letter-spacing: 0.03rem;
    letter-spacing: 0.03rem;
    margin: 10px 0px 4vh 0px;
}
.singularevent-content-details2{
    font-family: 'FK_Grotesk';
    font-weight: 100;
    font-size: 1rem;
    letter-spacing: 0.03rem;
    letter-spacing: 0.03rem;
}
#singularevent-event , #singularevent-vit, #singularevent-pastpanel, #singularevent-paneldisc, #singularevent-registration , #singularevent-prizes , #singularevent-schedule , #singularevent-contact, #singularevent-overview{
    margin-top: 0vw;
    margin-left: 5vw;
    margin-bottom: 0vw;
    margin-right: 5vw;
}
.singularevent-prizes-wrapper{
    display: flex;
    justify-content: space-between;
    margin: 10px 0px;
}
.singularevent-content-details{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.singularevent-prize-card-outer{
    background-color:black;
    padding: 2px;
    width: 16vw;
}
.singularevent-prize-card-inner{
    background-color: rgb(235, 235, 235);
    /* width:100% ; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding:10px 0px 10px 40px
}
.singularevent-inner-wrapper{
    position: relative;
    z-index: 98;
    margin-bottom: 40vh;
}
.singularevent-register{
    height: 8vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.singularevent-register-closed{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.singularevent-btn-inner{
    cursor: pointer;
    color: #2D48D5;
    margin: 0px 5px 5px 0px;
    
}
.singularevent-btn-inner-closed{
    color: #2D48D5;
}
.singularevent-btn-inner:hover{
    margin: 0px 0px 0px 0px;
}
.singularevent-prizes-category{
    font-weight: 300;
    font-size: 1.1rem;
}
.singularevent-prize-category-outer{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 3vh 0px;
}
.singularevent-prize-category-inner-1{
    width: 33%;
    margin-bottom: 2vh;
}
.singularevent-prize-category-inner-2{
    width: 67%;
    justify-content: space-around;
}
@media screen and (max-width:800px) {
    .singularevent-prize-category-outer{
        flex-direction: column;
    }
    .singularevent-prize-category-inner-1 , .singularevent-prize-category-inner-2{
        width: 100%;
        display: flex;
        justify-content:center;
    }
    .singularevent-prize-category-inner-2{
        justify-content: space-evenly;
        margin: 20px 0px;
    }
    .singularevent-prize-card-inner,.singularevent-prize-card-outer{
        width: 35vw;
    }
}
@media screen and (max-width:900px) {
    .singularevent-title {
        width: 100vw;
        display: flex;
        font-size: 2rem;
        font-family: 'That_New_Pixel';
        margin-top: 4vh;
        margin-bottom: 4vh;
        color: #2D48D5;
        font-style: italic;
        justify-content: space-between;
    }
    .go-left-arrow {
        display: flex;
        padding-left: 4vw;
        width: 20vw;
        align-items: center;
    }
    .singularevent-content-wrapper{
        width: 80vw;
    }
    .go-left-arrow img {
        height: 6vh;
    }
    .singularevent-content-inner{
        width: 90vw;
    }
    .singularevent-navbar ul{
        height:0.1vh;
        width: calc(90vw + 8px);
    }
    .singularevent-prize-card-inner{
        padding: 10px 0px;
        align-items: center;
    }
    .singularevent-outer-wrapper{
        padding-top: 10vh;
    }
    .singularevent-inner-wrapper{
        margin-bottom: 10vh;
    }
}
.singularevent-img-wrapper{
    width: fit-content;
}
.singularevent-img-robo-wrapper{
    width: fit-content;
}
@media screen and (max-width:850px) {
    #singularevent-overview {
        display: flex;
        flex-direction: column-reverse;
    }
    .singularevent-img-wrapper{
        width: 100%;
        display: flex;
        justify-content: center;
    }
}
@media screen and (max-width:650px) {
    .singularevent-content-details{
        flex-direction: column;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .singularevent-img-robo-wrapper{
        margin-left: 15vw;
        margin-top: 0vh;
    }
    .singularevent-navbar a{
        display: none;
    }
     #singularevent-event , #singularevent-vit, #singularevent-pastpanel, #singularevent-paneldisc, #singularevent-registration , #singularevent-prizes , #singularevent-schedule , #singularevent-contact{
        display: flex;
        flex-direction: column;
    }
    .singularevent-content-heading{
        margin-top: 2vh;
        font-size:1.5rem ;
    }
    .singularevent-prize-card-outer{
        margin: 20px 0px;
        width: 35vw;
    }
    .singularevent-prize-card-inner{
        width: 35vw;
    }
    .singularevent-prizes-wrapper{
        flex-direction: column;
        align-items: center;
    }
}
@media screen and (max-width:450px) {
    .singularevent-prize-category-inner-2{
        margin: 0px;
    }
    .singularevent-prize-card-outer{
        margin: 10px 0px;
    }
}
@media screen and (max-width:350px) {
    .singularevent-title{
        font-size: 2.5rem;
    }
}