@font-face {
    font-family: 'MintGrotesk';
    src: url(../../../fonts/MintGroteskTrial-Regular-BF64336b1caef64.otf) format('opentype');
    src: url(../../../fonts/MintGroteskTrial-Regular-BF64336b1caef64.otf) format('opentype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'MintGrotesk';
    src: url(../../../fonts/MintGroteskTrial-Medium-BF64336b1cc96e2.otf) format('opentype');
    src: url(../../../fonts/MintGroteskTrial-Medium-BF64336b1cc96e2.otf) format('opentype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'MintGrotesk';
    src: url(../../../fonts/MintGroteskTrial-Bold-BF64336b1cb5af2.otf) format('opentype');
    src: url(../../../fonts/MintGroteskTrial-Bold-BF64336b1cb5af2.otf) format('opentype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'MintGrotesk';
    src: url(../../../fonts/MintGroteskTrial-ExtraBold-BF64336b1c9a73a.otf) format('opentype');
    src: url(../../../fonts/MintGroteskTrial-ExtraBold-BF64336b1c9a73a.otf) format('opentype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "NN Swinton";
    src: url("../../../fonts/NNSwintonTRIAL-BoldItalic.otf") format("opentype");
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: "NN Swinton";
    src: url("../../../fonts/NNSwintonTRIAL-Heavy.otf") format("opentype");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "NN Swinton";
    src: url("../../../fonts/NNSwintonTRIAL-HeavyItalic.otf") format("opentype");
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: "NN Swinton";
    src: url("../../../fonts/NNSwintonTRIAL-Black.otf") format("opentype");
    font-weight: 900;
    font-style: normal;
}

.ghm-register-container {
   
    margin: 0 auto;
    padding: 12rem;
    background-color: #FFF6E4;
}

.ghm-header {
    text-align: center;
    margin-bottom: 2rem;
}

.ghm-register-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Aligns content to the left */
    font-size: 3rem;
    color: rgba(14, 31, 72, 1);
    line-height: 1.2;
    margin: 0 auto;
    word-wrap: break-word;
    margin-left: 2vw;


}

.register-title-line-1 {
  color: rgba(14, 31, 72, 1);
    display: block;
    font-weight: normal;
    margin-bottom: 0.5rem;
    font-style: italic;
    
}

.register-title-line-2 {
  color: rgba(14, 31, 72, 1);
    display: block;
    font-weight: bold;
}

.info-accordion {
    margin-top: 1rem;
}

.accordion-toggle {
    width: 100%;
    padding: 0.75rem;
    background-color: #FFF6E4;
    
    text-align: left;
    color: #05003D;
    cursor: pointer;
    font-weight: 500;
}
.ghm-form{
    background-color:  #FFF6E4;
}
.accordion-content {
    padding: 1rem;
    background-color:  #FFF6E4;
    border: 1px solid #dee2e6;
    border-top: none;
    border-radius: 0 0 4px 4px;
    margin-top: -1px;
    color: #333;
    line-height: 1.5;
}

.accordion-content p {
  color: #333;
    margin-bottom: 1rem;
}

.back-button {
    margin-bottom: 2rem;
}

.back-link {
    color: #000;
    text-decoration: none;
    font-weight: 500;
    font-size: 1rem;
}

.logo {
    text-align: right;
    margin-bottom: 3rem;
}

.logo img {
    height: 40px;
}

.register-content {
    max-width: 800px;
    margin: 0 auto;
}

h1 {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
}

h2 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 3rem;
    color: #05003D;
}

h3 {
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 1rem;
    color: #333;
}

section {
    margin-bottom: 2rem;
}

.form-row {
    display: flex;
    gap: 1.5rem;
    margin-bottom: 1rem;
}

.form-row .half {
    flex: 1;
}

.form-row.three-col {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
}

.two-columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

.three-columns {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
}

input, select {
    width: 100%;
    padding: 0.75rem 1rem;
    border: none;
    border-bottom: 2px solid #000;
    outline: none;
    font-size: 1rem;
    color: #333;
    background-color: #FFF6E4;
    transition: border-color 0.2s, box-shadow 0.2s;
}

/* Add styles for placeholder text */
input::placeholder {
    color: #666666;
    opacity: 1;
}

/* Add styles for select options */
select option {
    color: #000000;
    background-color:  #FFF6E4;
    padding: 0.5rem;
}

/* Add focus styles for better visibility */
input:focus, select:focus {
    outline: none;
    border-color: #05003D;
    box-shadow: 0 0 0 3px rgba(5, 0, 61, 0.1);
}

select {
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1em;
}

.learn-more {
    display: block;
    color: #0066CC;
    text-decoration: none;
    margin-top: 0.5rem;
    font-size: 0.9rem;
}

.submit-button {
    width: 100%;
    padding: 1rem 2rem;
    background-color: #05003D;
    color: white;
    border: none;
    border-radius: 50px;
    font-size: 1.1rem;
    font-weight: 500;
    cursor: pointer;
    margin-top: 2rem;
    transition: background-color 0.2s;
}

.submit-button:hover {
    background-color: #0A0063;
}

.form-group {
    margin-bottom: 20px;
    position: relative;
}

.form-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
    color: #333;
    font-size: 0.9rem;
}

.form-section {
    background: #FFF6E4;
    padding: 2rem;
    
    margin-bottom: 2rem;
}

.section-title {
    font-size: 1.5rem;
    color: #05003D;
    margin-bottom: 1.5rem;
    font-weight: 600;
}

.register-btn {
    background-color: #05003D;
    color: white;
    padding: 1rem 2rem;
    border: none;
    border-radius: 50px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
    width: 100%;
    margin-top: 1rem;
}

.register-btn:hover {
    background-color: #0A0063;
}

.error-message {
    color: #ff0000;
    margin-top: 10px;
    text-align: center;
    font-weight: 500;
}

@media (max-width: 768px) {
    .ghm-register-container {
        padding: 1rem;
    }

    h1 {
        font-size: 1.5rem;
    }

    h2 {
        font-size: 2rem;
    }

    .ghm-title {
        font-size: 2rem;
    }

    .two-columns,
    .three-columns {
        grid-template-columns: 1fr;
    }

    .logo img {
        height: 32px;
    }

    .form-row.three-col {
        grid-template-columns: 1fr;
    }

    .form-row {
        flex-direction: column;
        gap: 1rem;
    }

    .form-section {
        padding: 1.5rem;
    }
}

@media (max-width: 480px) {
    .ghm-register-title {
        font-size: 1.7rem;
    }
    h2 {
        font-size: 1.75rem;
    }

    input, select {
        padding: 0.875rem;
    }

    .submit-button {
        padding: 0.875rem;
    }
}

/* Input field styles */
.ghm-form input,
.ghm-form select {
    width: 100%;
    padding: 10px;
    
    
    color: #000;
    font-size: 16px;
}

/* Button styles */
.register-btn,
.pay-btn {
    display: inline-block;
    padding: 12px 24px;
    background-color: #0e1f48 !important;
    color: #fff !important;
    /* color: #fff; */
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.register-btn:hover,
.pay-btn:hover {
    background-color: #1a3366;
}

/* Form group spacing */
.form-group {
    margin-bottom: 20px;
}

/* Make sure form elements are visible with proper contrast */
.ghm-form label {
    display: block;
    margin-bottom: 8px;
    color: #0e1f48;
    font-weight: 500;
}

/* Error message styling */
.error-message {
    color: #ff0000;
    margin-top: 10px;
    font-weight: 500;
}

/* Form actions container */
.form-actions {
    margin-top: 20px;
    text-align: center;
}