/* HeroSection.css

/* ---------------------
   Hero Section Container
--------------------- */
.hero-section {
  position: relative; /* To position overlay, images, and moving divs absolutely */
  width: 100%;
  height: 940px; /* Hero section height for laptop/desktop */
  background: url("../GhmAssets/Frame 26081033.png") no-repeat center center/cover;
  overflow: hidden;
  padding: 0%;
  margin-bottom: 0rem;
}

/* ---------------------
   Decorative Images
--------------------- */
.image {
  position: absolute;
  object-fit: contain;
}

.image1 {
  width: 145px;
  height: 145px;
  top: 0;
  right: -15px;
}

.image2 {
  width: 192px;
  height: 192px;
  top: 502px;
  left: -30px;
}

.image3 {
  width: 275px;
  height: 275px;
  top: 707px;
  right: -45px;
}

/* ---------------------
   Moving Divs (Laptop/Desktop)
--------------------- */
.moving-div {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  transform: translate(0, 0);
}

/* --- Div 1: "GUWAHATI" --- */
.div1 {
  width: max-content;
  height: 120px;
  top: 209px;
  left: 122px;
  z-index: 2;
  gap: 12px;
}

.div1-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  z-index: 1px;
}

.div1-image-container {
  width: 80px;
  height: 80px;
}

.div1-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.div1-text-container {
  width: max-content;
  height: 120px;
  font-family: "Cabinet Grotesk Variable", sans-serif;
  font-weight: 900;
  font-size: 130px;
  line-height: 100%;
  letter-spacing: 0;
  text-transform: uppercase;
  color: #FFFCF5;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* --- Div 2: "Half" --- */
.div2 {
  width: max-content;
  height: 230px;
  top: 384px;
  left: 1043px;
  z-index: 2;
}

.div2-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.div2-image-container {
  width: 206px;
  height: 86px;
}

.div2-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.div2-text-container {
  width: max-content;
  height: 120px;
  font-family: "Cabinet Grotesk Variable", sans-serif;
  font-weight: 900;
  font-size: 120px;
  line-height: 100%;
  letter-spacing: 0;
  text-transform: uppercase;
  color: #FFF6E4;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

/* --- Div 3: "MARATHON" (with "O" as image) --- */
.div3 {
  /* Laptop specs from your instructions */
  width: max-content;
  height: 149px;
  top: 634px;
  left: 340px;
  gap: 4px;
  z-index: 2;
}

.div3-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-family: "Cabinet Grotesk Variable", sans-serif;
  font-weight: 900;
  font-size: 120px;
  line-height: 100%;
  letter-spacing: 0;
  text-transform: uppercase;
  color: #FFFCF5;
  gap: 4px; /* Spacing between parts */
}

.div3-part1,
.div3-part2 {
  display: flex;
  align-items: center;
  height: 100%;
}

.div3-o-container {
  width: auto; /* Let the image define its width */
  height: 100%;
  display: flex;
  align-items: center;
}

.div3-o-image {
  height: 100%;
  object-fit: contain;
}

/* --- Div 4: Unchanged --- */
.div4 {
  width: max-content;
  height: 58px;
  top: 448px;
  left: 252px;
  z-index: 2;
  gap: 10px;
}

.div4-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-end; /* Right-aligned text on laptop */
  justify-content: center;
  width: 100%; 
  height: 100%;
}

/* 1. Mark Your Calendars (Laptop) */
.div4-text1 {
  width: 290px;
  height: 27px;
  padding-right: 2vw;
  font-family: "Cabinet Grotesk Variable", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 100%;
  letter-spacing: 2%; /* In CSS, letter-spacing typically uses px/em, but we'll follow your spec */
  color: #FFF6E4;
  text-align: right; /* Right-aligned text */
}

/* 2. 6th APRIL (Laptop) */
.div4-text2 {
  width: max-content;
  height: 24px;
  font-family: "Cabinet Grotesk Variable", sans-serif;
  font-weight: 900;
  padding-right: 2vw;
  font-size: 26px;
  line-height: 100%;
  letter-spacing: 10%; /* Same note on letter-spacing units */
  text-transform: uppercase;
  color: #FFF6E4;
  text-align: right;
}

/* --- Div 5: Slower movement with background JPEG --- */
.div5 {
  width: 590px;
  height: 390px;
  position: absolute; /* Ensures correct positioning */
  top: 293px;
  left: 529px;
  z-index: 1;
  overflow: hidden; /* Crops any excess part */
}

.div5 video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures it fills the div while cropping */
}



/* --------------------------
   Mobile Styles (max-width: 768px)
--------------------------- */
@media (max-width: 768px) {
  .hero-section {
    height: 585px;
  }

  .image1 {
    width: 115px;
    height: 115px;
    top: 0;
    right: -10px;
  }

  .image2 {
    width: 105px;
    height: 105px;
    top: 335px;
    left: -30px;
    right: auto;
  }

  .image3 {
    width: 132px;
    height: 132px;
    top: 476px;
    right: -25px;
  }

  /* Div 1 (Mobile) */
  .div1 {
    width: 143px;
    height: 40px;
    top: 105px;
    left: 50px;
    gap: 7px;
  }

  .div1-inner {
    gap: 7px;
  }

  .div1-image-container {
    width: 30px;
    height: 30px;
  }

  .div1-text-container {
    width: max-content;
    height: 40px;
    font-size: 40px;
    color: #FFF6E4;
  }

  /* Div 2 (Mobile) */
  .div2 {
    width: max-content;
    height: 65px;
    top: 179px;
    left: 278px;
    z-index: 2;
  }

  .div2-inner {
    gap: 5px;
  }

  .div2-image-container {
    width: 62px;
    height: 25.8835px; /* Rounded */
  }

  .div2-text-container {
    width: max-content;
    height: 40px;
    font-size: 35px;
    color: #FFF6E4;
  }

  /* Div 3 (Mobile) */
  .div3 {
    width: max-content;
    height: 40px;
    top: 295px;
    left: 90px;
    gap: 4px;
  }

  .div3-inner {
    font-size: 35px;
    color: #FFF6E4;
    gap: 4px;
  }

  .div3-part1,
  .div3-part2 {
    height: 40px;
    align-items: center;
  }

  .div3-o-container {
    height: 40px;
  }

  .div3-o-image {
    height: 80%;
  }

  /* Div 4 (Mobile) */
  .div4 {
    width: 150px;
    height: 36px;
    top: 426px;
    left: 130px;
    gap: 4px;
  }

  .div4-inner {
    align-items: center; /* Center-aligned text on mobile */
  }

  .div4-text1 {
    
    font-size: 16px;
    letter-spacing: 2%;
    text-align: center; /* Center text on mobile */
    color: #FFF6E4;
  }

  .div4-text2 {
    width: 150px;
    height: 16px;
    font-size: 16px;
    letter-spacing: 10%;
    text-align: center;
    text-transform: uppercase;
    color: #FFF6E4;
    font-weight: 900;
  }

  /* Div 5 (Mobile) */
  /* .div5 {
    width: 243px;
    height: 161px;
    top: 162px;
    left: 64px;
    background: url("../GhmAssets/ghm_vdo.mp4") no-repeat center center/cover;
  } */

  .div5 {
    width: 263px;
    height: 181px;
    top: 132px;
    left: 54px;
    z-index: 1;
    object-fit: cover;
  }

} 
