.dashboard-event-card {
  text-align: center;
    margin-top: 8%;
    margin-left: 5vw;
    margin-right: 5vw;
    padding: 3%;
    padding-bottom: 2%;
    background-color: rgba(241, 241, 241, 0.245);
    box-shadow: 0px 24px 24px rgba(0,0,0,0.4);
    border: 1px solid rgba(225,225,225,0.1);
    border-radius: 10px;
    width: 25vw;
}
.dashboard-event-card-1{
  text-align: center;
    margin-top: 8%;
    margin-left: 5vw;
    margin-right: 5vw;
    padding: 3%;
    padding-bottom: 2%;
    background-color: rgba(241, 241, 241, 0.245);
    box-shadow: 0px 24px 24px rgba(0,0,0,0.4);
    border: 1px solid rgba(225,225,225,0.1);
    border-radius: 10px;
    width: 25vw;
}

.dashboard-event-card-image {
  width: 100%;
  height: auto;
  border-radius: 4px;
  margin-bottom: 8px;
}

.dashboard-event-card-title {
  font-family: 'That_New_Pixel';
  font-size: 1.5em;
  margin-bottom: 8px;
  color: #F3FF63;
}

.dashboard-event-card-status {
  font-size: 1em;
  color: #333;
}
.viewteam-button{
  margin-top: 4vh;
  margin-bottom: 2vh;
}
.registered-button-1{
  margin-top: 6vh;
  margin-bottom: 2vh;
}
.register-button-1{
  margin-top: 6vh;
  margin-bottom: 2vh;
}
.register-button{
  margin-top: 4vh;
  margin-bottom: 2vh;
}
.registered-button{
  margin-top: 4vh;
  margin-bottom: 2vh;
}
@media (max-width: 768px) {
  .dashboard-event-card {
    text-align: center;
      margin-top: 14%;
      margin-left: 10vw;
      margin-right: 5vw;
      padding: 3%;
      padding-bottom: 2%;
      background-color: rgba(241, 241, 241, 0.245);
      box-shadow: 0px 24px 24px rgba(0,0,0,0.4);
      border: 1px solid rgba(225,225,225,0.1);
      border-radius: 10px;
      width: 80vw;
  }
  .dashboard-event-card-1{
    text-align: center;
      margin-top: 14%;
      margin-left: 10vw;
      margin-right: 5vw;
      padding: 3%;
      padding-bottom: 2%;
      background-color: rgba(241, 241, 241, 0.245);
      box-shadow: 0px 24px 24px rgba(0,0,0,0.4);
      border: 1px solid rgba(225,225,225,0.1);
      border-radius: 10px;
      width: 80vw;
  }
  
}