.singularevent2-content-wrapper{
    margin: 4vh 3vw 0vh 0vw;

}
.singularevent2-img-wrapper {
    margin-left: 10vw;
    width: 50vw!important;
    display: flex;
    justify-content: center;
    margin-bottom: 3vh;
}
.schedule-day-date li{
    font-family: 'FK_Grotesk';
    font-weight: 100;
    font-size: 1rem;
    letter-spacing: 0.03rem;
}
@media screen and (max-width:900px) {
    .singularevent2-img-wrapper{
        margin-left: 0vw;
        width: 90vw !important;
        padding-left: 1vw;
        padding-right: 1vw;

    }
    .singularevent2-content-wrapper{
        margin: 4vh 3vw 3vh 3vw;
    
    }
}