    .submission-container{
      margin-top: 20vh;
      margin-bottom: 10vh;
      margin-left: 20vw;
      padding: 20px;
      background-color: rgba(225,225,225,0.2);
      box-shadow: 0px 24px 24px rgba(0,0,0,0.4);
      border: 1px solid rgba(225,225,225,0.1);
      border-radius: 5px;
      max-width: 60vw;
      text-align: center;
    }
    .submission-container-2{
        margin-top: 20vh;
        margin-bottom: 10vh;
        margin-left: 20vw;
        padding: 20px;
        background-color: rgba(225,225,225,0.2);
        box-shadow: 0px 24px 24px rgba(0,0,0,0.4);
        border: 1px solid rgba(225,225,225,0.1);
        border-radius: 5px;
        max-width: 60vw;
        text-align: center;
      }
    .submission-heading p{
        font-size: 3vh;
    }
    .submission-heading-red{
        margin-top: 3vh;
    }
    .submission-heading-red p{
        color: red;
    }
  @media (max-width: 768px) {
    .submission-container{
        margin-top: 20vh;
        margin-bottom: 10vh;
        margin-left: 10vw;
        padding: 20px;
        background-color: rgba(225,225,225,0.2);
        box-shadow: 0px 24px 24px rgba(0,0,0,0.4);
        border: 1px solid rgba(225,225,225,0.1);
        border-radius: 5px;
        max-width: 80vw;
        text-align: center;
      }
      .submission-container-2{
        margin-top: 5vh;
        margin-bottom: 10vh;
        margin-left: 10vw;
        padding: 20px;
        background-color: rgba(225,225,225,0.2);
        box-shadow: 0px 24px 24px rgba(0,0,0,0.4);
        border: 1px solid rgba(225,225,225,0.1);
        border-radius: 5px;
        max-width: 80vw;
        text-align: center;
      }
  }
  