@font-face {
  font-family: 'PP_Mori';
  src: url('../../../fonts/PPMori-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Zaio';
  src: url('../../../fonts/Armageda Wide.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

.login-new {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 110vh;
  background-image: url('../images/login-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.login-new-container {
    margin-top: 0vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 64px;
    position: absolute;
    width: 50vw;
    max-width: 90%;
    height: auto;
    background: #FFFFFF;
    border: 1px solid #000000;
}

.login-new-container p {
  text-align: center;
  font-weight: 400;
  font-size: 70px;
  margin: 0px 10px;
  padding: 5px 10px;
  font-family: 'Zaio';
  color: #000000;
}

.login-new-container>h4 {
  text-align: center;
  font-weight: 400;
  font-size: 30px;
  margin: 0px 10px;
  padding: 5px 10px;
  font-family: 'Zaio';
  color: #000000;
}
.error-message{
  font-size: 18px !important;
  color: red !important;
}


@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

nav ul li:hover {
  color: #f76300;
}

.navbutton {
  position: absolute;
  right: 5%;
  background-color: transparent !important;
  border: 1px solid black;
  padding: 0.5rem 1rem;
  border-radius: 12px;
  cursor: pointer;
}


.mobile {
  display: none;
}

@media (max-width: 500px) {
  .mobile {
    display: block;
  }

  .laptop {
    display: none;
  }

  nav button {
    display: none;
  }

  nav ul {
    display: none;
  }
}

.login-button-white {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 8px;
  width: 335px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #000000;
  box-shadow: -3px 2px 0px #000000;
  border-radius: 12px;
}

.login-button-white>p {
  font-weight: 400;
  font-size: 25px;
  margin: 0px 10px;
  cursor: pointer;
  padding: 5px 10px;
  font-family: 'Zaio';
  color: #000000;
}

.signup-button-orange {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px;
  width: 335px;
  height: 60px;
  background: #F96908;
  border: 1px solid #000000;
  box-shadow: -3px 2px 0px #000000;
  border-radius: 12px;
}

.signup-button-orange>p {
  font-weight: 400;
  font-size: 25px;
  margin: 0px 10px;
  cursor: pointer;
  padding: 5px 10px;
  font-family: 'Zaio';
  font-style: normal;
  line-height: 24px;
  color: #FFFFFF;
  text-shadow:
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000;
}

.form-option {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.form-option>label {
  color: #000;
  font-weight: 500;
  margin-bottom: 1px;
  width: 100%;
}

.form-option>input {
  border-radius: 3px;
  outline: none;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  background: #F3F3F3;
  border: 1px solid #959595;
  margin-bottom: 5px;
  color: #000;
}


@media (max-width: 768px) {
  .login-new-container {
    width: 80%;
    padding: 24px 32px;
  }

  .login-button-white,
  .signup-button-orange,
  .form-option>input {
    width: 100%;
  }

  .login-button-white>p,
  .signup-button-orange>p {
    font-size: 20px;
  }
}

@media (max-width: 500px) {
  .login-new-container {
    width: 90%;
    padding: 16px 24px;
  }

  .login-button-white,
  .signup-button-orange,
  .form-option>input {
    width: 100%;
  }

  .login-button-white>p,
  .signup-button-orange>p {
    font-size: 18px;
  }
}
