@font-face {
  font-family: 'PP_Mori';
  src: url('../../fonts/PPMori-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}


.parent {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: black;
  }

  .signup-form {
    text-align: center;
    padding: 20px;
    
    background-color: rgba(0, 0, 0, 0.16);
    backdrop-filter: blur(20px); /* Blur effect */
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 25px;
    width: 80vh;
    height: auto;
    color:white;
    position: relative;
  }

  .signup-form h1{
    
    margin-top: 7vh;
  }

  .input-feild-wrapper{
    background-color: #F7F8FF;
    margin-bottom: 20px;
    width: 100%; /* Set a specific width */
    display: flex;
    flex-direction: column;

  }
  .button-container button{
    width: 30vw;
  }
  .input-feild-indicator{
    font-family: 'FK_Grotesk';
    color:#2D48D5;
    padding-left: 10px;
    padding-top: 10px;
  }

  .signup-form input[type="text"],
  .signup-form input[type="password"],
  .signup-form input[type="number"] {
    width: 60%;
    font-family: "PP_Mori";
    padding: 5px;
    border: none;
    border-bottom: 1px solid white;
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0);
    color:white;
  }

  .delay-300 {
    transition-delay: 300ms;
  }
  
  .signup-form a {
    font-family: "PP_Mori";
    text-decoration: none;
    color: white;
  }

   .form-detailss p{
    color: #F7F8FF;
    font-size: 2rem;

   }

  .signup{
    width: 60%;
    padding: 10px;
    color: white;
    border: none;
    border-radius: 25px;
    margin: 2vh 0 2vh 0;
    cursor: pointer;
    font-family: "PP_Mori";
  }
  
  .login{
    width: 60%;
    padding: 10px;
    background: grey;
    color: white;
    border: none;
    border: 1px solid white;
    margin: 0 0 2vh 0;
    border-radius: 25px;
    margin-bottom: 2vh;
    cursor: pointer;
    font-family: "PP_Mori";
  }
  @media (max-width: 768px) {
    .button-container button{
      width: 70vw;
    }
  }