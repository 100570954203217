/* Navbar */
@font-face {
  font-family: "MintGrotesk";
  src: url("../../fonts/MintGroteskTrial-Regular-BF64336b1caef64.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "MintGrotesk";
  src: url("../../fonts/MintGroteskTrial-Medium-BF64336b1cc96e2.otf") format("opentype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "MintGrotesk";
  src: url("../../fonts/MintGroteskTrial-Bold-BF64336b1cb5af2.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "MintGrotesk";
  src: url("../../fonts/MintGroteskTrial-Bold-BF64336b1cb5af2.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "MintGrotesk";
  src: url("../../fonts/MintGroteskTrial-Black-BF64336b1cc868f.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}

/* @font-face {
  font-family: 'MintGrotesk';
  src: url("../../fonts/MintGroteskTrial-ExtraBold-BF64336b1c9a73a.otf") format('opentype');
  font-weight: 900;
  font-style: normal;
} */
@font-face {
  font-family: "NN Swinton";
  src: url("../../fonts/NNSwintonTRIAL-BoldItalic.otf") format("opentype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "NN Swinton";
  src: url("../../fonts/NNSwintonTRIAL-Heavy.otf") format("opentype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "NN Swinton";
  src: url("../../fonts/NNSwintonTRIAL-HeavyItalic.otf") format("opentype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "NN Swinton";
  src: url("../../fonts/NNSwintonTRIAL-Black.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}

/* @font-face {
  font-family: 'MintGrotesk';
  src: url("../../fonts/MintGroteskTrial-Heavy-BF64336b1c8f087.otf") format('opentype');
  font-weight: 1200;
  font-style: normal;
} */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "MintGrotesk";
  color: white;
  /* overflow: hidden; */
}

body {
  overflow-x: hidden;
}

.ghm-container {
  background-color: #ffffff;
  overflow-x: hidden;
}

.main-main {
  margin: 0;
  overflow: hidden;
}

.main-ghm {
  width: 100vw;
  overflow: hidden;
}

/* NAVBAR */
#nav {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16));
  backdrop-filter: blur(10px) !important;
  width: 100vw;
  display: flex;
  padding: 2vh 4vw;
  position: fixed;
  top: 0;
  z-index: 200;
  justify-content: space-between;
  align-items: center;
  font-size: 1vmax;
  font-weight: 500;
}

.nav-links-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40%;
}

.register-btn {
  color: black;
  background-color: #d9f971;
  display: flex;
  justify-content: center;
  padding: 2vh 2vw;
  font-size: 1vmax;
  align-items: center;
  border-radius: 57px;
  border: none;
  cursor: pointer;
}

.nav-active {
  color: #c4e84b !important;
}

.navghm {
  width: 60% !important;

}

.hamburger {
  height: 100%;
  display: none;
  align-items: center;
  justify-content: end;
}

.hamburger img {
  height: 100%;
  scale: 3;
}

.hamburger-ghm-menu {
  background-color: #000224;
  padding: 5%;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100000;
}

.top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ham-links {
  margin-top: 5vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  gap: 2vh;
}

.ham-links p {
  font-family: "MintGrotesk";
  padding: 2% 0;
}

.ham-vector {
  margin-top: 70vh;
}

.ham-button {
  margin-top: 5vh;
  background-color: #d9f971;
  width: 100%;
  color: #000224;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 52px;
  padding: 2% 3%;
}

.ham-menu {
  display: none;
}

@media screen and (max-width: 750px) {
  .nav-links-container {
    display: none;
  }

  .hamburger {
    display: flex;
  }

  #nav img {
    width: 20%;
  }

  .nav-links-container {
    width: 50%;
    font-size: 1.5vmax;
  }

  .register-btn {
    font-size: 1.5vmax;
    padding: 2vw 2vh;
  }
}

@media (max-width: 515px) {
  #nav img {
    width: 30%;
  }

  .ham-vector {
    margin-top: 40vh !important;
  }

  .nav-links-container {
    width: 65%;
    font-size: 1.6vmax;
  }

  .register-btn {
    font-size: 1.6vmax;
    padding: 2vw 2vh;
  }

  .ghm-content {
    scale: 1 !important;
  }

  .pp1,
  .pp2,
  .pp3 {
    font-size: 1.7vmax;
    padding: 1vw 1.2vh !important;
  }

  .animated-button {
    width: 14vmax;
    height: 7vmin;
    scale: 1.3;
  }
}

/* HERO */
main {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  z-index: 103;
}

.hero {
  height: 100%;
  position: absolute;
}

.ghm-content {
  position: relative;
  /* gap: 2vh; */
  top: 15vh;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 4;
}

.ghm-content h3 {
  font-family: "MintGrotesk";
  font-weight: 700;
  font-size: 3vh;
  margin-top: 3vh;
  margin-bottom: 7vh;
}

.ghm-logo {
  width: 30%;
}

.ghm-logo img {
  transform: scale(1.4);
  width: 100%;
}

.ghm-title {
  height: 30vh;
}

.ghm-title img {
  height: 110%;
}

.animated-button {
  z-index: 2;
  background-color: #d9f971;
  position: relative;
  width: 14vw;
  height: 7%;
  overflow: hidden;
  display: flex;
  border-radius: 60px;
  align-items: center;
  flex-direction: column;
}

.btn-overflow {
  width: 100%;
  /* height: 300%; */
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1% 0;
}

.pp1,
.pp2,
.pp3 {
  font-family: "MintGrotesk";
  font-weight: 700;
  color: black;
  z-index: 2;
  /* height: 100%; */
  width: 14vw;
  display: flex;
  justify-content: center;
  padding: 2% 0;
  border-radius: 60px;
  align-items: center;
  cursor: pointer;
  font-size: larger;
}

.btn-overflow:hover {
  animation: ready 1s ease-in;
  animation-fill-mode: forwards;
}

.play {
  margin-top: 2vh;
  font-size: 2.3vh;
  text-decoration: underline;
  cursor: pointer;
  color: rgb(205, 207, 210);
}

.play:hover {
  color: #ffffff;
}

.video {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  align-items: center;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.vid {
  /* width: 100%; */
  max-width: none;
  height: 100%;
  z-index: 2;
}

.black {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 3;
}

/* video controls */
.controls-wrapper {
  width: 100%;
  height: 20vh;
  position: absolute;
  bottom: 0vh;
  /* margin-top: 20vh; */
  z-index: 1000;
  opacity: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2vh;
  justify-content: center;
  align-items: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16));
}

.motion-controls {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1000;
}

.progress {
  width: 80%;
  display: flex;
  justify-content: center;
  z-index: 1000;
}

.progress input {
  width: 100%;
  height: 10px;
  display: flex;
  justify-content: center;
}

input[type="range"] {
  accent-color: red;
}

.play-pause {
  display: flex;
  align-items: center;
  gap: 1vw;
  font-size: 2vmin;
  position: absolute;
  left: 0;
}

.play-pause button {
  background: transparent;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  border: none;
  float: left;
}

.controls {
  width: 80%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.controls button {
  font-size: 2.5vh;
  /* margin-top: 5vh; */
  cursor: pointer;
  background: transparent;
  border: none;
  text-decoration: underline;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 750px) {
  .ghm-content {
    scale: 0.8;
  }
}

@media (max-width: 515px) {

  .pp1,
  .pp2,
  .pp3 {
    font-size: 2vh;
    /* padding: 0.5vw 1.2vh; */
  }

  .btn-overflow {
    padding: 0;
  }

  .controls-wrapper {
    padding: 0%;
  }

  .animated-button {
    width: 35vmax;
    height: 8vmin;
  }

  .ghm-title img {
    height: 80%;
  }

  .ghm-content h3 {
    font-family: "MintGrotesk";
    font-weight: 700;
    font-size: 2.5vh;
    margin-top: 0vh;
    margin-bottom: 7vh;
  }

  .play {
    font-family: "MintGrotesk";
    font-weight: 500;
    margin-top: 3vh;
  }

  .exit-video {
    /* margin-top: 2vh; */
    font-size: 4vmin !important;
  }

  .play-pause p {
    font-size: 4vmin;
  }
}

/*  TAGLINE  */
/* tagline desktop */
.tag-section {
  margin: 0;
  padding: 0;
  line-height: 0.9;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  /* z-index: 106; */
}

.tag-normal {
  font-family: "NN Swinton";
  font-weight: 800;
  font-style: normal;
}

.tag-italic {
  font-family: "NN Swinton";
  font-weight: 800;
  font-style: italic;
}

.tagline-description {
  margin-top: 4vh;
  text-align: center;
}

.tagline-description h4 {
  width: 30vw;
  font-size: 2vh;
  line-height: 3vh;
  font-family: "MintGrotesk";
  color: #020328 !important;
  z-index: 100;
}

.tag-section {
  background: url("./GhmAssets/bg.png") no-repeat center center/cover;
  color: #0c0e3d;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: relative; */
  overflow: hidden;
  /* z-index: 98; */
  padding-top: 5%;
}

.tagline-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 12vh;
}

.tagline-container h1 {
  color: #0c0e3d !important;
  font-size: 15vh;
  font-weight: 800;
  letter-spacing: -0.03em;
}

.tag-normal {
  font-style: normal;
}

.italic {
  font-style: italic;
}

.runners {
  position: absolute;
  width: 30%;
  /* height: 100%; */
  z-index: 109;
}

.runners img {
  transform: scale(1.1);
  position: relative;
  width: 100%;
  z-index: 109;
}

.run-left {
  position: absolute;
  left: 6%;
  top: 6%;
  z-index: 109;
}

.run-right {
  position: absolute;
  right: 5%;
  top: 4%;
  z-index: 109;
}

.verticals {
  height: 100vh;
  width: 100vw;
}

.ribbon {
  position: absolute;
  pointer-events: none;
}

.r-1 img,
.r-2 img,
.r-3 img,
.r-4 img,
.r-5 img {
  width: 100%;
  z-index: 98;
}

.r-1 {
  position: absolute;
  width: 40%;
  z-index: 108;
  top: 2%;
  left: 0;
}

.r-2 {
  position: absolute;
  /* height: 30%; */
  width: 25%;
  z-index: 108;
  top: 10%;
  right: 0%;
}

.r-3 {
  position: absolute;
  width: 100%;
  z-index: 108;
  top: 60%;
  left: 0;
}

.r-4 {
  width: 100%;
  z-index: 100;
  top: 225%;
  right: 0;
}

.r-5 {
  width: 30%;
  z-index: 107;
  top: 110%;
  left: 0;
}

.r-6 {
  width: 140%;
  top: 680%;
  right: -41%;
  z-index: 120;
}

.r-7 {
  width: 25%;
  z-index: 107;
  top: 600%;
  left: 0;
}

.r-8 {
  width: 40%;
  z-index: 120;
  top: 360%;
  right: 0;
}

.r-9 {
  width: 50%;
  z-index: 107;
  top: 360%;
  left: 0;
  z-index: 120;
}

@media (max-width: 1024px) and (min-width: 480px) {

  /* tagline ipad */
  .tag-section {
    padding-top: 30%;
  }

  .runners img {
    transform: scale(3);
    width: 100%;
    z-index: 105;
    overflow: hidden;
  }

  .run-left {
    left: -15%;
    top: 25%;
    z-index: 105;
  }

  .run-right {
    position: absolute;
    right: -17%;
    top: 25%;
    z-index: 105;
  }

  .tagline-container h1 {
    color: #0c0e3d !important;
    font-size: 10vh;
  }

  .tagline-description {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .tagline-description h4 {
    width: 60vw;
  }
}

@media (max-width: 515px) {

  /* tagline phone */
  .tag-section {
    padding-top: 40%;
    height: 80vh;
  }

  .runners img {
    transform: scale(3);
    width: 100%;
    z-index: 105;
    overflow: hidden;
  }

  .run-left {
    left: -15%;
    top: 25%;
    z-index: 108;
  }

  .run-right {
    position: absolute;
    right: -17%;
    top: 25%;
    z-index: 108;
  }

  .tagline-container {
    line-height: 6vh;
    z-index: 114;
  }

  .tagline-container h1 {
    color: #0c0e3d !important;
    font-size: 15vw;
  }

  .tagline-description {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .tagline-description h4 {
    width: 70vw;
  }

  .r-1 {
    top: 5%;
    width: 50%;
  }

  .r-2 {
    top: 157%;
    width: 30%;
  }

  .r-3 {
    /* scale: 1.4; */
    top: 90%;
  }

  .r-4 {
    width: 110%;
    top: 234%;
  }

  .r-5 {
    width: 40%;
    top: 110%;
  }

  .r-6 {
    top: 660%;
  }

  .r-7 {
    top: 634%;
  }

  .r-8 {
    top: 570%;
  }

  .r-9 {
    top: 509%;
  }
}




/* VERTICALS */
.verticals {
  background: url("./GhmAssets/bg.png") no-repeat center center/cover;
  height: 100vh;
}

.horizontal-container {
  padding-top: 3vh;
  display: inline-block;
  margin-right: 10px;
  width: 40vw;
}

.taglinevertical {
  display: flex;
  align-items: center;
  justify-content: center;
}

.taglinevertical p {
  font-size: 5vh;
  padding-top: 10vh;
  color: #000224 !important;
  font-family: 'NN Swinton';
  font-weight: 800;
  font-style: normal;
}

.horizontal-section ::-webkit-scrollbar {
  display: none;
  scrollbar-width: none;
}

.horizontal-container img {
  max-width: 35vw;

  position: relative;
}

#imgg1 {
  margin-top: 7vh;
}

#imgg2 {
  margin-top: 1vh;
}

#imgg3 {
  margin-top: 2vh;
  aspect-ratio: 3/3;
  filter: drop-shadow(0 0 0 0)
}

#imgg4 {
  max-height: 72vh;
  aspect-ratio: 2.7/3;
  /*margin-top: 7vh;*/
}

#imgg5 {
  margin-top: 3vh;
  max-height: 75vh;
  aspect-ratio: 3.5/3.5;
}

#imgg6 {
  margin-top: 7vh;
  height: 70vh;
  aspect-ratio: 3.8/3;
}

#imgg1-text p {
  position: absolute;
  padding-bottom: 10vh;
}

.img-text {
  position: absolute;
  align-items: center;
}

.img-text p {
  color: #0c0e3d;


}


.horizontal-section {
  width: 100%;
  margin-left: 1vw;
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  gap: 5vw;
  scrollbar-width: none;
  padding-top: 4vh;
  position: absolute;
  z-index: 114;

}

@media screen and (max-width:768px) {
  .verticals {
    background: url("./GhmAssets/bg.png") no-repeat center center/cover;
    height: 80vh;
  }

  .horizontal-container img {
    height: 42vh;
    aspect-ratio: 3.9/3.5;
    max-width: 100vw;
  }

  .taglinevertical p {
    font-size: 5vw;
  }

  .horizontal-container {
    width: 100vw;
    display: flex;
    flex-direction: column;
  }

  .horizontal-container img {
    aspect-ratio: 3.4/2.5;
  }

  #imgg5 {
    aspect-ratio: 4.5/4;
  }

  #imgg4 {
    aspect-ratio: 3.5/3.9;
  }

  #imgg1 {
    margin-top: 4vh;
  }

  #imgg6 {
    margin-top: 5vh;
    height: 50vh;
  }

}


/* GALLERY */
.gallerymain {
  background: url("./GhmAssets/bg.png") no-repeat center center/cover;
  width: 100%;
  height: 100vh;
  /* position: relative; */
  overflow: hidden;
  /* z-index: 98; */
}

.gallerytext {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: left;
  align-items: flex-start;
  margin-left: 8vh;
  padding-top: 10vh;
}

.gallerytext p {
  font-family: "NN Swinton";
  position: relative;
  gap: 10%;
  font-weight: 800;
  line-height: 8vh;
  font-size: 10vh;
  color: #0c0e3d !important;
  margin-bottom: 0;
  letter-spacing: -0.03em;
  z-index: 116;
}

.gallerytext i {
  font-family: "NN Swinton";
  position: relative;
  font-weight: 800;
  line-height: 10vh;
  font-size: 10vh;
  color: #0c0e3d !important;
  margin-bottom: 0;
}

.gallerypics {
  transform: translateY(-2vh);
  overflow: hidden;
  width: 100vw;
  position: relative;
  z-index: 100;
}

.gallerypics-slide {
  display: flex;
  animation: slideLeft 10s linear infinite;
  /* adjust duration as needed */
}

.gallerypics-slide img {
  width: 100%;
  /* adjust image size */
  flex-shrink: 0;
}

.gallerypics:hover .gallerypics-slide {
  animation-play-state: paused;
}

@keyframes slideLeft {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

/* .ghmgallerypics-1{
    height:100%;
    width:100%;
    z-index: 3;
  }
  .ghmgallerypics-2{
    margin-top: 8vh;
    height:100%;
    width:100%;
    transform: translateX(-2vw)!important;
    z-index: 4;
  }
  .ghmgallerypics-3{
    margin-top: 2vh;
    height:100%;
    width:100%;
    transform: translateX(-4vw)!important;
    z-index: 7;
  }
  .ghmgallerypics-4{
    height:100%;
    width:100%;
    transform: translateX(-6vw)!important;
    z-index: 5;
  }
  .ghmgallerypics-5{
    margin-top: 10vh;
    height:100%;
    width:100%;
    transform: translateX(-9vw)!important;
   z-index: 6;
  }
  .ghmgallerypics-6{
    margin-top: 4vh;
    height:100%;
    width:100%;
    transform: translateX(-12vw)!important;
    z-index: 5;
  } */

.lowertext {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: right;
  margin-right: 2vw;
}

.lowertxt {
  margin-top: 0;
  margin-bottom: 0;
}

.lowertext p {
  font-size: 2vh;
  font-family: "MintGrotesk";
  font-weight: 500;
  font-style: normal;
  text-align: right;
  color: #0c0e3d !important;
}

@media (max-width: 1024px) and (min-width: 480px) {
  .ghmgallerypics {
    aspect-ratio: 155/202 auto;
    width: 45vw;
    height: 35vh;
  }

  .gallerytext {
    margin-left: 5vh;
    padding-top: 8vh;
    padding-bottom: 0vh;
    margin-bottom: 4vh;
  }

  .gallerytext p {
    font-size: 7vh;
    line-height: 6vh;
    margin-top: 0vw;
  }

  .gallerytext i {
    line-height: 1vh;
    font-size: 7vh;
    color: #0c0e3d;
  }

  .gallerypics {
    overflow: hidden;
    width: 100vw;
    position: relative;
  }

  .gallerypics-slide img {
    height: 60vh;
    max-width: 250%;
    width: 250%;
    flex-shrink: 0;
  }

  .gallerypics-slide {
    display: flex;
    animation: slideLeft 10s linear infinite;
    /* adjust duration as needed */
  }

  .gallerypics:hover .gallerypics-slide {
    animation-play-state: paused;
  }

  @keyframes slideLeft {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(-250%);
    }
  }
}

@media (max-width: 515px) {
  .gallerymain {
    width: 100%;
    height: 90vh;
    overflow: hidden;
  }

  .ghmgallerypics {
    aspect-ratio: 155/202 auto;
    width: 27vw;
    height: 35vh;
  }

  .gallerytext {
    margin-left: 4vh;
    margin-top: 4vh;
  }

  .gallerytext p {
    font-size: 7vh;
    line-height: 6vh;
    margin-top: 0vw;
    letter-spacing: -0.01em;
  }

  .gallerytext i {
    line-height: 1vh;
    font-size: 7vh;
    color: #0c0e3d;
  }

  .gallerypics {
    overflow: hidden;
    width: 100vw;
    position: relative;
    transform: translateY(-1vh);
  }

  .gallerypics-slide img {
    height: 47vh;
    max-width: 270%;
    width: 270%;
    flex-shrink: 0;
  }

  .gallerypics-slide {
    display: flex;
    animation: slideLeft 10s linear infinite;
    /* adjust duration as needed */
  }

  .gallerypics:hover .gallerypics-slide {
    animation-play-state: paused;
  }

  @keyframes slideLeft {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(-270%);
    }
  }

  .lowertext {
    margin-top: 4vh;
    margin-bottom: 0;
  }
}

@keyframes ready {
  0% {
    background-color: #d9f971;
    transform: translateY(0%);
  }

  20% {
    background-color: #c4e84b;
    transform: translateY(-32%);
  }

  50% {
    background-color: #c4e84b;
    transform: translateY(-32%);
  }

  60% {
    background-color: rgb(200, 249, 38);
    transform: translateY(-63%);
  }

  100% {
    background-color: rgb(200, 249, 38);
    transform: translateY(-63%);
  }
}

/*SPONSOR + BENIFITS*/
.sponsorpage {
  background: url("./GhmAssets/Whitebg texture 4.png") no-repeat center center/cover;
  height: 120vh;
  z-index: 120;
}

.sponsor-heading {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: right;
  margin-right: 8vh;
  padding-top: 25vh;
}

.sponsor-heading-tag {
  font-family: "NN Swinton";
  font-weight: 800;
  font-size: 10vh;
  gap: 10%;
  color: #0c0e3d !important;
  line-height: 8vh;
  letter-spacing: -0.03em;
}

.sponsor-heading-tag i {
  font-family: "NN Swinton";
  font-weight: 800;
  line-height: 10vh;
  font-size: 10vh;
  color: #0c0e3d !important;
}

.sponsor-heading-subtag {
  color: #0c0e3d !important;
  width: auto;
  font-weight: 300;
  font-style: italic;
  font-size: 3.2vh;
}

.benifits-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 8vw;
  text-align: center;
}

.benefitsghm {
  width: 8vw;
  height: 15vh;
  position: relative;
}

.benefitsghm-bib {
  width: 10vw;
  height: 15vh;
  position: relative;
}

.benefitsghm-certificate {
  width: 10vw;
  height: 13vh;
  position: relative;
}

.benifits-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15vh;
}

.benifits-icon1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon-text {
  width: auto;
  height: auto;
  position: relative;
  /* font-family: mint grotesk trial;*/
  font-weight: 300;
  font-size: 3.2vh;
  color: #0c0e3d;
}

.sponsors {
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.past-sponsors {
  font-family: "NN Swinton";
  color: #0c0e3d;
  position: relative;
  z-index: 200;
  font-size: 5vw;
  font-weight: 800;
  font-style: italic;
  text-align: center;
  width: auto;
  height: auto;
  margin-top: 3vh;
}

.companiessponsors {
  background: url("./GhmAssets/bg.png") no-repeat center center/cover;
  width: 100%;
  height: 100vh;
  /* position: relative; */
  overflow: hidden;
  /* z-index: 98; */
}

.companies-a {
  width: 20%;
  left: 50%;
}

.companies-a1 {
  width: 18%;
  left: 50%;
  margin-top: -2%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.companies {
  display: flex;
  gap: 3vh;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  line-height: normal;
  align-items: center;
  justify-content: center;
  margin-top: 4vh;
  z-index: 120;
}

.sponsorname {
  margin-top: 2%;
  color: #020328;
  font-family: "MintGrotesk";
  font-size: 3.2vh;
  font-weight: 300;
}

.name {
  color: #020328;
  font-size: 2.7vh;
  color: #0c0e3d !important;
  font-weight: 300;
}

@media screen and (max-width: 480px) {
  .sponsorpage {
    height: 100vh;
  }

  .tag {
    font-size: 10vw;
  }

  .icon-text {
    position: relative;
    /* font-family: mint grotesk trial;*/
    font-weight: 300;
    font-size: 2.7vh;
    color: #0c0e3d;
    margin-top: 2vh;
  }

  .past-sponsors {
    font-size: 10vw;
  }

  .sponsor-icon {
    margin-top: 0vh;
  }

  .benifits-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0vh;
  }

  .benefitsghm {
    width: 24vw;
    height: 12vh;
  }

  .sponsor-logo {
    margin-left: 2vw;
    margin-right: 2vw;
  }

  .sponsor-heading {
    margin-right: 3vh;
    text-align: right;
  }

  .sponsor-heading-tag {
    font-size: 7vh;
    line-height: 1vh;
  }

  .sponsor-heading-tag i {
    font-size: 7vh;
  }

  .benifits-row {
    margin-top: 5vh;
    row-gap: 4vh;
    flex-direction: column;
    height: auto;
  }

  .benefitsghm-bib {
    width: 25vw;
    height: 13vh;
    position: relative;
  }

  .benefitsghm-certificate {
    width: 27vw;
    height: 12vh;
    position: relative;
  }

  .sponsor-heading-subtag {
    color: #0c0e3d !important;
    width: auto;
    font-weight: 300;
    font-style: italic;
    font-size: 3vh;
  }

  .companiessponsors {
    height: auto;
    padding-bottom: 5vh;
  }

  .sponsors {
    text-align: center;
    align-items: center;
  }

  .companies-a {
    flex-direction: column;
    gap: 0vh;
    width: 40%;
  }

  .sponsorname {
    color: #020328;
    scale: 0.8;
    width: 81vw;
    right: 2vw;
  }

  .companies-a {
    margin-bottom: 3vh;
  }

  .companies-a1 {
    width: 35%;
    margin-bottom: 3vh;
  }

  .sponsor-icon1 {
    position: relative;
  }

  .past-sponsors {
    width: 80vw;
    font-family: "NN Swinton";
    font-style: italic;
    font-size: 7vh;
    line-height: 5.5vh;
    text-align: center;
  }
}

@media (max-width: 1024px) and (min-width: 480px) {
  .tag {
    font-size: 10vw;
  }

  .icon-text {
    position: relative;
    /* font-family: mint grotesk trial;*/
    font-weight: 300;
    font-size: 2.7vh;
    color: #0c0e3d;
    margin-top: 2vh;
  }

  .past-sponsors {
    font-size: 7vh;
  }

  .sponsor-icon {
    margin-top: 0vh;
  }

  .benifits-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0vh;
  }

  .benefitsghm {
    width: 12vw;
    height: 10vh;
  }

  .sponsor-logo {
    margin-left: 2vw;
    margin-right: 2vw;
  }

  .sponsor-heading {
    margin-right: 2vh;
    text-align: right;
  }

  .sponsor-heading-tag {
    font-size: 7vh;
    line-height: 1vh;
  }

  .sponsor-heading-tag i {
    font-size: 7vh;
  }

  .benifits-row {
    margin-top: 5vh;
    row-gap: 4vh;
    height: auto;
  }

  .benefitsghm-bib {
    width: 17vw;
    height: 10vh;
    position: relative;
  }

  .benefitsghm-certificate {
    width: 19vw;
    height: 9vh;
    position: relative;
  }

  .sponsor-heading-subtag {
    color: #0c0e3d !important;
    width: auto;
    font-weight: 300;
    font-style: italic;
    font-size: 3vh;
  }

  .companies {
    gap: 0vh;
  }

  .companies-a {
    width: 35%;
  }

  .companies-a1 {
    width: 35%;
  }

  .sponsor-logo {
    position: relative;
  }

  .sponsor-logo img {
    height: 10vh;
  }

  .sponsor-icon1 {
    position: relative;
  }
}

/* MERCHANDISE */
.ghmmerch-container {
  background: url("./GhmAssets/bg.png") no-repeat center center/cover;
  width: 100vw;
  /* z-index: 98; */
}

.ghmmerch-paint-container {
  /* background: url("./GhmAssets/merchbg.png") no-repeat center center/cover; */
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  /* z-index: 98; */
  display: flex;
  flex-direction: row;
  align-items: center;
  /* gap: 15vw; */
  text-align: right;
  justify-content: space-between;
}

.merchdesignimg {
  margin-bottom: 15vh;
  z-index: 121;
  position: relative;
  width: 50vw;
  transform: rotate(-1.4deg);
}

.regularprice {
  font-family: "MintGrotesk";
  font-size: 10vh;
  font-weight: 600;
}

.gloryprice {
  font-family: "MintGrotesk";
  font-size: 10vh;
  color: #d9f971;
  font-weight: 600;
  margin-top: 4vh;
}

.pricetext {
  font-family: "MintGrotesk";
  font-size: 3.2vh;
  font-weight: 300;
}

.pricetext-glory {
  font-family: "MintGrotesk";
  font-size: 3.2vh;
  font-weight: 300;
  color: #d9f971;
}

.pp1-merch {
  font-family: "MintGrotesk";
  font-weight: 700;
  color: black;
  z-index: 2;
  height: 100%;
  width: 24vw;
  display: flex;
  justify-content: center;
  padding: 2% 0;
  border-radius: 60px;
  align-items: center;
  cursor: pointer;
  font-size: larger;
}

.animated-button-merch {
  z-index: 99;
  background-color: #d9f971;
  /* position: relative; */
  width: 25vw;
  height: 9%;
  overflow: hidden;
  display: flex;
  border-radius: 60px;
  align-items: center;
  flex-direction: column;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -10%);
  bottom: 10%;
}

.pp1-merch {
  font-family: "MintGrotesk";
  font-weight: 700;
  color: black;
  z-index: 2;
  height: 100%;
  width: 18vw;
  display: flex;
  justify-content: center;
  padding: 2% 0;
  border-radius: 60px;
  align-items: center;
  cursor: pointer;
  font-size: larger;
}

@media screen and (max-width: 480px) {
  .ghmmerch-paint-container {
    display: block;
    margin-bottom: 0vh;
    height: 80vh;
    padding-top: 8vh;
  }

  .merchprice {
    flex-direction: row;
  }

  .merchimage {
    display: flex;
    align-items: center;
  }

  .merchimage img {
    max-width: 120%;
    width: 120%;
  }

  .merchdesignimg {
    justify-content: center;
    width: 90vw;
    height: 40vh;
  }

  .merchprice {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 4vw;
    padding-left: 4vw;
  }

  .regularprice {
    font-size: 4vh;
    align-self: flex-start;
    text-align: center;
    width: 40%;
    font-size: 4vh;
  }

  .gloryprice {
    font-size: 4vh;
    align-items: flex-end !important;
    text-align: center;
    color: #d9f971;
    width: 30%;
    text-align: center;
    width: 40%;
    margin-top: 0vh;
  }

  .pricetext {
    font-size: 2vh;
  }

  .pricetext-glory {
    font-size: 2vh;
    color: #d9f971;
  }

  .merchdesign {
    height: 80vh;
  }

  .merchdesignimg {
    margin-bottom: 0vh;
    width: 90vw;
    height: 40vh;
  }

  .merchimage {
    margin-left: 5vw;
    margin-right: 5vw;
    align-items: center;
  }

  .merchprice {
    flex-direction: row;
    width: 100%;
    gap: 0vw;
    position: relative;
    z-index: 115;
  }

  .pricetext {
    font-size: 2vh;
  }

  .buynowbutton {
    margin-top: 5vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .animated-button-merch {
    width: 35vmax;
    height: 10vmin;
    font-size: 4vmin;
  }

  .pp1-merch {
    font-size: 2vmax;
    padding: 0.5vw 1.2vh;
    width: 100%;
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .ghmmerch-paint-container {
    display: block;
    padding-top: 15vh;
  }

  .merchdesign {
    height: 80vh;
  }

  .merchdesignimg {
    width: 88vw;
    height: 52vh;
  }

  .merchimage {
    margin-left: 5vw;
    margin-right: 5vw;
    align-items: center;
    position: relative;
    /* top: -2%; */
  }

  .merchprice {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100vw;
    padding-right: 0vw;
  }

  .regularprice {
    font-size: 4vh;
    align-self: flex-start;
    text-align: center;
    width: 50%;
  }

  .gloryprice {
    font-size: 4vh;
    align-self: self-end;
    text-align: center;
    width: 50%;
    color: #d9f971;
    margin-top: 0vh;
  }

  .pricetext {
    font-size: 2vh;
  }

  .pricetext-glory {
    font-size: 2vh;
    color: #d9f971;
  }
}

/* PRICE CONTAINER */
.price-container {
  padding: 5% 0;
  background: url("./GhmAssets/bg.png") no-repeat center center/cover;
  height: 100vh;
  width: 100vw;
  display: flex;
  position: relative;
}

.woman img {
  height: 80vh;
  top: 10%;
  right: 15%;
  z-index: 120;
  position: relative;
}

.price-text {
  width: 90vw;
  align-items: flex-start;
  z-index: 121;
}

.line-1-1 {
  font-family: "NN Swinton";
  font-weight: 800;
  font-size: 8vh;
  z-index: 121;
  color: #0c0e3d !important;
  font-style: italic;
}

.line-1-2 {
  font-family: "NN Swinton";
  font-weight: 800;
  font-size: 8vh;
  z-index: 121;
  color: #0c0e3d !important;
  font-style: italic;
  margin-top: -10%;
  left: 23%;
  position: relative;
}

.font-orange-1 {
  font-family: "NN Swinton";
  font-size: 20vh;
  color: #ff6d3a !important;
  font-weight: 800;
}

.line-2-1 {
  font-family: "NN Swinton";
  font-weight: 800;
  font-size: 8vh;
  z-index: 121;
  color: #0c0e3d !important;
  font-style: italic;
  margin-top: -8%;
}

.line-2-2 {
  font-family: "NN Swinton";
  font-weight: 800;
  font-size: 8vh;
  z-index: 121;
  color: #0c0e3d !important;
  font-style: italic;
  margin-top: -10%;
  left: 15%;
  position: relative;
}

.font-violet-1 {
  font-family: "NN Swinton";
  font-size: 20vh;
  color: #9957db !important;
  font-weight: 800;
}

.font-orange-2 {
  font-family: "NN Swinton";
  font-size: 20vh;
  color: #ff6d3a !important;
  font-weight: 800;
}

.font-violet-2 {
  font-family: "NN Swinton";
  font-size: 20vh;
  color: #9957db !important;
  font-weight: 800;
}

@media (max-width: 565px) {

  .font-orange-1,
  .font-orange-2 {
    padding: 2vh;
    font-size: 8vmax;
  }

  .font-violet-2,
  .font-violet-1 {
    padding: 2vh;
    font-size: 8vmax;
  }

  .line-2-1,
  .line-1-2,
  .line-1-1,
  .line-2-2 {
    font-size: 5vmax;
    display: flex;
    align-items: center;
    margin-top: -15%;
  }

  .line-2-2 {
    width: max-content;
    left: 10vw;
  }

  .line-2-1:last-child {
    margin-left: 15vw;
    width: 100vw;
  }

  .price-container {
    /* margin-left: 25%; */
    display: flex;
    align-items: center;
    justify-content: end;
    z-index: 114;
    height: 70vh;
    width: 100vw;
  }

  .price-text {
    scale: 0.8;
    width: 81vw;
    position: absolute;
    right: 2vw;
    transform: translateY(10vh);
  }

  .woman {
    position: absolute;
    scale: 0.6;
    top: -19%;
    left: -40%;
  }
}

/* FOOTER */
.footer-ghm {
  position: absolute;
  width: 100%;
  /* background-image: url("./GhmAssets/Footerbg.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  transform: translateY(-40vh);
  z-index: 110;
}

.vector {
  margin-top: 10vh;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  margin-left: 25vw;
  margin-right: 25vw;
  gap: 2vw;
}

.lowerfooter {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 3vw;
  gap: 20%;
  align-items: center;
  justify-content: center;
}

.leftside {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-left: 1vw;
}

.seeyouatthe {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 30vw;
  font-weight: 900;
  margin-bottom: 2vh;
}

.seeyouatthe p {
  font-family: "NN Swinton";
  font-weight: 700;
}

.startingline {
  font-family: "NN Swinton";
  text-align: left;
  font-style: normal;
  font-weight: 900;
  font-size: 7vh;
  line-height: 88%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #f3f3f0;
  float: left;
  margin: 0;
}

.startingline i {
  font-family: "NN Swinton";
  text-align: left;
  font-style: italic;
  font-weight: 700;
  font-size: 6vh;
  line-height: 88%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #f3f3f0;
  margin: 0;
}

.writetous {
  text-align: left;
  margin-top: 4vh;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 15vw;
}

#writeto {
  font-style: bold;
  font-weight: 600;
  font-size: 3vh;
  line-height: 31px;
  text-align: left;
  text-transform: capitalize;
  color: #d9f971;
}

#mailtechniche {
  margin-top: 2vh;
  font-style: normal;
  font-weight: 500;
  font-size: 2vh;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #d9f971;
}

.rightside {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 6vh;
  gap: 8vw;
}

.linksghm {
  margin-right: 1vh;
  margin-top: 1vh;
  margin-bottom: 4vh;
  text-align: left;
}

.quicklinksblock {
  padding-top: 1vh;
  text-align: center;
}

.quicklinksheading {
  font-style: normal;
  font-weight: 600;
  font-size: 3vh;
  line-height: 31px;
  text-transform: capitalize;
  color: #d9f971;
  margin-bottom: 3vh;
}

.linkitems {
  font-family: "MintGrotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 2.5vh;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #d9f971;
  text-decoration: none;
  align-items: start;
}

.contactusghm {
  padding-top: 1vh;
}

.contact-details {
  font-family: "MintGrotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 2.5vh;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #d9f971;
  text-decoration: none;
  margin-right: 1vh;
  margin-top: 1vh;
  margin-bottom: 0.5vh;
  text-align: left;
}

.contact-number {
  font-weight: 300;
  color: #d9f971;
}

/* @media screen and (max-width: 1220px) {
        .vector{ 
         margin-top: 10vh;
         margin-left:10vw;
         margin-right:10vw;
         gap:1vw;
        }
        .seeyouatthe{
         float:none;
         width:auto;
         margin-top: 2vh;
         font-size: 20vw;
        }
        .startingline{
         font-size: 6vh;
         
        }
        .writetous{
         display: inline;
         align-items: center;
         justify-content: center;
         width: auto;
        }
        .lowerfooter{
         flex-direction: column;
         gap: 2vw;
         align-items: center;
        }
        .leftside{
         margin-left: 0vw;
         align-items: center;
        }
        .rightside{
         margin-top: 1vh;
         gap:18vw;
        }
        .linkitems{
         font-size: 2vh;
        }
        .contact-details{
         font-size: 2vh;
        }   
     } */
@media (max-width: 1024px) and (min-width: 480px) {
  .footer-ghm {
    transform: translateY(-70vh);
  }

  .vector {
    margin-top: 10vh;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-evenly;
    margin-left: 11vw;
    margin-right: 11vw;
  }

  .vector a {
    height: 8vh;
    width: 8vh;
  }

  .seeyouatthe {
    float: none;
    width: auto;
    margin-top: 2vh;
    font-size: 20vw;
    text-align: center;
    justify-content: center;
  }

  .writetous {
    display: flex;
    align-items: left;
    justify-content: center;
    width: auto;
    margin-top: 2vh;
    text-align: center;
    gap: auto;
  }

  #writeto {
    text-align: center;
  }

  .writetous a {
    text-align: center;
  }

  .startingline {
    text-align: center;
    font-size: 6vh;
  }
}

@media screen and (max-width: 515px) {
  .footer-ghm {
    transform: translateY(-30vh);
  }

  .vector {
    margin-top: 10vh;
    margin-left: 10vw;
    margin-right: 10vw;
    gap: 1vw;
    padding-bottom: 0vh;
  }

  .seeyouatthe {
    float: none;
    width: auto;
    margin-top: 2vh;
    font-size: 20vw;
  }

  .startingline {
    text-align: center;
    font-size: 6vh;
  }

  .writetous {
    display: inline;
    align-items: center;
    justify-content: center;
    width: auto;
    margin-top: 2vh;
    text-align: center;
  }

  #writeto {
    text-align: center;
  }

  .lowerfooter {
    flex-direction: column;
    gap: 2vw;
    align-items: center;
  }

  .leftside {
    margin-left: 0vw;
    align-items: center;
  }

  .rightside {
    margin-top: 1vh;
    gap: 18vw;
  }

  .linkitems {
    font-size: 2vh;
  }

  .contact-details {
    font-size: 2vh;
  }

  .contact-number {
    font-size: 2vh;
  }

  .linksghm {
    margin: 0vw;
    padding-bottom: 4vh;
  }
}

/* Confirmation Page */

.container-confirm {
  background: url("./GhmAssets/bg.png") no-repeat center center/cover;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 3vh;
  justify-content: center;
  align-items: center;
}

.container-confirm h1 {
  text-align: center;
  color: #0C0E3D;
  text-transform: uppercase;
  font-size: 4vmax;
}

.container-confirm p {
  text-align: center;
  color: #0C0E3D;
  font-size: 2vmax;
}









.other-sponsors-wrapper {
  background: url("./GhmAssets/Whitebg texture 4.png") no-repeat center/cover;
  height: 260vh;
  z-index: 120;
  margin: 0px 0px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
}

.other-sponsors-inner-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  /* width: 100%; */
}

.sponsors-item-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 10vh;
  margin-left: 1vw;
  margin-right: 1vw;
}

.sponsors-title-wrapper {
  font-size: 2.8vh;
  height: 20%;
  margin-bottom: 3vh;
  text-align: center;
  width: 100%;
}

.sponsors-img-wrapper {
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 121;
}

.sponsors-img {
  width: 180px;
}

.hospital-logo {
  width: 120px;
}

.img-presenting {
  width: 80%;
  margin-top: 4vh;
}

@media screen and (min-width:750px) and (max-width:1100px) {
  .sponsors-img {
    width: 120px;
  }

  .hospital-logo {
    width: 100px;
  }
}

@media screen and (min-width:500px) and (max-width:750px) {
  .other-sponsors-inner-wrapper {
    grid-template-columns: 1fr 1fr;
  }

  .sponsors-item-box {
    margin: 50px 0px 50px 0px;
  }

  .sponsors-title-wrapper {
    font-size: 2rem;
  }
}

@media screen and (max-width:500px) {
  .other-sponsors-wrapper {
    background: url("./GhmAssets/Whitebg texture 4.png") no-repeat center/cover;
    height: 350vh;
    z-index: 120;
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100vw;
  }

  .other-sponsors-inner-wrapper {
    position: relative;
    top: -5%;
    grid-template-columns: 1fr 1fr;
    height: 300vh;
  }

  .sponsors-img {
    width: 120px;
  }

  .hospital-logo {
    width: 100px;
  }

  .sponsors-item-box {
    width: 50vw;
  }

  .sponsors-title-wrapper {
    font-size: 2.8vh;
    height: 20%;
    margin-bottom: 0vh;
    text-align: center;
    width: 100%;
  }

  .sponsors-item-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 1vh;
    margin-left: 1vw;
    margin-right: 1vw;
  }

  .sponsors-img-wrapper {
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 121;
  }

}