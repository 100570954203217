*, *::before, *::after {
    box-sizing: inherit;
    /* cursor: none; */
  }
  
  html {
    /* this makes sure the padding and the border is included in the box sizing */
    background: #000;

  }
  
  html body {
    padding: 0;
    margin: 0;
    overflow-y: inherit;
    background: #000;
  }

  .App{
    height:100vh;
    width:100vw;
    position: relative;
    background-color: black;
}
/* App.css */
