@font-face {
  font-family: 'FK_Grotesk';
  src: url('../fonts/FKGroteskNeueTrial-Regular-BF6576818c3af74.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
 }
 @font-face {
  font-family: 'FK_Grotesk';
  src: url('../fonts/FKGroteskNeueTrial-Thin-BF6576818c2a14c.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
 }
 @font-face {
  font-family: 'FK_Grotesk';
  src: url('../fonts/FKGroteskNeueTrial-Bold-BF6576818bd3700.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
 }
 
 
 .header-wrapper-outter {
  background: url('../assets/nav-bar.png') center center/cover no-repeat;
  position: fixed;
  width: 100vw;
  z-index: 120;
  height: 8vh;
  justify-content: space-around;
 }
 
 
 .header-wrapper{
  /* background: url('../assets/navbarbg.png');
  background-repeat: repeat-y; */
  position: fixed;
  /* background-size:cover; */
  width: 100vw;
  height: 8vh;
  z-index: 100;
  justify-content: space-around;
 }
 .header-navbar-ul li{
  font-family: 'FK_Grotesk_SemiMono';
  width: max-content;
  padding: 0px 30px;
  font-weight: 100;
 }
 .header-navbar-ul{
  font-size: 1rem;
 }
 .highlighted-header{
  color:#F3FF63;
  font-style: italic;
 }
 .header-btn-wrapper{
  width: 7vw;
 }
 @media screen and (max-width:1100px) {
  .header-navbar-ul li{
    padding: 0px 15px;
  }
  .header-navbar-ul{
    font-size: 0.8rem;
  }
 }
 .header-logo{
  cursor: pointer;
 }
 .header-logo-wrapper{
  width: 20%;
  cursor: pointer;
 }
 .header-navbar-ul li:hover{
  color: #F3FF63;
  font-style: italic;
  cursor: pointer;
 }
 .hamburger-icon-wrapper{
  display: none;
 }
 .header-navbar-wrapper{
  display: flex;
 }
 @media screen and (max-width:850px) {
  .navbar-heading-name,.header-navbar-wrapper,.header-btn-wrapper{
    display: none;
  }
  .header-logo-txt{
    display: none;
  }
  .hamburger-icon-wrapper{
    display:flex;
    width: 40px;
  }
  .header-logo-wrapper{
    width: max-content;
  }
  .header-wrapper{
    background: #2D48D5;
    padding: 0vh 0px;
    justify-content: space-between;
    width: 100vw;
  }
 }
 .hamburger-wrapper{
  position: fixed;
  top: 0px;
  background: url('../assets/ham-bg.png');
  width: 100vw;
  z-index: 99;
  height: 60vh;
 }
 .hamburger-wrapper ul{
  margin-top: 10vh;
  text-align: right;
  font-size: 1.5rem;
 }
 .hamburger-wrapper ul li{
  font-family: 'FK_Grotesk_SemiMono';
  font-size: 1rem;
  padding: 2vh 6vw 2vh 2vw;
 }
 .hamburger-wrapper ul li:hover{
  color:#2D48D5 ;
  background-color: #F3FF63;
 }
 
 
 .header-wrapper-outter.hidden {
  top: -100px; /* Adjust this based on your header height */
 }
 
 
 .header-wrapper-outter.visible {
  top: 0;
 }
 
 
 .header-wrapper {
  opacity: 1;
 }
 