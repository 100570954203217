*{
    padding: 0px;
    margin: 0px;
    scrollbar-width: none;
  }
  @font-face {
    font-family: 'That_New_Pixel';
    src: url('../../../fonts/thatthatnewpixelfamilytest-italicsquare.otf') format('opentype');
    font-weight: 300;
    font-style: italic;
  }
  @font-face {
    font-family: 'That_New_Pixel';
    src: url('../../../fonts/thatthatnewpixelfamilytest-square.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'FK_Raster_Grotesk';
    src: url('../../../fonts/FKRasterGroteskCompact-Rounded.ttf') format('opentype');
    font-weight: 700;
    font-style: normal;
  }
  .years-parent-wrapper{
    height: 255vh;
    margin-bottom: 8vh;
  }
  .years-first-slide{
    height: 100vh;
  }
  @media screen and (max-width:900px) {
    .years-outer-wrapper{
      /* height: 100vh; */
    }
    .years-first-slide{
      background-image: url('../../../assets/bluepixelbg.png');
    }
    .years-parent-wrapper{
      height: 135vh;
      margin-bottom: 0vh;
    }
  }
  .years-outer-wrapper{
    cursor: pointer;
    /* height: 100vh; */
    width: 100vw;
    /* overflow-x: hidden; */
    position: relative;
    padding-bottom: 10vh;
  }
  .years-inner-wrapper{
    /* height: 67vh; */
    width: 100vw;
    /* transform: translateY(67vh); */
    position: relative;
    transition: 0.5s;
    /* overflow-y: hidden; */
  }
  .year-scroll{
    height: 33vh;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    width: 100vw;
    font-size: 10rem;
    padding-right: 40px;
    display: flex;
    align-items: center;
  }
  .year-scroll span{
    font-family: 'FK_Raster_Grotesk';
    letter-spacing: 0.25rem;
  }
  @media screen and (max-width:500px) {
    .year-scroll{
      font-size: 7rem;
    }
  }
  @media screen and (max-width:400px) {
    .year-scroll{
      font-size: 6rem;
    }
  }
  .year-scroll span{
  padding:0px 10px;
  }
  .h-infinite-carousel{
    background-color: #2d48d5;
    width: 100vw;
    z-index: 2;
    position: sticky;
    top: 0vh;
    overflow-x: hidden;
  }
  .years-heading{
    font-family: 'That_New_Pixel';
    font-size:4rem;
    color: #2D48D5;
  }
  .years-heading span{
    font-family: 'That_New_Pixel';
    font-size: 4rem;
  }
  .inner-carousel{
  display: flex;
  animation: scroller 4s linear infinite;
  }
  @keyframes scroller {
    from{
      transform: translateX(0%);
    }to{
      transform: translateX(-100%);
    }
  }
  .year-card-wrapper{
    position: fixed;
    clip-path: polygon(
      30px 0px,
      30px 10px,
      20px 10px,
      20px 20px,
      10px 20px,
      10px 30px,
      0px 30px,
      0px calc(100% - 30px),
      10px calc(100% - 30px),
      10px calc(100% - 20px),
      20px calc(100% - 20px),
      20px calc(100% - 10px),
      30px calc(100% - 10px),
      30px 100%,
      calc(100% - 30px) 100%,
      calc(100% - 30px) calc(100% - 10px),
      calc(100% - 20px) calc(100% - 10px),
      calc(100% - 20px) calc(100% - 20px),
      calc(100% - 10px) calc(100% - 20px),
      calc(100% - 10px) calc(100% - 30px),
      100% calc(100% - 30px),
      100% 30px,
      calc(100% - 10px) 30px,
      calc(100% - 10px) 20px,
      calc(100% - 20px) 20px,
      calc(100% - 20px) 10px,
      calc(100% - 30px) 10px,
      calc(100% - 30px) 0px,
      30px 0px
    );
    height: 80vh;
    width: 30vw;
    display: none;
    opacity: 0;
    align-items: center;
    justify-content: center;
    top: 10vh;
    transition: 1s;
    right: 5vw;
    z-index: 3;
    background-color: black;
  }
  .year-card-visible{
    display: flex;
    opacity: 1;
  }
  .year-card-wrapper-2{
    clip-path: polygon(
    30px 0px,
    30px 10px,
    20px 10px,
    20px 20px,
    10px 20px,
    10px 30px,
    0px 30px,
    0px calc(100% - 30px),
    10px calc(100% - 30px),
    10px calc(100% - 20px),
    20px calc(100% - 20px),
    20px calc(100% - 10px),
    30px calc(100% - 10px),
    30px 100%,
    calc(100% - 30px) 100%,
    calc(100% - 30px) calc(100% - 10px),
    calc(100% - 20px) calc(100% - 10px),
    calc(100% - 20px) calc(100% - 20px),
    calc(100% - 10px) calc(100% - 20px),
    calc(100% - 10px) calc(100% - 30px),
    100% calc(100% - 30px),
    100% 30px,
    calc(100% - 10px) 30px,
    calc(100% - 10px) 20px,
    calc(100% - 20px) 20px,
    calc(100% - 20px) 10px,
    calc(100% - 30px) 10px,
    calc(100% - 30px) 0px,
    30px 0px
  );
    height: calc(80vh - 5px);
    width: calc(30vw - 5px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .year-card-text{
    font-size: 1.7rem;
    background-color: rgb(255, 255, 112);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25vh;
    padding:0px 50px ;
  }
  .year-card-img{
    background-color: white;
    height: 72%;
  }
   .year-card-img img{
    width: 100%;
    height: 100%;
  }
  /* .fade{
    animation:  fade 2s forwards ;
    opacity: 0;
  } */
  @keyframes fade {
    to{
      opacity: 1;
    }
  }
  @media screen and (max-width:1000px) {
    .year-card-wrapper{
      width: 50vw;
      left: 45vw;
    }
    .year-card-wrapper-2{
      width: calc(50vw - 5px);
    }
  }
  @media screen and (max-width:700px) {
    .years-first-slide{
      height: 50vh;
    }
    .year-card-wrapper{
      height: 51vh;
      width: 70vw;
      left: 28vw;
      top: 18vh;
    }
    .year-card-text{
      font-size: 1.4rem;
      padding: 0px 20px;
    }
    .year-card-wrapper-2{
      height: calc(51vh - 5px);
      width: calc(70vw - 5px);
    }
    .years-heading {
      color: #F7F8FF;
      text-align: center;
      width: 60vw;
      font-size: 2.5rem;
    }
    .years-heading span{
      font-size: 2.5rem;
    }
    .year-scroll{
      height: 17vh;
    }
    .year-scroll span{
      font-size: 3.5rem;
    }
  }
  @media screen and  (max-width:550px) {
    .year-card-text{
      font-size: 1.2rem;
    }
  }
  @media screen and (max-width:400px) {
    .year-card-text{
      font-size: 1rem;
    }
  }
  /* @media screen and  (min-width:1500px) {
    .year-card-wrapper{
      width: 700px;
    }
    .year-card-wrapper-2{
      width: 690px;
    }
  }
  @media screen and (max-width:1100px) {
    .year-card-wrapper-2{
      width: calc(60vw - 10px);
    }
    .year-card-wrapper{
      width: 60vw;
      right: 20vw;
      top: 25vh;
    }
  }
  @media screen and (max-width:600px) {
    .year-card-wrapper{
      width: 80vw;
      right: 10vw;
    }
    .year-card-wrapper-2{
      width: calc(80vw - 5px);
    }
  } */


  .gap{
    width: 100%;
    height: 50vh;
  }
  .footer-pixel-top-2{
    bottom: 80vh;
  }