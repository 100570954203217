.main1 {
    display: flex;
    justify-content: center;
}
.main2{
    display:flex;
    justify-content: center;
    column-gap: 30vh;
    margin-top: 5vh;
}
.left-half {
    display: flex;
    justify-content: center;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgb(red, green, rgb(1, 1, 50));
}
.btn1{
    width:18vh;
    height: 6vh;
    background-color: #1d195e;
    border-radius: 2vh;

}
.right-half {
    width: 100%;
    padding-right: 10%;
    justify-content: center;
    align-items: center;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.main-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card-div {
    width: 100%;
    margin: 3vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

table {
    border: 5px solid white;
    background-color: black;
    width: 100%;
}

th,
td {
    border: 2px solid white;
    width: 150px;
    height: 70px;
    text-align: center;
}

.cell-font {
    font-size: 16px;
    color: white;
}


.table1 {
    margin-top: 15vh;
    font-size: 10vh;
}

.card-user {
    margin: 5%;
    border: 5px solid white;
    margin-top: 14%;
    margin-left: 30%;
    margin-right: 30%;
    padding: 3%;
    padding-bottom: 4%;
    background-image: linear-gradient(to right bottom, #1d195e, #232a66, #2d396b, #3a4870, #495673, #46556b, #465462, #48525a, #374146, #293033, #1b2021, #0d1010);
    border-radius: 10px;
    width: 60%;
}

.question-card {
    justify-content: center;
    align-items: center;
    margin: 10vw;
    border: 1px solid white;
    margin-top: 4%;
    margin-left: 10vw;
    margin-right: 10vw;
    padding-top: 5%;
    margin-bottom: 10%;
    padding-left: 15%;
    padding-right: 15%;
    font-size: 3.5vh;

    background-image: linear-gradient(to right bottom, #1d195e, #232a66, #2d396b, #3a4870, #495673, #46556b, #465462, #48525a, #374146, #293033, #1b2021, #0d1010);
    border-radius: 10px;
    width: 80%;
}

.label {
    border: 1vh white;
    margin: 5%;
}