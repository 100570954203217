.team-ribbon-wrapper div{
    transform: rotateZ(-7deg);
}
.head-img-wrapper{
    cursor: pointer;
    transition: 0.5s;
}
.team-wrapper{
    z-index: 98;
    height: 450vh;
}
.team-sticky-outer{
    height: 300vh;
}
.head-name-ribbon{
    animation: slide 10s linear infinite;
}
.team-sticky-wrapper{
    position: -webkit-sticky;
    position: sticky;
    height: auto;
    top: 0px;
}
.team-inner-wrapper{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 5vw;
    width: 250vw;
    /* width: max-content; */
}

.head-card-outer-wrapper{
    padding: 10vh 3vw;
    height: 100vh;
}
.team-sticky-outer-wrapper{
    height: 50vh;
}
@media screen and (max-width:900px) {
    .head-card-outer-wrapper{
        padding: 10vh 2vw;
    }
}
.head-team-ribbon{
    animation: slide 8s linear infinite;
}
/* .team-heading-wrapper{
    width: 65vw;
} */
.team-details-txt{
    width: 35vw;
}
.who-team{
    /* width: 75vw; */
    width: 100vw;
    text-wrap: nowrap;
    font-family: 'That_New_Pixel';
    color: #2D48D5;
    text-wrap: nowrap;
    font-size: 6rem;
}
.who-team-inner{
    font-family: 'That_New_Pixel';
    color: #2D48D5;
    margin-left: 35vw;
    text-wrap: nowrap;
}
@media screen and (max-width:800px) {
    .team-details-txt{
        width: 50vw;
    }
    /* .team-heading-wrapper{
        width: 75vw;
    } */
    .who-team-inner{
        margin-left: 25vw;
    }
}
@media screen and (max-width:600px) {
    .team-details-txt{
        width: 80vw;
    }
    /* .team-heading-wrapper{
        width: 85vw;
    } */
    .who-team-inner{
        margin-left: 15vw;
    }
}
@media screen and (max-width:500px) {
    .who-team-inner{
        margin-left: 15vw;
    }
    /* .who-team{
        width: 85vw;
    } */
}
@media screen and (max-width:450px) {
    .who-team-inner{
        margin-left: 10vw;
    }
    /* .who-team{
        width: 90vw;
    } */
}
@media screen and (max-width:400px) {
    .who-team-inner{
        margin-left: 15vw;
    }
}
@keyframes slide {
    from{
        transform: translateX(0%);
    }to{
        transform: translateX(-50%);
    }
}
.head-card-wrapper{
    z-index: 98;
    cursor: pointer;
    width: 12vw;
}
@media screen and (max-width:1000px) {
    .head-card-wrapper{
        width: 17vw;
    }
    .head-card-outer-wrapper{
        height: 80vh;
    }
}
@media screen and (max-width:750px) {
    .head-card-outer-wrapper{
        padding: 5vw 0px;
    }
    .head-card-wrapper{
        width: 21vw;
    }
    .team-sticky-wrapper{
        position: relative;
    }
    .who-team-inner{
        font-size: 3.8rem;
    }
    .team-sticky-outer-wrapper{
        height: 0vh;
    }
}
@media screen and (max-width:500px) {
    .head-card-outer-wrapper{
        padding: 0px;
        width: 80vw;
        height: fit-content;
        flex-direction: column;
    }
    .team-inner-wrapper{
        flex-direction: column;
        width: 100vw;
        height: fit-content;
    }
    .head-card-wrapper{
        width: 30vw;
    }
    .team-sticky-outer{
        height: 300vh;
    }
    .team-wrapper{
        height: 400vh;
    }
    .head-team-ribbon{
        animation: slide 40s linear infinite;
    }
    .head-name-ribbon{
        animation: slide 15s linear infinite;
    }
}
@media screen and  (max-width:400px){
    .head-card-wrapper{
        width: 34vw;
    }
    
}

