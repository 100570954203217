/*******************************************************
 * GENERAL / BASE STYLES
 *******************************************************/
 * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  a {
    text-decoration: none;
    cursor: pointer;
  }
  
  button {
    cursor: pointer;
    border: none;
    outline: none;
    background: none;
  }
  
  header,
  .navbar-container {
    width: 100%;
    position: sticky; /* keep it fixed at top as you scroll */
    top: 0;
    z-index: 999;
    background: #0e1f48; /* default background for both laptop and mobile */
  }
  
  /*******************************************************
   * DESKTOP VIEW
   * (we'll hide the desktop items on small screens)
   *******************************************************/
  .navbar-content.desktop-view {
    /* Laptop specs */
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    /* Use 100% width, but to mirror the "width: 1440" idea,
       we can center a max-width container. However, the user
       specifically wanted "padding: 24px 120px" and "gap: 8px" */
    width: 100%;
    min-height: 90px;
    border-bottom: 0.4px solid #fff6e4;
  
    /* The user’s required padding */
    padding: 24px 120px;
    gap: 8px;
  }
  
  .nav-left .nav-logo-img {
    /* The user wanted: width: 180; height: 40.84 */
    width: 180px;
    height: auto; /* let it scale */
  }
  
  /* Center links container: "width: 240; height: 21; gap: 40px;" */
  .nav-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    width: 280px;
    height: 21px; /* can be left as is or auto */
  }
  
  /* Each link style:
     - font-family: DM Sans;
     - font-weight: 400;
     - font-size: 16px;
     - line-height: 100%;
     - letter-spacing: -4%;
     - background: #FFFCF5 (the user asked for this but typically you want text color).
     We'll do color: #FFFCF5 to match a typical “light text on dark bg.” 
     If you want an actual background for the link, just invert them. */
  .nav-link {
    color: #fffcf5;
    font-family: "DM Sans", sans-serif;
    font-weight: 400;
    font-size: 1.3vw;
    line-height: 1;
    letter-spacing: -0.04em; /* -4% in CSS is about -0.04em */
    transition: opacity 0.2s ease-in-out;
  }
  
  .nav-link:hover {
    opacity: 0.7;
  }
  
  /* Right side (button)
     - background: #FFF6E4
     - width: 152, height: 42, padding: 8px 16px, gap: 10px
     - text style: 
         font-family: DM Sans;
         font-weight: 800;
         font-size: 20px;
         letter-spacing: -4%;
         background: #0E1F48; (we interpret as text color)
  */
  .nav-right .register-btn {
    background: #fff6e4;
    max-width: max-content;
    /* width: 152px; */
    height: 42px;
    padding: 8px 16px;
    font-family: "DM Sans", sans-serif;
    font-weight: 800;
    font-size: 20px;
    line-height: 1;
    letter-spacing: -0.04em;
    color: #0e1f48; /* If the "background" #0E1F48 is intended as text color, do this */
    transition: opacity 0.2s ease-in-out;
  }
  
  .nav-right .register-btn:hover {
    opacity: 0.8;
  }
  
  /*******************************************************
   * MOBILE VIEW
   * We'll hide the mobile items on larger screens
   *******************************************************/
  .navbar-content.mobile-view {
    display: none; /* hidden by default on large screens */
  }
  
  /* On small screens, we show the mobile version and hide the desktop version */
  @media screen and (max-width: 768px) {
    .navbar-content.desktop-view {
      display: none;
    }
  
    .navbar-content.mobile-view {
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      /* phone specs:
         background: #0E1F48; (already set)
         width: 390;
         height: 71;
         top: 65px; (sticky top done in parent)
         border-bottom-width: 1px #FFF6E4 
         padding: 24px 16px;
         gap: 8px;
      */
      width: 100%;
      min-height: 71px;
      border-bottom: 1px solid #fff6e4;
      padding: 24px 16px;
      gap: 8px;
    }
  
    /* The sub-div: width: 358, height: 37, justify-content: space-between
       We can replicate that with the .mobile-view’s internal children. */
  
    .mobile-left {
      display: flex;
      align-items: center;
      gap: 16px; /* as specified: width:146, height:24, gap:16 */
    }
  
    /* The “logo” that toggles the menu: 
       - width:24, height:16, gap:8 
       You can just let the icon size be set in .hamburger-icon */
    .mobile-logo {
      width: 24px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  
    .hamburger-icon {
      font-size: 24px;
      color: #fffcf5;
    }
  
    /* The image at the right of the logo:
       width:106, height:24
    */
    .mobile-text-img {
      width: 106px;
      height: auto;
    }
  
    /* The mobile “Register Now” button:
       background: #FFF6E4;
       width:126, height:37, padding:8px 16px, gap:10px
       text style: color #0E1F48, font-size 16px
    */
    .mobile-right .mobile-register-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff6e4;
      max-width: max-content;
      height: 37px;
      padding: 8px 16px;
      gap: 10px;
      font-family: "DM Sans", sans-serif;
      font-weight: 600;
      font-size: 16px;
      line-height: 1;
      letter-spacing: -0.04em;
      color: #0e1f48; 
    }
  
    /* Dropdown for the mobile menu */
    .mobile-dropdown {
      display: flex;
      flex-direction: column;
      background: #0e1f48;
      padding: 16px;
      border-bottom: 1px solid #fff6e4;
    }
  
    .dropdown-link {
      color: #fffcf5;
      font-family: "DM Sans", sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 1;
      letter-spacing: -0.04em;
      margin-bottom: 12px;
    }
  
    .dropdown-link:last-child {
      margin-bottom: 0;
    }
  }
  