.container11{
  background-color: white !important;
  display: flex;
  flex-direction: row;
}
.left-side{
  background-color: white;
}


@media only screen and (max-width: 768px){
  .container11{
    background-color: white !important;
    display: flex;
    flex-direction: column;
    width: 100vw;
  }
  .left-side{
    background-color: white;
    width: 100vw;
  }
}