@font-face {
  font-family: 'PP_Mori';
  src: url('../../../fonts/PPMori-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Zaio';
  src: url('../../../fonts/Armageda Wide.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

.signup-new {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 160vh;
  background-image: url('../images/signup-bg.png');
  background-size: cover;
  background-repeat: repeat-y;
  background-position: center center;
}

.signup-new-container {
  margin-top: 10vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 64px;
  position: absolute;
  width: 540px;
  max-width: 90%;
  height: auto;
  background: #FFFFFF;
  border: 1px solid #000000;
}

.signup-new-container p {
  text-align: center;
  font-weight: 400;
  font-size: 70px;
  margin: 0px 10px;
  padding: 5px 10px;
  font-family: 'Zaio';
  color: #000000;
}
.signup-new-container h4 {
  text-align: center;
  font-family: 'Zaio';
  font-size: 30px;
  color: #000000;
}


@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

@media (max-width: 500px) {
  .signup-new{
    height: 165vh;
  }
  .mobile {
      display: block;
  }

  .laptop {
      display: none;
  }

  nav button {
      display: none;
  }

  nav ul {
      display: none;
  }
}

.signup-button-white {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 8px;
  width: 335px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #000000;
  box-shadow: -3px 2px 0px #000000;
  border-radius: 12px;
}

.signup-button-white p {
  font-weight: 400;
  font-size: 25px;
  margin: 0px 10px;
  cursor: pointer;
  padding: 5px 10px;
  font-family: 'Zaio';
  color: #000000;
}

.login-button-orange {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px;
  width: 335px;
  height: 60px;
  background: #F96908;
  border: 1px solid #000000;
  box-shadow: -3px 2px 0px #000000;
  border-radius: 12px;
}

.login-button-orange p {
  font-weight: 400;
  font-size: 25px;
  margin: 0px 10px;
  cursor: pointer;
  padding: 5px 10px;
  font-family: 'Zaio';
  font-style: normal;
  line-height: 24px;
  color: #FFFFFF;
  text-shadow:
      -1px -1px 0 #000,
      1px -1px 0 #000,
      -1px 1px 0 #000,
      1px 1px 0 #000;
}

.form-option {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.form-option label {
  color: #000;
  font-weight: 500;
  margin-bottom: 1px;
  width: 100%;
}

.form-option input {
  border-radius: 3px;
  outline: none;
  box-sizing: border-box;
  width: 100%;
  height: 30px;
  padding: 0 10px;
  background: #F3F3F3;
  border: 1px solid #959595;
  margin-bottom: 5px;
  color: #000;
}
.error-message{
  font-size: 10px;
}

@media (max-width: 768px) {
  .signup-new-container {
      width: 80%;
      padding: 24px 32px;
  }

  .signup-button-white,
  .login-button-orange,
  .form-option>input {
      width: 100%;
  }

  .signup-button-white>p,
  .login-button-orange>p {
      font-size: 20px;
  }
}

@media (max-width: 500px) {
  .signup-new-container {
    margin-top: 0vh;
      width: 90%;
      padding: 16px 24px;
  }

  .signup-button-white,
  .login-button-orange,
  .form-option>input {
    width: 80vw;
    height: 5vh;
  }

  .signup-button-white>p,
  .login-button-orange>p {
      font-size: 18px;
  }
}