.SpeakerContainerOutter{
    font-family: 'FK_Grotesk';
    display: flex;
    align-content: center;
    flex-direction: column;
    align-items: center;
}
.SpeakersTileText{
    font-family: 'FK_Grotesk';
}
.SpeakersTileWrapper {
    margin-left: 1vw;
    margin-right: 1vw;
    overflow: hidden;
    position: relative;
    display: flex;
    margin-top: 2vh;
    justify-content: center;
    background-color: black;
    width: 16vw;
    align-items: center;
    clip-path: polygon(0% 6% ,2% 6%, 2% 4% , 4% 4%, 4% 2%, 6% 2%, 6% 0%, 94% 0% ,94% 2%, 96% 2%, 96% 4% , 98% 4%, 98% 6%, 100% 6%, 100% 94% ,98% 94%, 98% 96% , 96% 96%, 96% 98%, 94% 98%, 94% 100% , 6% 100% , 6% 98%, 4% 98% , 4% 96% , 2% 96% , 2% 94%, 0% 94% );
}

.SpeakersTileContainer {
    /* position: relative; */
    border-radius: 8px; 
    overflow: hidden; 
    background-color: black;
    margin: 0.4vh 0.4vh 0.4vh 0.4vh;
    align-content: center;
    align-items: center;
    overflow: hidden;
    width: 25vw;
    position: relative;
    box-shadow: 2rem 2rem 1rem black;
}

.SpeakersTileImage {
    width: 27vw;
    display: block;
    clip-path: polygon(0% 6% ,2% 6%, 2% 4% , 4% 4%, 4% 2%, 6% 2%, 6% 0%, 94% 0% ,94% 2%, 96% 2%, 96% 4% , 98% 4%, 98% 6%, 100% 6%, 100% 94% ,98% 94%, 98% 96% , 96% 96%, 96% 98%, 94% 98%, 94% 100% , 6% 100% , 6% 98%, 4% 98% , 4% 96% , 2% 96% , 2% 94%, 0% 94% );
}

.SpeakersTileContainer p {
    font-size: 1.1rem;
    font-family: 'FK_Grotesk';
    color: white;
    margin: 10px;
    font-family: 'FK_Grotesk';
    position: absolute;
    top: 80%;
    left: 2%;
    display: inline-flex;
    color: #2D48D5;
    font-weight: 400;
    text-align: center;
}
.LearnMoreicon{
    padding-left: 1vh;
    margin-top: 14%;
    height: 2.5vh;
}
.LearnMoreWrapper {
    display: flex;
    align-items: baseline;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    top: 78%;
    right: 4%;
    justify-content: center;
    padding: 0.05vh;
    align-items: center;
    opacity: 0; 
    transition: opacity 0.3s ease;
}

.SpeakersTileWrapper:hover .LearnMoreWrapper {
    opacity: 1; 
}

.LearnMoreContainer {
    font-family: 'FK_Grotesk';
    align-content: center;
    align-items: center;
    overflow: hidden;
    padding-top: 14%;
    position: relative;
    margin: 0.3vh 0vh 0vh 0.3vh;
    text-align: center;
    font-weight: 500;
    font-size: 0.9rem;
    color: white;
}

.LearnMoreContainer:hover {
    cursor: pointer;
}
@media (max-width: 1050px){
    .SpeakersTileContainer{
        width: 75vw;
    }
    .SpeakersTileWrapper{
        width: 50vw;
    }
}
@media (max-width : 768px){
    .SpeakersTileContainer p{
        top: 70%;
    }
    .SpeakersTileWrapper{
        margin-top: 5vh;
    }
}

@media (max-width : 600px){
    .SpeakersTileContainer{
        height: 30vw;
    }
    .SpeakersTileWrapper{
        height: 30vw;
    }
    .SpeakersTileContainer p{
        top: 10%;
    }
    .SpeakersTileImage{
        height: 28.8vw;
    }
}
@media (max-width : 400px){
    .SpeakersTileContainer{
        height: 62vw;
    }
    .SpeakersTileContainer p{
        font-size: 120%;
    }
    .SpeakersTileWrapper{
        height: 64vw;
    }
    .SpeakersTileImage{
        height: 62vw;
        width: 50vw;
    }
}
