@font-face {
    font-family: 'PP_Mori';
    src: url('../../../fonts/PPMori-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Roslindale';
    src: url('../../../fonts/Roslindale-DeckNarrowRegular-Testing.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  .heading{
    font-family: 'Roslindale';
    margin-bottom: 20px;
  }
  .login-form-verify {
    text-align: center;
    padding: 40px;
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 25px;
    width: 80vh;
    height: auto;
    color:white;
    position: relative;
    background-color: rgba(0, 0, 0, 0.16);
    backdrop-filter: blur(20px); /* Blur effect */
  }
  
  .login-form-verify h1{
    margin-top: 7vh;
  }
  .loginx {
    width: 60%;
    padding: 10px;
    
    color: white;
    border: none;
    border-radius: 25px;
    margin: 2vh 0 0vh 0;
    cursor: pointer;
    font-family: "PP_Mori";
  }
  
  .login-form-verify a {
    font-family: "PP_Mori";
    text-decoration: none;
    color: white;
  }
  
  .forgot-password{
    margin-top: 10px;
    margin-bottom: 40px;
    font-family: 'PP_Mori';
    position: absolute;
    right: 22%;
  }
  
  .hover {
    animation: translateAnimation 4s linear infinite alternate; /* Change '5s' to adjust the animation duration */
  }
  .texth{
    font-size: 100%;
  }
  
  @keyframes translateAnimation {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-5%, -5%); /* Change '-50%' to adjust the distance of horizontal and vertical translation */
    }
  }
  
  
  
  @media (max-width: 767px) {
   .login-form-verify{
    margin: auto;
    width: 85vw;
    background-color: rgba(18, 13, 13, 0.8);
    backdrop-filter: blur(10px); /* Blur effect */
   }
   .login-form-verify input[type="text"],
  .login-form-verify input[type="password"] {
    width: 90%;
  }
  .loginx{
    width: 90%;
  }
  .signup{
    width: 90%;
  }
  }