.SmallImageWrapper {
    z-index: 98;
    margin-left: 1vw;
    margin-right: 1vw;
    overflow: hidden;
    position: relative;
    display: flex;
    margin-top: 5vh;
    justify-content: center;
    padding: 0.2vh;
    background-color: black;
    width: 20vw;
    height: 14vw;
    align-items: center;
    clip-path: polygon(0% 6% ,1% 6%, 1% 4% , 2% 4%, 2% 2%, 3% 2%, 3% 0%, 97% 0% ,97% 2%, 98% 2%, 98% 4% , 99% 4%, 99% 6%, 100% 6%, 100% 94% ,99% 94%, 99% 96% , 98% 96%, 98% 98%, 97% 98%, 97% 100% , 3% 100% , 3% 98%, 2% 98% , 2% 96% , 1% 96% , 1% 94%, 0% 94% );
}

.SmallImageContainer {
    position: relative;
    border-radius: 8px; 
    overflow: hidden; 
    background-color: black;
    margin: 0.2vh 0.7vh 0.7vh 0.9vh;
    align-content: center;
    align-items: center;
    overflow: hidden;
    width: 20vw;
    height: 14vw;
    position: relative;
    margin: 0.2vw;
    box-shadow: 2rem 2rem 1rem black;
}

.SmallImageImage {
    width: 100%;
    height: auto;
    display: block;
    clip-path: polygon(0% 6% ,1% 6%, 1% 4% , 2% 4%, 2% 2%, 3% 2%, 3% 0%, 97% 0% ,97% 2%, 98% 2%, 98% 4% , 99% 4%, 99% 6%, 100% 6%, 100% 94% ,99% 94%, 99% 96% , 98% 96%, 98% 98%, 97% 98%, 97% 100% , 3% 100% , 3% 98%, 2% 98% , 2% 96% , 1% 96% , 1% 94%, 0% 94%  );
}

.SmallImageContainer p {
    font-size: 1.1rem;
    font-family: 'FK_Grotesk_SemiMono';
    color: white;
    margin: 10px 0px 10px 10px;
    position: absolute;
    top: 80%;
    left: 2%;
    display: inline-flex;
    color: #2D48D5;
    font-weight: 300;
    text-align: center;
}
.LearnMoreicon{
    padding-left: 1vh;
    margin-top: 14%;
    height: 2.5vh;
}
.LearnMoreWrapper {
    display: flex;
    align-items: baseline;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    top: 78%;
    right: 4%;
    justify-content: center;
    padding: 0.05vh;
    align-items: center;
    opacity: 0; 
    transition: opacity 0.3s ease;
}

.SmallImageWrapper:hover .LearnMoreWrapper {
    opacity: 1; 
}

.LearnMoreContainer {
    font-family: 'FK_Grotesk_SemiMono';
    align-content: center;
    align-items: center;
    overflow: hidden;
    padding-top: 14%;
    position: relative;
    margin: 0.3vh 0vh 0vh 0.3vh;
    text-align: center;
    font-weight: 500;
    font-size: 0.9rem;
    color: white;
}

.LearnMoreContainer:hover {
    cursor: pointer;
}
@media (max-width: 1050px){
    .SmallImageContainer{
        width: 45vw;
    }
    .SmallImageWrapper{
        width: 45vw;
    }
}
@media (max-width : 768px){
    .SmallImageContainer p{
        top: 65%;
        left:0%;
    }
    .SmallImageWrapper{
        margin-top: 0vh;
    }
}

@media (max-width : 600px){
    .SmallImageContainer{
        height: 30vw;
    }
    .SmallImageWrapper{
        height: 30vw;
    }
    .SmallImageImage{
        height: 28.8vw;
    }
}
@media (max-width : 400px){
    .SmallImageContainer{
        height: 35vw;
    }
    .SmallImageContainer p{
        text-align: left;
        font-size: 0.8rem;
    }
    .SmallImageWrapper{
        height: 32vw;
    }
    .SmallImageImage{
        height: 31vw;
    }
}
