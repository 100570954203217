.footer-wrapper{
  background: url('../assets/footerBg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  height:80vh;
  z-index: 100;
 }
 .footer-banner-wrapper{
  position: absolute;
  bottom: 5vh;
  background-color: #F3FF63;
 }
 .footer-banner-inner {
  animation: slideFooter 18s linear infinite;
 }
 
 
 @keyframes slideFooter {
  0% {
    transform: translateX(0); /* Start from the right */
  }
  100% {
    transform: translateX(-50%); /* Move to the left */
  }
 }
 
 
 .main-footer-container{
  padding-left: 10vw;
  width: 50%;
 }
 .main-footer-inner-container{
  gap: 4vh;
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
 }
 /* @keyframes slideFooter {
  from{
      transform: translateX(-50%);
  }to{
      transform: translateX(0%);
  }
 } */
 .footer-logo-img{
  animation: rotation 3s linear infinite;
 }
 @keyframes rotation{
  from{
      transform: rotateY(0deg);
  }to{
      transform: rotateY(360deg);
  }
 }
 .footer-btn{
  font-family: 'FK_Grotesk_SemiMono';
  background-color: #F3FF63;
  height: max-content;
  padding: 8px 25px;
  font-weight: 100;
  cursor: pointer;
  width: max-content;
  color: rgb(34, 0, 255);
  clip-path: polygon(
    6px 0px,
    6px 2px,
    4px 2px,
    4px 4px,
    2px 4px,
    2px 6px,
    0px 6px,
    0px calc(100% - 6px),
    2px calc(100% - 6px),
    2px calc(100% - 4px),
    4px calc(100% - 4px),
    4px calc(100% - 2px),
    6px calc(100% - 2px),
    6px 100%,
    calc(100% - 6px) 100%,
    calc(100% - 6px) calc(100% - 2px),
    calc(100% - 4px) calc(100% - 2px),
    calc(100% - 4px) calc(100% - 4px),
    calc(100% - 2px) calc(100% - 4px),
    calc(100% - 2px) calc(100% - 6px),
    100% calc(100% - 6px),
    100% 6px,
    calc(100% - 2px) 6px,
    calc(100% - 2px) 4px,
    calc(100% - 4px) 4px,
    calc(100% - 4px) 2px,
    calc(100% - 6px) 2px,
    calc(100% - 6px) 0px,
    6px 0px
  );
  margin: 0.1vh 0.5vh 0.5vh 0.1vh;
  transition: transform 0.2s ease-in-out;
 }
 .footer-btn-wrapper{
  margin: 20px 0px;
  clip-path: polygon(
    6px 0px,
    6px 2px,
    4px 2px,
    4px 4px,
    2px 4px,
    2px 6px,
    0px 6px,
    0px calc(100% - 6px),
    2px calc(100% - 6px),
    2px calc(100% - 4px),
    4px calc(100% - 4px),
    4px calc(100% - 2px),
    6px calc(100% - 2px),
    6px 100%,
    calc(100% - 6px) 100%,
    calc(100% - 6px) calc(100% - 2px),
    calc(100% - 4px) calc(100% - 2px),
    calc(100% - 4px) calc(100% - 4px),
    calc(100% - 2px) calc(100% - 4px),
    calc(100% - 2px) calc(100% - 6px),
    100% calc(100% - 6px),
    100% 6px,
    calc(100% - 2px) 6px,
    calc(100% - 2px) 4px,
    calc(100% - 4px) 4px,
    calc(100% - 4px) 2px,
    calc(100% - 6px) 2px,
    calc(100% - 6px) 0px,
    6px 0px
  );
 }
 .footer-btn:hover{
  cursor: pointer;
  background-color: #F7F8FF;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  font-style: italic;
  margin: 0.0vh 0.1vh 0.1vh 0.0vh;
 }
 .footer-social-wrapper{
  display: flex;
  flex-direction: row;
  width:30vw;
 }
 .footer-logo-container{
  height: 100%;
  padding-right: 10vw;
  padding-top: 5vh;
 }
 .footer-video{
  width:65%;
 }
 .footer-btn-wrapper:hover{
  transform: translate(0.5vh, 0.5vh); /* Moves the button diagonally */
  transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
 }
 .footer-pixel-top{
  bottom: 80vh;
 }
 .know-more-about-us{
  display: flex;
 }
 @media screen and  (max-width:700px) {
  .footer-social-wrapper{
      width: 80vw;
      margin-top: 3vh;
  }
  .footer-pixel-top{
    bottom: 94vh;
  }
  .footer-video{
    width: 100%;
  }
 }
 .main-footer-heading{
  text-align: left;
  width: 45vw;
  font-family: 'That_New_Pixel';
  font-size: 3rem;
 }
 .footer-aboutus{
  display: flex;
  text-align: left;
  text-align: left;
  width: 45vw;
  font-family: 'That_New_Pixel';
  font-size: 1.5rem;
 }
 .footer-aboutus span Link{
  font-family: 'That_New_Pixel';
  font-size: 1.5rem;
 }
 .main-footer-heading span{
  font-family: 'That_New_Pixel';
  font-size: 3rem;
 }
 .email-contact-footer{
  letter-spacing: 0.05rem;
  font-size: 1.4rem;
  font-weight: 300;
 }
 .email-id-footer{
  font-size: 1.15rem;
 }
 .footer-social-inner-wrapper{
  margin-right: 5vw;
 }
 .AboutUsicon{
    padding-left: 1vh;
    cursor: pointer;
    height: 3vh;
 }
 .aboutus-link{
  align-items: center;
  cursor: pointer;
  margin-left: 0.5vw;
 }
 .AboutUsicon {
  visibility: hidden; /* Initially hide the icon */
  transition: visibility 0.3s; /* Smooth transition */
  margin-left: 0.5vw; /* Add some space between text and icon */
 }
 
 
 .aboutus-link:hover .AboutUsicon {
  visibility: visible; /* Show the icon on hover */
 }
 .know-more-about-us-inner{
  margin-left:1vw;
 }
 .socials-icons-full{
  padding: 20px 10px 20px 10px;
  /* transform: translateX(-15vw); */
  width: 100%;
 }
 
 
 @media screen and (max-width:500px) {
  .footer-wrapper{
    height: 94vh;
  }
  .main-footer-heading{
      font-size:1.5rem ;
  }
  .main-footer-container{
    padding-left: 0vw;
    width: 100%;
  }
  .email-contact-footer{
    text-align: center;
    font-weight: 300;
    font-size: 1.3rem;
  }
  .email-id-footer{
      font-size: 1rem;
  }
  .footer-wrapper{
    flex-direction: column-reverse;
  }
  .footer-aboutus{
    text-align: center;
    width: 70vw;
    display: flex;
    flex-direction: column;
    font-size: 1.3rem;
  }
  .footer-banner-wrapper{
    margin: 0px 0px;
  }
  .footer-logo-container{
    height:auto;
    padding-right: 0vw;
    padding-top: 5vh;
  }
  .main-footer-heading{
    padding-top: 4vw;
    padding-right: 4vw;
    text-align: center;
    width: 100vw;
    font-size: 1.5rem;
  }
  .main-footer-heading span{
    font-size: 1.5rem;
  }
  .main-footer-inner-container{
    gap: 0vh;
    display: flex;
    align-items: center;
    height: 12vh;
    justify-content: center;
  }
  .footer-social-wrapper{
    display: flex;
    flex-direction: column;
    width:30vw;
  }
  .footer-social-inner-wrapper{
    margin-right: 0vw;
  }
  .know-more-about-us-inner{
    margin-left:13vw;
  }
  .aboutus-link{
    margin-left: 3vw;
  }
  .socials-icons-full{
    padding: 30px 10px 20px 10px;
    transform: translateX(-15vw);
    width: 70vw;
    margin-top: 5vh;
    margin-bottom: 5vh;
  }
 }
 