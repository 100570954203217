*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* background-image: url('https://s3-alpha-sig.figma.com/img/d8f6/2123/9ce87a69719d07c1bf0902e8df394a6b?Expires=1722211200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=RuKEneyW1E3b3P3~-UgJeS7z6HvWUwoAL3sUby3pSXFt541K6Ho0T8ED-vefAX6vzWTLZBIWavU4JmfIeEA56fnD69RQoQnddN-pQeeFDvvSmGUEzLlw24l1JFK6-VpqgRDGR5pe89sthLRrq346uD0z1uiabzx9W02LjCFJzJZ69SnHR7eYl-sPifOQJA51rourCyEFFvLTEGRrxXjMfu5Bv0W-ZwFUDYMoTYsqhiW40VN~79bOf-cK3vaSswYl2U4qd8njmAANwDrJRRnIl4oa76dNOToxxfWSZTURhgJpODvfeeMMkH1-Heo3ZHgD6Sd0iOY05~WhZzoNMMe-XA__'); */
}

.LandingPage{
  overflow-x: hidden;
  /* height: 600vh; */
}
.Hero_landing{
  height: 300vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #C1C3C6;
  position: relative;
  overflow-x: hidden;
}

.hero-all-text{
  display: flex;
  flex-direction: column;
  position: absolute;
  transform: translateY(10%);
  /* width: 70%;
  height: 50%; */
}


.Event-video{
  height: 200vh;
  width: 100vw;
  position: absolute;
  bottom: 0;
  left: 0;
  display: none;
  /* background-image: url('../../assets/bg.png'); */
  /* display: flex; */
  align-items: center;
  justify-content: center;
  /* opacity: 0; Initially hidden */
  /* transition: opacity 0.2s ease-in-out; */
}



.Event-video-block{
  width: 100vw;
  height: 60vh;
  display: flex;
  flex-direction: column;
  /* background-color: white; */
  align-items: center;
}
.Event-video-block video{
  max-width: 140%;
  width: 140%;
  height: 140%;
}



.Journey{
  /* background-color: #C1C3C6; */
  background-image: url('../../assets/footerBg.png');;
  height: 200vh;
  width: 100vw;
  display: flex;
  padding: 10px 0 0 0;
  position: relative;
  overflow-y:hidden;
  overflow-x: hidden;
}
.journey-content{
  background-color: #C1C3C6;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;
}
.location-video-block{
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 99;
;
}
.location-video-block video{
  width: 100%;
  height: 100%;
}
.Journey-through-techniche-block{
  position: absolute;
  z-index: 100;
  right: 0;
  margin-top: 20vh;
  padding-right: 5vw;
  width:38%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;

}


.Exciting-events{
  background-image: url('../../assets/footerBg.png');
  position: absolute;
  bottom: 0;
  /* transform:translateY(7vh); */
  opacity: 0;
  transition: opacity 0.1s ease;
  /* display: none; */
  z-index: 110;
  /* background-color: #2D48D5; */
  color: white;
  height: 100vh;
  width: 100vw;
}
.Exciting-events-header{
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  text-align: center;
}
.Exciting-events-header span{
  font-family: 'That_New_Pixel';
  font-size: 4rem;
}
.Exciting-Event-list-container{
  /* height: 70%; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
  position: relative;
  color:#2D48D5 !important;
}
.Exciting-Event-list{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: visible;
 
}
.Exciting-Event-card{
  width:22.5%;
  height: 60vh;
  padding: 1.25%;
  /* flex: 0 0 auto; */
  flex: 0 0 auto;
  position: relative;
  right: 0;
  transition: right 0.5s ease;
  cursor: pointer;
}

#Exciting-Event-img-3{
  perspective-origin: center;
  perspective: 10vw;
  scale: 1.1;
}
#Exciting-Event-img-3-img{
  transform: translateZ(10px); 
  
}
#Exciting-Event-img-3-name{
 scale: 1.1;
   background-color: white; 
   color: #2D48D5 !important;
  animation: righttoleft 10s infinite linear;
}
@keyframes righttoleft {
  from{
    transform: translateX(0%) rotate(-5deg);
  }
  to{
    transform: translateX(-50%) rotate(-5deg);
  
  }
}

.Exciting-Event-image{
  width: 100%;
 height: 100%;
  position: relative;
  overflow: hidden;
}
.Exciting-Event-name{
  position: absolute;
  bottom: 20%;
  overflow: hidden;
  display: flex;
  flex: 0 0 auto;
  background-color: #F3FF63;
  color: #2D48D5;
  transform: rotate(-5deg);
 }
/*.Event-name ul{
  list-style-type: none;
 display: flex;
  align-items: center;
} */
.Exciting-Event-name p{
  font-family: 'FK_Grotesk_SemiMono';
  font-weight: 100;
  font-size: 1.2rem;
 padding-right: 10px;
 padding-top: 5px;
  padding-bottom: 5px;
}



.Exciting-Event-image img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

/*Merch*/
.Merch{
  background-image: url('../../assets/greypixelbg.png');
  height: 120vh;
  width: 100vw;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 98;
}
.Merch-img-block{
  margin-left: 5vw;
  height: 50%;
  width: 50%;
  position: relative;
}
#merch_img1{
  width: 100%;
  position: absolute;
  z-index: 98;
}
#merch_img2{
  position: absolute;
  z-index: 99;
  left: 40%;
  top: 30%;
  width: 50%;
  height: 60%;
}
.Merch-text{
  padding: 10px;
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
   align-items: start;
}
/* .Merch-text P{
  font-size: 3rem;
} */
.collection-txt {
   font-size: 1rem;
   font-family: 'FK_Grotesk_SemiMono';
   color:#2D48D5;
   font-weight: 100;
}
.elevate-your-style{
  margin-top: 5vh;
  font-size: 4rem;
  font-family: 'That_New_Pixel';
  color:#2D48D5;
}
.elevate-your-style2{
  margin-bottom: 5vh;
  font-size: 4rem;
  font-family: 'That_New_Pixel';
  color:#2D48D5;
}
.elevate-your-style span{
  font-size: 4rem;
  font-family: 'That_New_Pixel';
}
.merch-body-txt{
  font-size: 1rem;
   font-family: 'FK_Grotesk_SemiMono';
   color:#2D48D5;
   font-weight: 100;
}
.after-movie-text{
  text-align: center;
  font-style: italic;
  color:#2D48D5;
  font-size: 3rem;
  font-family: 'That_New_Pixel';
}
.Journey-through-techniche-heading1{
  color:#2D48D5;
  font-size: 4rem;
  font-family: 'That_New_Pixel';
}
.Journey-through-techniche-heading2{
  font-size: 6rem;
  font-family: 'That_New_Pixel';
}
.Journey-through-techniche-heading3{
  font-size: 1rem;
  color:#2D48D5;
  font-family: 'FK_Grotesk_SemiMono';
  font-weight: 100;
}
.merch-btn-container{
  padding-top: 8vh;
  height: 16vh;
}
.merch-btn-wrapper{
  margin: 20px 0px;
  clip-path: polygon(
    6px 0px,
    6px 2px,
    4px 2px,
    4px 4px,
    2px 4px,
    2px 6px,
    0px 6px,
    0px calc(100% - 6px),
    2px calc(100% - 6px),
    2px calc(100% - 4px),
    4px calc(100% - 4px),
    4px calc(100% - 2px),
    6px calc(100% - 2px),
    6px 100%,
    calc(100% - 6px) 100%,
    calc(100% - 6px) calc(100% - 2px),
    calc(100% - 4px) calc(100% - 2px),
    calc(100% - 4px) calc(100% - 4px),
    calc(100% - 2px) calc(100% - 4px),
    calc(100% - 2px) calc(100% - 6px),
    100% calc(100% - 6px),
    100% 6px,
    calc(100% - 2px) 6px,
    calc(100% - 2px) 4px,
    calc(100% - 4px) 4px,
    calc(100% - 4px) 2px,
    calc(100% - 6px) 2px,
    calc(100% - 6px) 0px,
    6px 0px
  );
}
.merch-btn-wrapper:hover{
  transform: translate(0.5vh, 0.5vh); 
  transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
}
.merch-btn{
  font-family: 'FK_Grotesk_SemiMono';
  background-color:#F7F8FF;
  height: max-content;
  padding: 8px 25px;
  font-weight: 100;
  cursor: pointer;
  width: max-content;
  color: rgb(34, 0, 255);
  clip-path: polygon(
    6px 0px,
    6px 2px,
    4px 2px,
    4px 4px,
    2px 4px,
    2px 6px,
    0px 6px,
    0px calc(100% - 6px),
    2px calc(100% - 6px),
    2px calc(100% - 4px),
    4px calc(100% - 4px),
    4px calc(100% - 2px),
    6px calc(100% - 2px),
    6px 100%,
    calc(100% - 6px) 100%,
    calc(100% - 6px) calc(100% - 2px),
    calc(100% - 4px) calc(100% - 2px),
    calc(100% - 4px) calc(100% - 4px),
    calc(100% - 2px) calc(100% - 4px),
    calc(100% - 2px) calc(100% - 6px),
    100% calc(100% - 6px),
    100% 6px,
    calc(100% - 2px) 6px,
    calc(100% - 2px) 4px,
    calc(100% - 4px) 4px,
    calc(100% - 4px) 2px,
    calc(100% - 6px) 2px,
    calc(100% - 6px) 0px,
    6px 0px
  );
  margin: 0.1vh 0.5vh 0.5vh 0.1vh;
  transition: transform 0.2s ease-in-out;
}
.merch-btn:hover{
  cursor: pointer;
  background-color:#F3FF63;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  font-style: italic;
  margin: 0.0vh 0.1vh 0.1vh 0.0vh;
}
@media screen and (max-width: 740px){
  .header-logo{
    scale: 3;
  }
  .Exciting-Event-name p{
    font-size: 0.5rem;
   padding-right: 5px;
   padding-top: 0;
    padding-bottom: 0;
  }
  .Exciting-Event-name{
    position: absolute;
    bottom: 37%;
  }
  .Exciting-Event-list-container{
    scale: 1.5;
    height: 60%;
  }
  .Hero_landing {
    height: 200vh;
  }
  .Event-video-block{
    width: 80vw;
  }
  .Journey_phone{
    width: 100vw;
    /* background-color: #C1C3C6; */
  }
  .Journey{
    flex-direction: column;
    height: 200vh;
    overflow-y: visible;
    overflow-x: visible;
  }
  .journey-content{
    width: 100vw;
    height: 104vh;
    overflow-y: visible;
  }
  .Journey-through-techniche-block{
    position: absolute;
    /* width: 80vw;  anu*/
    width: 100vw;
    height: 80vh;
    top:-0vh;
    left:5vw;
    z-index: 99;
  }
  .Journey-through-techniche-block p{
    white-space: nowrap;
    font-size: 10vw;
    margin-top: 0vh;
  }
  .location-video-block{
    width: 100vw;
    height: 100vh;
    
  }
  .Exciting-events{
    height: 105vh;
  }
  .Exciting-Event-card{
    height: 40vh;
  }
  .Exciting-Event-image img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .Exciting-Event-list{
    transform: scaleX(2);
  }
  #Exciting-Event-img-3{
    transform: scaleY(1.5);
  }
  .location-video-imgset{
    object-position: bottom;
    transform: translateY(-0vh);
  }
  .Exciting-Event-name p{
    font-size: 0.5rem;
  }
  .Event-video-block video{
     margin-top: 0vh;
  }
  .Event-video{
    height: 20vh;
    /* background-size: 200%; */
  }
  .Merch{
    display: flex;
    align-items: center;
    position: relative;
    z-index: 98;
    flex-direction: column-reverse;
    background-size: 200%;
    height: 94vh;
    justify-content: center;

  }
  .merch-btn-container{
    padding-top: 2vh;
    height: 16vh;
  }
  .Merch-text{
    margin-top: 8vh;
    width: 90vw;
  }
 .elevate-your-style{
   margin-top: 2vh;
   font-size: 2rem;
   font-family: 'That_New_Pixel';
   color:#2D48D5;
 }
 .elevate-your-style2{
   margin-bottom: 2vh;
   font-size: 2rem;
   font-family: 'That_New_Pixel';
   color:#2D48D5;
 }
 .elevate-your-style span{
   font-size: 2rem;
   font-family: 'That_New_Pixel';
 }
 .merch-body-txt{
   font-size: 1rem;
    font-family: 'FK_Grotesk_SemiMono';
    color:#2D48D5;
    font-weight: 100;
 }
 .Merch-img-block {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-left: 0vw;
  height: 40%;
  width: 100%;
  }
  #merch_img1 {
    width: 90%;
    position: absolute;
    z-index: 98;
}
.Event-video-block video {
  max-width: 140%;
  width: 120%;
  height: 60%;
}
  .Exciting-events-header span {
  font-family: 'That_New_Pixel';
  font-size: 2.5rem;
  }
  /* .hero-all-text{
    display: none;
  }
  .date-text{
    display: none;
  }
  .text-t24{
    display: none;
  }
  .text-techniche{
    display: none;
  } */
  
}