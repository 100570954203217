@font-face {
    font-family: 'FK_Grotesk';
    src: url('../../../fonts/FKGroteskNeueTrial-Regular-BF6576818c3af74.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'FK_Grotesk';
    src: url('../../../fonts/FKGroteskNeueTrial-Thin-BF6576818c2a14c.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'FK_Grotesk';
    src: url('../../../fonts/FKGroteskNeueTrial-Bold-BF6576818bd3700.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'That_New_Pixel';
    src: url('../../../fonts/thatthatnewpixelfamilytest-italicsquare.otf') format('opentype');
    font-weight: 300;
    font-style: italic;
  }
  @font-face {
    font-family: 'That_New_Pixel';
    src: url('../../../fonts/thatthatnewpixelfamilytest-square.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'FK_Grotesk_SemiMono';
    src: url('../../../fonts/FKGroteskSemiMono-Medium.ttf') format('opentype');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'FK_Grotesk_SemiMono';
    src: url('../../../fonts/FKGroteskSemiMono-Regular.ttf') format('opentype');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'FK_Grotesk_SemiMono';
    src: url('../../../fonts/FKGroteskSemiMono-Bold.ttf') format('opentype');
    font-weight: 500;
    font-style: normal;
  }
.about-techniche-heading{
    font-family: 'FK_Grotesk_SemiMono';
    font-weight: 300;
    font-size: 1.3rem;
}
  .about-techniche-heading-lower{
    font-family: 'FK_Grotesk_SemiMono';
    font-weight: 300;
    padding-right: 5vw;
    font-size: 1rem;
  }

  .about-main-heading{
    font-size: 4rem;
    font-family: 'That_New_Pixel';
    font-weight: 400;
  }
  .about-main-heading span{
    font-family: 'That_New_Pixel';
  }
.about-techniche-tagline{
    font-family: 'That_New_Pixel';
}
.about-techniche-tagline span{
    font-family: 'That_New_Pixel';
    font-style: italic;
}
.home-left-wrapper{
    justify-content: center;
    display: flex;
    text-align: left;
    /* padding-top: 10vh; */
    width: 50vw;
    flex-direction: column;
    position: relative;
}
.home-right-wrapper{
    width: 50vw;
}

.pixels-slider{
    display: flex;
    animation: slider 10s linear infinite;
}
.pixels-slider img{
    width: 100vw;
}
.home-slider-wrapper{
    position: absolute;
    width: 100vw;
    overflow-x: hidden;
    z-index: 1;
    transform: translateX(-2vh);
}
#about-heading-details{
    width: 40vw;
}
@keyframes slider {
    to{
        transform: translateX(0%);
    }from{
        transform: translateX(-100%);
    }
}
@media screen and (max-width:1000px) {
    .home-wrapper{
        flex-direction: column;
    }
    .home-left-wrapper , .home-right-wrapper{
        width: 100%;
    }
    .home-left-wrapper{
        height: 104vw;
        position: sticky;
        top: 0px;
    }   
}
@media screen and (max-width:700px) {
    #about-heading-details{
        width: 70vw;
        margin: 40px 0px;
    }
    .home-slider-wrapper{
        margin-bottom: 8vh;
        width: 104vw;
    }
    .pixels-slider img{
        margin-top: 10vh;
        height: 25vh;
        width: 3000vw;
        max-width: 300%;
    }
    .about-techniche-heading-lower{
        font-size: 0.8rem;
    }
    .about-techniche-heading{
        font-size: 1.1rem;
    }
    .about-techniche-heading-wrapper{
        margin-top: 5vh;
    }
}
@media screen and (max-width:550px) {
    .about-main-heading{
        font-size: 2.7rem;
    }
    .home-wrapper{
        height: max-content;
        padding: 5vh 0px;
    }
}
@media screen and (max-width:450px) {
    #about-heading-details{
        margin: 10px 0px;
    }
    .about-main-heading{
        font-size: 2.5rem;
    }
}
@media screen and (max-width:400px) {
    #about-heading-details{
        /* font-size: 0.7rem; */
        width: 90vw;
    }
}