.marquee {
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
    z-index: 98;
  }
  
  .marquee-content {
    display: inline-block;
    animation: marquee 55s linear infinite;
  }
  
  .marquee span {
    font-family: 'FK_Grotesk_SemiMono';
    font-style: italic;
    display: inline-block;
    padding: 5px 10px;
    font-size: 2rem;
    font-weight: bold;
  }
  
  @keyframes marquee {
    from {
      transform: translateX(-50%);
    }
    to {
      transform: translateX(0%);
    }
  }
  
  @media (max-width : 600px){
    .marquee span{
        font-size: 1rem;
    }
  }