.robotics-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    z-index: 98;
}
.event-sidenav-module:hover{
    color: #2D48D5;
}
.event-navbutton:hover .event-sidenav-module{
    color: #2D48D5;
}
.EventNav{
    padding-left: 8vw;
    padding-right: 8vw;
}
@media (max-width : 768px){
    .EventNav{
        padding-left: 2vw;
        padding-right: 2vw;
    }
}