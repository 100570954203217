*{
    margin: 0;
    padding: 0;
}


.Wrapper-Workshops{
    background-image: url('../../../assets/bg.png');
    background-repeat: repeat-y;
    background-size: 100%;
    width: 100%;
    padding-top: 12vh ;
    z-index: 50;
}

.marquee{
    margin-top: 10vh; 
}

.event-nav{
    align-content: center;
    text-align: center;
}

.event-nav i{
    font-size: 350%; 
}

.event-sidenav{
    align-content: center;
    font-size: 250%;
}

.event-navbutton{
    padding-left: 5vw;
    padding-right: 5vw;
    align-content: center;
    align-items: center;

}
.event-navbutton:hover{
    opacity: 0.7;
    /* box-shadow: 0.3em 0.3em 0.5em #808182;
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out; */

}

.MarqueeImg{
    width: 100%;
    height: 4.5vw;
    display: inline;
}
.event-navbutton img{
    width: 5vh;
}


@keyframes slide{
    from{
        transform: translateX(0);
    }
    to{
        transform: translateX(-100%);
    }
}

.marqueeSlide{
    animation: 35s slide infinite linear;
}

.marqueeSlide:hover{
    animation-play-state: paused;
}

.footer{
    margin-top: 40vh;
}

@media (max-width : 1050px){

    .Wrapper-Workshops{
        padding-top: 8vh;
    }

    .marquee{
        margin-top: 5vh;
    }


}

@media (max-width : 768px){

    .event-sidenav{
        font-size: 150%;
    }

    .event-nav i{
        font-size: 250%;
    }

    .MarqueeImg{
        height: 5vh;
    }

    .marquee{
        margin-top: 3vh;
    }

    .footer{
        margin-top: 20vh;
    }
    .event-navbutton{
        padding-left: 0vw;
        padding-right: 0vw;
    
    }

}

@media (max-width : 600px){

    .event-sidenav{
        font-size: 100%;
    }

    .event-nav i{
        font-size: 2rem;
    }
    .event-nav{
        margin-bottom: 2vw
    }
    .event-navbutton img{
        width: 2vh;
    }

}