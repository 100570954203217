
.reach-inner-wrapper {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   justify-content: center;
}
.stat-txt{
    padding-top: 2vh;
    font-size: 1.4rem;
}
.reach-stat-img{
    max-width: 200%;
}
.reach-stat-card{
    margin:10vh 0px;
}
#reach-stat-card-1,#reach-stat-card-3,#reach-stat-card-5{
    width:33vw;
}
#reach-stat-card-2,#reach-stat-card-4{
    width:20vw;
}
@media screen and (max-width:850px) {
    .stat-txt{
        font-size: 1.5rem
    }
    #reach-stat-card-1,#reach-stat-card-3,#reach-stat-card-5{
        width:20vw;
    }
    #reach-stat-card-2,#reach-stat-card-4{
        width:12vw;
    }
}
@media screen and (max-width:500px) {
    .stat-txt{
        font-size: 0.8rem
    }
}
@media screen and (max-width:700px) {
    .reach-stat-card{
        margin: 5vh 5vw;
    }
    .our-reach-heading{
        font-size: 2.5rem !important;;
    }
} 
.our-reach-heading{
    font-family: 'That_New_Pixel';
    font-size:4rem;
}
.our-reach-heading span{
    font-family: 'That_New_Pixel';
    font-style: italic;
}
.reach-outer-wrapper{
    height: 100vh;
    margin-bottom: 10vh;
    padding: 5vh 0px;
}
@media screen and (max-width:900px) {
    .reach-outer-wrapper{
        margin-bottom: 0px;
        height: fit-content;
        padding:0px ;
    }
}
