/* Data.css */

.container-for-data {
    display: flex;
    flex-direction: column; /* Ensure column layout */
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f4;
  }
  
  .form-for-data {
    background: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .form-for-data div {
    margin-bottom: 15px;
  }
  
  .label-for-data {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .password-for-data {
    color: #000;
  }
  
  .input-for-data {
    width: 100%;
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    color: #000000; /* Input text color */
  }
  
  .input-for-data::placeholder {
    color: #000000; /* Placeholder text color */
  }
  
  .button-for-data {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .button-for-data:hover {
    background-color: #0056b3;
  }
  
  /* New styles for the heading */
  .h1-for-data {
    font-size: 2em;
    color: #000000;
    margin-bottom: 20px; /* Add some space below the heading */
  }