*{
    padding: 0px;
    margin: 0px;
    cursor: url('./bg.png'), auto;
}
.about-outer-wrapper{
    background-image: url('./bg.png');
    /* overflow-x: hidden; */
    cursor: url('./bg.png'), auto;

    
}