.dashboard-container{
    display:flex;
    align-items: center;
}
.ca-dashboard-container{
    height: 100vh;
    width: 100vw;
    background-color: black;
    z-index: 90;
  }
  .user-profile-card{
    border: 1px solid white;
    margin-top: 14%;
    margin-left: 30vw;
    margin-right: 30vw;
    padding: 3%;
    padding-bottom: 4%;
    background-color: rgba(225,225,225,0.2);
    box-shadow: 0px 24px 24px rgba(0,0,0,0.4);
    border: 1px solid rgba(225,225,225,0.1);
    border-radius: 10px;
    width: 35vw;
  }
  .user-card-heading{
    font-size: 200%;
  }
  .user-profile-card-d1{
    display: flex;
    align-items: center;
    justify-content:space-evenly; 
    text-align: left; 
    background: rgba(0,0,0,0);
  
  }
  .user-profile-image {
    border-radius: 50%;
    width: 100%; 
    height: auto; 
    max-width: 150px; 
    max-height: 150px; 
    object-fit: cover; 
    max-width: 90%;
  }
  .user-profile-info{
    font-family: 'FK_Grotesk';
    font-weight: 300;
  }
  .tech-rep-id{
    font-weight: 600;
  }
  .user-profile-creds{
    font-family: 'FK_Grotesk';
    margin-top: 1vh;
    margin-bottom: 1vh;
  }
  .events-registration{
    display: flex;
    width: 100vw;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }

  @media (max-width: 768px) {
    .user-profile-card{
      border: 1px solid white;
      margin-top: 5%;
      margin-left: 15vw;
      margin-right: 15vw;
      padding: 3%;
      padding-bottom: 4%;
      
    background-color: rgba(225,225,225,0.2);
    box-shadow: 0px 24px 24px rgba(0,0,0,0.4);
    border: 1px solid rgba(225,225,225,0.1);
      border-radius: 10px;
      width: 70vw;
    }
    .user-card-heading{
      font-size: 200%;
    }
    .user-profile-card-d1 {
      display: flex;
      flex-direction: column; 
      align-items: center; 
      text-align: center;
      margin-left: 10%;
      margin-right: 10%;
    }
    .user-profile-image {
      border-radius: 50%;
      width: 100%;
      height: auto;
      max-width: 150px;
      max-height: 150px;
      object-fit: cover;
      max-width: 80%;
      margin-bottom: 5px;
      margin-left: auto; 
      margin-right: auto;
      margin-top: 5px;
    }
    .user-profile-info {
      font-size: 100%;
      text-align: center;
      margin-top: 5%;
    }
    .events-registration{
      display: block;
      width: 100vw;
      justify-content: space-evenly;
    }
}