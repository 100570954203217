@tailwind base;
@tailwind components;
@tailwind utilities;

.filter-blue {
    filter: invert(30%) sepia(90%) saturate(5000%) hue-rotate(200deg) brightness(95%) contrast(90%);
  }
.filter-red {
    filter: invert(16%) sepia(90%) saturate(7483%) hue-rotate(359deg) brightness(101%) contrast(116%);
  }
.filter-teal {
    filter: invert(59%) sepia(47%) saturate(2258%) hue-rotate(147deg) brightness(98%) contrast(96%);
}