
.dots {
    z-index: 60;
    position: fixed;
    transform: translate(-50%, -50%);
    /* rotate: 45deg; */
    display: grid;
    grid-template-columns: repeat(15, 1fr);
    grid-template-rows: repeat(15, 1fr);
    width: 220px;
    height: 220px;
    gap: 5px;
    overflow: hidden;
    border-radius: 20%;
    filter: blur(90%);
}

.dot {
    border-radius: 10%;
    transform: translate(50%, 50%);
    background-color: #A2A8C7;
}

